import React from 'react';
import { Redirect } from 'react-router-dom';
import {  IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonMenuButton, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, RefresherEventDetail } from '@ionic/react';
import AuthenticateJWT from '../shared/Authenticate';
import ButtonUser from '../components/ButtonUser';
import OrderList from '../components/Order/OrderList';
import OrderItemList from '../components/Order/OrderItemList';
import SetOrders from '../components/Order/SetOrders';
import { useAppDispatch } from '../hooks/hook';
import { setRefresh  } from '../store/ordersSlice';

const Order: React.FC = () => {

  const dispatch = useAppDispatch();

  if (!AuthenticateJWT.isAuthenticated() && !AuthenticateJWT.isEmployee()) {
    return <Redirect to="/login" />
  }

  function handleRefreshOrders (event: CustomEvent<RefresherEventDetail>) {
    dispatch(setRefresh(true));
    event.detail.complete();
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonTitle>Pedidos</IonTitle>
          <ButtonUser />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefreshOrders}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid className='ion-no-padding'>
          <IonRow>
            <IonCol sizeXl="2" sizeMd="4" size="12" className='unobike-height-screen'>
              <OrderList/>
            </IonCol>
            <OrderItemList/>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <SetOrders/>
      </IonFooter>
    </IonPage>
  );
};

export default Order;
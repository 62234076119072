import { IonButton, IonIcon, IonThumbnail } from "@ionic/react";
import { trashOutline } from "ionicons/icons";

type Props = {
  index: number,
  handleDeleteScan: (index: number) => void,
  webviewPath: string
}

const Scan: React.FC<Props> = ({index, handleDeleteScan, webviewPath}) => {

  return (
    <>
      <IonThumbnail item-left>
        <img src={webviewPath} alt='' />
      </IonThumbnail>
      <IonButton
        slot="end"
        onClick={() => handleDeleteScan(index)}
        color="danger"
        size='default'
      >
        <IonIcon icon={trashOutline} slot="icon-only" />
      </IonButton>
    </>
  );
};

export default Scan;
import { useReducer } from "react";
import { OrderLoaderContext } from "./OrderLoaderContext";
import { orderLoaderReducer } from "./OrderLoaderReducer";
import { OrderLoaderState, OrderProvider, Provider, ResponseOrderProviders, ResponseProviders } from "../interfaces";
import ApiRequest from "../../../shared/ApiRequest";
import { formatDateTime } from '../../../shared/utils';
import { BeepService } from "../../../services/BeepService";

const INITIAL_STATE: OrderLoaderState = {
  orders: [],
  ordersBySearch: [],
  providers: [],
  provider: 0,
  page: 1,
  term: '',
  loadingOrders: true
}

type Props = {
  children: JSX.Element | JSX.Element[]
}

export const OrderLoaderProvider = ({children}: Props) => {

  const [orderLoaderState, dispatch] = useReducer(orderLoaderReducer, INITIAL_STATE);
  const addOrders = (orders: OrderProvider[]) => {    
    dispatch({type: 'addOrders', payload: {orders}})
  }
  const setOrdersBySearch = (orders: OrderProvider[]) => {    
    dispatch({type: 'setOrdersBySearch', payload: {orders}})
  }
  const addProviders = (providers: Provider[]) => {
    dispatch({type: 'addProviders', payload: {providers}})
  }
  const setProvider = (provider: number) => {
    dispatch({type: 'setProvider', payload: {provider}})
  }
  const setTerm = (term: string) => {
    dispatch({type: 'setTerm', payload: {term}})
  }
  const activeLoadingOrders = () => {
    dispatch({type: 'activeLoadingOrders'})
  }

  const fetchProviders = async() => {
    const params = { 'order[name]': 'ASC' };
    const response = await ApiRequest.get<ResponseProviders>('/es/providers', {params});
    
    addProviders(response.data["hydra:member"])
  };

  const fetchOrders = async(provider: number, page: number) => {

    activeLoadingOrders();

    const params = {
      'order[number]': 'DESC',
      'pipelineStage': [11, 12],
      page,
      ...(provider && {provider})      
    }

    const response = await ApiRequest.get<ResponseOrderProviders>(`/es/order_providers?order[number]=DESC`,{params});
    addOrders(response.data["hydra:member"]);
  };
  
  const fetchOrdersBySearch = async(term: string) => {

    if ( term.length < 3 ) {
      return;
    }
    
    activeLoadingOrders();

    const response = await ApiRequest.get<OrderProvider[]>(`/es/reception-order-provider/find-by-ean-or-sku`,{params: {term}});   

    setOrdersBySearch(response.data);

    const inputElement: HTMLInputElement | null = document.querySelector('#order-loader-search input');
    if (inputElement) {
      setTimeout(() => {
        inputElement.focus();
      }, 100);      
    }
  };  

  const beep = new BeepService();

  return (
    <OrderLoaderContext.Provider value={{
      orderLoaderState,
      addOrders,      
      addProviders,
      fetchProviders,
      fetchOrders,
      setProvider,
      setTerm,
      fetchOrdersBySearch,
      formatDateTime,
      beep
    }}>
      { children }
    </OrderLoaderContext.Provider>
  )
}
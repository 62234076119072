import { Capacitor } from "@capacitor/core";
import { UserScan } from "../hooks/useScanGallery";
import { DocumentType, ResponseUploadedScans } from "../pages/ReceptionOrder/interfaces";
import ApiRequest from "../shared/ApiRequest";

class ScanDocumentService {

    private _name: string = '';
    private _documentType: DocumentType = DocumentType.None;

    constructor(
        private orderId: number
    ) {}

    get documentType() {
        return this._documentType;
    }

    set documentType(documentType: DocumentType) {
        this._documentType = documentType;
    }

    async fileToBase64(webviewPath: string) {
        const response = await fetch(Capacitor.convertFileSrc(webviewPath));
        const blob = await response.blob();

        const resource = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });

        return resource;
    }

    async packImages(images: UserScan[]) {
        
        let pack: any[] = [];

        for (let i = 0; i < images.length; i++) {
            pack.push(await this.fileToBase64(images[i].webviewPath!));
        }

        return pack;
    }

    async uploadAll(images: UserScan[]): Promise<number> {

        const pack = await this.packImages(images);
        const data = {
            'order_id': this.orderId,
            'title': this.name,
            'code_number': '',
            'type': this.documentType,
            pack
        };        
        
        const response = await ApiRequest.post<ResponseUploadedScans>(`/es/scandocument`, data);
        
        return response.data.documentId;
    }

    async upload(image: string) {

        const fetchedFile = await fetch(Capacitor.convertFileSrc(image));
        const blob = await fetchedFile.blob();

        const resource = new Promise((resolve, reject) => {
            const reader = new FileReader();
                reader.onerror = reject;
                reader.onload = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result);
                } else {
                    reject('method did not return a string');
                }
            };
            reader.readAsDataURL(blob);
        });
        
        const base64 = await resource;
        const data = {
            'order_id': this.orderId,
            'scanned_image': base64            
        };
        
        await ApiRequest.post<ResponseUploadedScans>(`/es/scandocument`, data);
    }

    get name(): string {
        return this._name;
    }

    set name(valor: string) {
        this._name = valor;
    }
}

export default ScanDocumentService;
import { OrderLoaderState, OrderProvider, Provider } from "../interfaces";

type OrderLoaderAction =
  | {type: 'addOrders', payload: {orders: OrderProvider[]}}
  | {type: 'setOrdersBySearch', payload: {orders: OrderProvider[]}}
  | {type: 'addProviders', payload: {providers: Provider[]}}
  | {type: 'setProvider', payload: {provider: number}}
  | {type: 'setTerm', payload: {term: string}}
  | {type: 'activeLoadingOrders'}
  ;

export const orderLoaderReducer = (state: OrderLoaderState, action: OrderLoaderAction): OrderLoaderState => {

  switch( action.type ) {

    case 'addOrders':
      return {
        ...state,
        orders: [...state.orders, ...action.payload.orders],
        loadingOrders: false,
        page: state.page + 1
      }

    case 'setOrdersBySearch':
      return {
        ...state,
        ordersBySearch: [...action.payload.orders],
        loadingOrders: false
      }

    case 'addProviders':
      return {
        ...state,
        providers: action.payload.providers
      }

    case 'setProvider':
      return {
        ...state,
        provider: action.payload.provider,
        orders: [],        
        page: 1
      }

    case 'setTerm':
      return {
        ...state,
        term: action.payload.term,        
        orders: [],
        page: 1
      }
    
    case 'activeLoadingOrders':
      return {
        ...state,
        loadingOrders: true
      }
    default:
      return state;
  }
}
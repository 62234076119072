import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { IonButton, IonIcon, useIonActionSheet } from "@ionic/react";
import { cubeOutline } from 'ionicons/icons';
import { measuresReducer, setType } from "../../store/measuresSlice";

const MeasureType: React.FC = () => {

    const dispatch = useAppDispatch();
    const [present] = useIonActionSheet();
    const measures = useAppSelector(measuresReducer);

    const setBox = (value: number) => {
        dispatch(setType(value));
    } 

    const TypeBox = (value: number) => {
        switch (value) {
            case 1:
                return "Caja";

            case 2:
                return "Caja Propia";

            case 3:
                return "Blister";

            case 4:
                return "Sin Bolsa";
        }
    }

    return (
        <IonButton color="warning" className="ion-float-right ion-no-margin" onClick={() =>
            present({
                header: 'Selecciona el tipo de embalaje',
                buttons: [
                    {
                        text: 'Caja',
                        handler: () => {
                            setBox(1)
                        },
                    },
                    {
                        text: 'Caja Propia',
                        handler: () => {
                            setBox(2)
                        },
                    },
                    {
                        text: 'Blister',
                        handler: () => {
                            setBox(3)
                        },
                    },
                    {
                        text: 'Sin Bolsa',
                        handler: () => {
                            setBox(4)
                        },
                    },
                    {
                        text: 'Cerrar',
                        role: 'cancel',
                    }
                ],
            })
        }>
            <IonIcon size="small" icon={cubeOutline}/> {TypeBox(measures.type)}
        </IonButton>
    );
}

export default MeasureType;
import { IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react"
import { useEffect, useState } from "react";
import ApiRequest from "../../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { resetBarcode, barcodeReducer } from "../../../store/barcodeSlice";
import { showToast } from "../../../store/toastSlice";
import { useReceptionOrder } from "../hooks/useReceptionOrder";
import { ResponseResumeOrderProvider } from "../interfaces";

const ReceivedSearch: React.FC = () => {  
  
  const { orderId, setResumeOrderProvider, beep } = useReceptionOrder();

  const dispatch = useAppDispatch();
  const [search, setSearchText] = useState('');
  const barcode = useAppSelector(barcodeReducer);

  

  // Método standar
  const handleKeyDown = (event: any) => {

    const addBarCodeStock = (term: string) => {
    
      ApiRequest.get<ResponseResumeOrderProvider>(`/es/reception-order-provider/create-one/${orderId}?term=${term}`)    
      .then(response => {
        beep.play();
        setResumeOrderProvider(response.data);
        dispatch(showToast({type: "success", message: 'Artículo añadido'}));
      })
      .catch(error => {
        dispatch(showToast({type: "danger", message: `Error: ${error.response.data}`}));
      });
    };

    if (event.key === 'Enter') {
      addBarCodeStock(event.target.value);
      setSearchText('');
    } else {
      setSearchText(event.target.value);
    }
  }  

  // Barcode útil
  useEffect(() => {
    const addBarCodeStock = (term: string) => {
    
      ApiRequest.get<ResponseResumeOrderProvider>(`/es/reception-order-provider/create-one/${orderId}?term=${term}`)    
      .then(response => {
        beep.play();
        setResumeOrderProvider(response.data);
        dispatch(showToast({type: "success", message: 'Artículo añadido'}));     
      })
      .catch(error => {
        dispatch(showToast({type: "danger", message: `Error: ${error.response.data}`}));
      });
    };
    
    if (barcode.value !== '' && barcode.location.includes('reception-order-provider')) {
      addBarCodeStock(barcode.value);
      dispatch(resetBarcode());
    }
  }, [orderId, setResumeOrderProvider, barcode, dispatch, beep]);

  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        <IonCol sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="12">
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol size="8">
                <IonItem class="item-search">
                  <IonLabel position="floating">EAN/SKU</IonLabel>
                  <IonInput
                      type="text" autofocus={true}
                      onKeyDown={(e: any) => handleKeyDown(e)}
                      value={search}
                      id="search"
                  ></IonInput>
                </IonItem>
              </IonCol>              
            </IonRow>
          </IonGrid>
        </IonCol>
      </IonRow>
    </IonGrid>        
  );
}

export default ReceivedSearch;
import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";
import ApiRequest from "../../../shared/ApiRequest";
import { showToast } from "../../../store/toastSlice";
import { useAppDispatch } from "../../../hooks/hook";
import { useReceptionOrder } from "../hooks/useReceptionOrder";
import { ReceptionItem, ResponseResumeOrderProvider } from "../interfaces";

type Props = {
  item: ReceptionItem;
}

const ReceivedButtons: React.FC<Props> = ({item}: Props) => {

  const { setResumeOrderProvider } = useReceptionOrder();

  const dispatch = useAppDispatch();
  const updateStock = (url: string) => {
    
    ApiRequest.get<ResponseResumeOrderProvider>(url)    
    .then(response => {
      setResumeOrderProvider(response.data);      
    })
    .catch(error => {
      dispatch(showToast({type: "danger", message: `Error: ${error.response.data}`}));
    });
  };

  const addOne = () => {
    updateStock(`/es/reception-order-provider/add-one/${item.id}`)
  }
  const removeOne = () => {
    updateStock(`/es/reception-order-provider/remove-one/${item.id}`)
  }

  return (
    <IonGrid class="buttons-received">      
      <IonRow>                    
        <IonCol>
          <IonButton size="small" color="medium" onClick={ () => addOne() }>
            <IonIcon className='icon-text' icon={addOutline}/>
          </IonButton>
        </IonCol>
        <IonCol>
          <IonButton size="small" fill="clear" color="light" expand="full">
            <IonText className='icon-text'>{item.stock}</IonText>
          </IonButton>
        </IonCol>
        <IonCol>
          { item.stock > 1 && <IonButton size="small" color="medium" onClick={ () => removeOne() } >
            <IonIcon className='icon-text' icon={removeOutline}/>
          </IonButton> }
        </IonCol>
      </IonRow>
    </IonGrid>      
  );
}

export default ReceivedButtons;
import React , {useEffect, useRef, useState} from "react";
import { IonInput, IonItem, IonLabel, getPlatforms, IonButton, IonGrid, IonRow, IonCol, IonIcon, IonModal, IonContent } from "@ionic/react";
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { NativeAudio } from '@capacitor-community/native-audio'
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { receivedReducer, setScan, setLocation } from "../../store/receivedSlice";
import { barcodeReducer, resetBarcode, setResetString } from "../../store/barcodeSlice";
import { barcodeOutline, stopCircleOutline, location } from 'ionicons/icons';
import { Hydramember, Provider } from "../../shared/domain/Provider";
import ReceivedModal from "./ReceivedModal";

const ReceivedSearch: React.FC = () => {
  
    NativeAudio.preload({
      assetId: "beep",
      assetPath: "beep.mp3",
      audioChannelNum: 1,
      isUrl: false
    });

    const [search, setSearchText] = useState('');
    const dispatch = useAppDispatch();
    const isMobile = getPlatforms().includes('capacitor') || getPlatforms().includes('cordova');
    const [buttonStop, setButtonStop] = useState(false);
    const barcode = useAppSelector(barcodeReducer);
    const [item, addItem] = useState<string>('');
    const received = useAppSelector(receivedReducer);
    const [isFocus, setFocus] = useState<boolean>(false);
    const modal = useRef<HTMLIonModalElement>(null);
    const modalProvider = useRef<HTMLIonModalElement>(null);
    const [providerList, setProviderList] = useState<Hydramember[]>([]);

    useEffect(() => {
      ApiRequest.get<Provider>("/es/providers?order[name]=ASC").then(response => {
        setProviderList( response.data["hydra:member"] );
      })
    }, []);

    const startScan = async () => {
      await BarcodeScanner.checkPermission({ force: true });
      document.querySelector('body')!.classList.add('scan');
      dispatch(setScan({scan: 'on'}));
      setButtonStop(true);
      scanning();
    };

    const scanning = async () => {
      const result = await BarcodeScanner.startScan({ targetedFormats: [ 
        SupportedFormat.UPC_A,
        SupportedFormat.UPC_E,
        SupportedFormat.UPC_EAN_EXTENSION,
        SupportedFormat.EAN_8, 
        SupportedFormat.EAN_13,
        SupportedFormat.CODE_39,
        SupportedFormat.CODE_93,
        SupportedFormat.CODE_128,
        SupportedFormat.CODABAR,
        SupportedFormat.ITF,
        SupportedFormat.CODABAR,
      ]});
      
      if (result.hasContent) {
        addItem(result.content as string);
        scanning();
      }
    }

    const stopScan = () => {
      BarcodeScanner.stopScan();
      BarcodeScanner.showBackground();
      document.querySelector('body')!.classList.remove('scan');
      dispatch(setScan({scan: 'off'}));
      setButtonStop(false);
    }

    const stopScanButton = () => {
      if (buttonStop) {
        return (
          <IonButton size="small" expand="block" className="button-barcode" onClick={ stopScan }>
            <IonIcon size="large" className='icon-text' icon={stopCircleOutline}></IonIcon>
          </IonButton>

        );
      } else {
        return (
          <IonButton size="small" expand="block" className="button-barcode" onClick={ startScan }>
            <IonIcon size="large" className='icon-text' icon={barcodeOutline}></IonIcon>
          </IonButton>
        );
      }
    }

    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        addItem(event.target.value)
        setSearchText('');
      } else {
        setSearchText(event.target.value);
      }
    }

    useEffect(() => {
      if (item !== '') {
        ApiRequest.post("es/warehouse_barcodes/addItem",{search:item}).then((res:any) => {
          NativeAudio.play({ assetId: 'beep', time: 0.0 });
          dispatch(showToast({type: "success", message: res.data}));
        })
        .catch(error => {
          if (error.status >= 400) {
            dispatch(showToast({type: "danger", message: "Error"}));
          }
        });
        addItem('');
      }
    }, [item,dispatch]);

    useEffect(() => {
      if (barcode.value !== '' && barcode.location === 'received') {
        addItem(barcode.value);
        dispatch(resetBarcode());
      }
    }, [barcode, dispatch]);

    const buttonScan = () => {
      setFocus(true);
      dispatch(setResetString(true));
    }

    useEffect(() => {
      if (isFocus) {
          if (barcode.input !== '' && barcode.location === 'received') {
              dispatch(setLocation({location: barcode.input}));
              dispatch(resetBarcode());
              setFocus(false);
              NativeAudio.play({ assetId: 'beep', time: 0.0 });
          }
      }
    }, [barcode.input, barcode.location, isFocus, dispatch]);

    const provider = () => {
      if (received.providerId !== 0) {
        const provider = providerList.find(provider => provider.id === received.providerId);
        return (provider?.name);
      } else {
        return (''); 
      }
    }

    const modalLocation = () => {
      return (
        <IonModal
          trigger="modal"
          ref = { modal }
          initialBreakpoint={0.25}
          breakpoints={[0, 0.25, 0.5, 0.75]}
          handleBehavior="cycle"
        >
          <IonContent className="ion-padding">
            <IonGrid className="ion-no-padding">
              <IonRow>
                <IonCol>
                  <div className="ion-text-center"><strong>Ubicación:</strong> { received.location }</div>
                  <IonRow className="ion-justify-content-center">
                    <IonCol sizeXl="6" sizeSm="12">
                      <IonItem lines="none">
                        <IonInput readonly={true} value="Nueva Localizacion" className="input-focus" color="danger" onClick={ buttonScan }></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonButton id="selectProvider">
                  <IonLabel>Proveedor: {provider()}</IonLabel>
                </IonButton>
                <IonModal trigger="selectProvider" ref={modalProvider}>
                  <ReceivedModal
                    items={providerList}
                    closeModal={() => modalProvider.current?.dismiss()}
                  />
                </IonModal>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      );
    }

    if (isMobile) {
      return (
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="12">
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol size="8">
                    <IonItem class="item-search">
                      <IonLabel position="floating">EAN/SKU</IonLabel>
                      <IonInput
                        type="text" autofocus={true}
                        onKeyDown={(e: any) => handleKeyDown(e)}
                        value={search}
                        id="search"
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="2">
                    <IonButton size="small" expand="block" className="button-barcode" id="modal">
                      <IonIcon size="large" className='icon-text' icon={location}></IonIcon>
                    </IonButton>
                  </IonCol>
                  <IonCol size="2">
                    { stopScanButton() }
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
          { modalLocation() }
        </IonGrid>
      );
    }
    
    return (
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="10">
            <IonGrid className="ion-no-padding">
              <IonRow>
                <IonCol size="10">
                  <IonItem class="item-search">
                    <IonLabel position="floating">EAN/SKU</IonLabel>
                    <IonInput
                      inputmode="text"
                      type="text" autofocus={true} onIonChange={(e: any) => setSearchText(e.target.value)}
                      onKeyDown={handleKeyDown}
                      value={search}
                      id="search"
                    ></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol size="2">
                  <IonButton size="small" expand="block" className="button-barcode" id="modal">
                    <IonIcon size="large" className='icon-text' icon={location}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
        { modalLocation() }
      </IonGrid>
    );
}

export default ReceivedSearch;
import { IonButton, useIonAlert } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/hook";
import ApiRequest from "../../shared/ApiRequest";
import { InventoriesItem, InventoryVar } from "../../shared/domain/InventoriesItem";
import { showToast } from "../../store/toastSlice";

type Props = {
    item: InventoriesItem,
    setInventory: (inventory: InventoryVar | ((prev: InventoryVar) => InventoryVar)) => void,
    inventory: InventoryVar,
}

const InventoryButtonSet: React.FC<Props> = ({item, setInventory, inventory}) => {

    const [presentAlert] = useIonAlert();
    const dispatch = useAppDispatch();
    const [buttonSet, setButtonSet] = useState(false);

    const addInventory = () => {
        ApiRequest.post("/es/inventories/"+item.id+"/set", inventory).then(response => {
            setInventory((inventory) => ({...inventory, hidden: true}));
            dispatch(showToast({type: "success", message: response.data.message}));
        })
        .catch(error => {
            dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
        });
    }

    useEffect(() => {
        if (inventory.stock || inventory.storage) {
            setButtonSet(true);
        } else {
            setButtonSet(false);
        }
    }, [inventory.stock, inventory.storage]);

    if (buttonSet) {
        return (
            <IonButton color="success" expand="block" onClick={() => presentAlert({
                    header: 'Atención',
                    subHeader: '¿Quieres cambiar los stocks de este artículo?',
                    buttons: [
                        {
                            text: 'Cancelar',
                            role: 'cancel',
                        },
                        {
                            text: 'Cambiar',
                            role: 'confirm',
                            handler: () => {
                                addInventory();
                            },
                        },
                    ],
                })
            }>Cambiar</IonButton>
        )
    } else {
        return (<div></div>)
    }
}

export default InventoryButtonSet;
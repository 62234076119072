import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { useState } from 'react';

export interface UserScan {
  filepath: string;
  webviewPath?: string;
  uploaded: boolean;
}

export function usePhotoGallery() {

  const [photos, setPhotos] = useState<UserScan[]>([]);

  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 85,
    });

    const newPhotos = [
      ...photos,
      {
        filepath: Date.now() + '.jpg',
        webviewPath: photo.webPath,
        uploaded: false
      }      
    ];

    setPhotos(newPhotos);
  };

  return {
    scans: photos,
    takeScan: takePhoto,
    setScans: setPhotos
  };
}
import React from 'react';
import { Redirect } from 'react-router-dom';
import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import AuthenticateJWT from '../shared/Authenticate';
import ButtonUser from '../components/ButtonUser';
import OrdersReadyList from '../components/OrdersReady/OrdersReadyList';

const OrdersReady: React.FC = () => {

    if (!AuthenticateJWT.isAuthenticated() && !AuthenticateJWT.isEmployee()) {
        return <Redirect to="/login" />
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Pedidos en Preparacion</IonTitle>
                    <ButtonUser />
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <OrdersReadyList />
            </IonContent>
        </IonPage>
    );
};

export default OrdersReady;
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonPage, IonRow } from '@ionic/react';
import React from 'react';
import { Redirect } from 'react-router';
import AuthenticateJWT from '../shared/Authenticate';
import {cubeOutline,createOutline,pushOutline,giftOutline,bagCheckOutline,archiveOutline,analyticsOutline, diceOutline, layersOutline} from 'ionicons/icons';


const Main: React.FC = () => {

    if (!AuthenticateJWT.isAuthenticated() && !AuthenticateJWT.isEmployee()) {
        return <Redirect to="/login" />
    }

    return (
      <IonPage>
        <IonContent>
          <IonGrid>
            <IonRow class="ion-justify-content-center">
              <IonCol sizeXs="12" sizeSm="6" sizeMd="4" sizeXl="3">
                <IonImg src={"unobike.png"} />
              </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-center">
              <IonCol sizeXs="12" sizeSm="6" sizeMd="4" sizeXl="3" >
                <IonButton color="unobike-red" expand="block" shape="round" size="large" routerLink='/received'>
                  <IonIcon slot="start" className="icon-text" icon={cubeOutline}/>
                  Recibidos
                </IonButton>
                <IonButton color="unobike-red" expand="block" shape="round" size="large" routerLink='/order'>
                  <IonIcon slot="start" className="icon-text" icon={bagCheckOutline}/>
                  Pedidos
                </IonButton>
                <IonButton color="unobike-red" expand="block" shape="round" size="large" routerLink='/picking'>
                  <IonIcon slot="start" className="icon-text" icon={archiveOutline}/>
                  Recolección
                </IonButton>
                <IonButton color="unobike-red" expand="block" shape="round" size="large" routerLink='/packing'>
                  <IonIcon slot="start" className="icon-text" icon={giftOutline}/>
                  Empaquetado
                </IonButton>
                <IonButton color="unobike-red" expand="block" shape="round" size="large" routerLink='/storage'>
                  <IonIcon slot="start" className="icon-text" icon={pushOutline}/>
                  Movimientos
                </IonButton>
                <IonButton color="unobike-red" expand="block" shape="round" size="large" routerLink='/inventory'>
                  <IonIcon slot="start" className="icon-text" icon={createOutline}/>
                  Inventario
                </IonButton>
                <IonButton color="unobike-red" expand="block" shape="round" size="large" routerLink='/measures'>
                  <IonIcon slot="start" className="icon-text" icon={analyticsOutline}/>
                  Medidas
                </IonButton>
                <IonButton color="unobike-red" expand="block" shape="round" size="large" routerLink='/remote-document'>
                  <IonIcon slot="start" className="icon-text" icon={layersOutline}/>
                  Scan Doc
                </IonButton>
                <IonButton color="unobike-red" expand="block" shape="round" size="large" routerLink='/reception-order-provider'>
                  <IonIcon slot="start" className="icon-text" icon={diceOutline}/>
                  Recepcionar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
}

export default Main;
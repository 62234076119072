import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';

interface ReceivedState {
    scan: string,
    count: number,
    location: string,
    providerId: number,
    refresh: boolean,
}

const initialState: ReceivedState = {
    scan: 'off',
    count: 0,
    location: 'B1A0',
    providerId: 0,
    refresh: true,
}

export const receivedSlice = createSlice({
  name: 'receivedItem',
  initialState,
  reducers: {
    setScan: (state, action: PayloadAction<{scan: string}>) => {
      state.scan = action.payload.scan
    },
    countProduct: (state, action: PayloadAction<{count: number}>) => {
      state.count = action.payload.count
    },
    setLocation: (state, action: PayloadAction<{location: string}>) => {
      state.location = action.payload.location
    },
    setProviderId: (state, action: PayloadAction<{providerId: number}>) => {
      state.providerId = action.payload.providerId
    },
    setRefresh: (state, action: PayloadAction<boolean>) => {
      state.refresh = action.payload
    }
  }
});

export const { setScan, countProduct, setLocation, setProviderId, setRefresh } = receivedSlice.actions
export const receivedReducer = (state: RootState) => state.received
export default receivedSlice.reducer
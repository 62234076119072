import { IonButton, IonIcon, useIonAlert } from "@ionic/react";
import { useAppDispatch } from "../../../hooks/hook";
import ApiRequest from "../../../shared/ApiRequest";
import { showToast } from "../../../store/toastSlice";
import { trashOutline } from "ionicons/icons";
import { useReceptionOrder } from "../hooks/useReceptionOrder";
import { ReceptionItem, ResponseResumeOrderProvider } from "../interfaces";

type Props = {
  item: ReceptionItem
}

const ReceivedButtonDel: React.FC<Props> = ({item}: Props) => {

  const { setResumeOrderProvider } = useReceptionOrder();
  const dispatch = useAppDispatch();
  const [ presentAlert ] = useIonAlert();

  const handleDelete = (id: number) => {   
    
    ApiRequest.delete<ResponseResumeOrderProvider>(`/es/reception-order-provider/${id}`)
    .then(response => {      
      setResumeOrderProvider(response.data);
      dispatch(showToast({type: "medium", message: "Artículo Borrado"}));
    })
    .catch(error => {
      dispatch(showToast({type: "danger", message: 'Error: '+error.response.data}));
    });
  }

  return (
    <IonButton color="danger" className="ion-float-right ion-no-margin"
      onClick={() =>
        presentAlert({
          header: 'Alerta',
          subHeader: '¿Quieres borrar el artículo?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Borrar',
              role: 'confirm',
              handler: () => {
                handleDelete(item.id);
              }
            }
          ]            
        })
      }
    >
      <IonIcon size="small" className='icon-text' icon={trashOutline}/>
    </IonButton>
  );
}

export default ReceivedButtonDel;
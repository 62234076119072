import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';

interface Inventory {
    refresh: boolean,
    scan: string,
    search: string,
}

const initialState: Inventory = {
    refresh: true,
    scan: 'off',
    search: '',
}

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    refresh: (state, action: PayloadAction<{refresh: boolean}>) => {
      state.refresh = action.payload.refresh
    },
    setScan: (state, action: PayloadAction<{scan: string}>) => {
      state.scan = action.payload.scan
    },
    setSearch: (state, action: PayloadAction<{search: string}>) => {
      state.search = action.payload.search
    }
  }
});

export const { refresh, setScan, setSearch } = inventorySlice.actions
export const inventoryReducer = (state: RootState) => state.inventory
export default inventorySlice.reducer
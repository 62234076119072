import { IonButton, IonCol, IonFooter, IonGrid, IonRow, IonToolbar, useIonLoading } from "@ionic/react";
import React  from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { packingReducer, setLocationCode, setOrderId, setUpdateShipment, setAgency } from "../../store/packingSlice";

const PackingReady: React.FC = () => {

    const packing = useAppSelector(packingReducer);
    const dispatch = useAppDispatch();
    const [present, dismiss] = useIonLoading();

    const handleReady = () => {
        present({
            message: 'Procesando...',
            spinner: 'circular'
        });
        
        ApiRequest.post("/es/orders/"+packing.orderId+"/packing",{'workstation': packing.workstation, 'packaging': packing.packaging, 'agency': packing.agency}).then(response => {
            dismiss();
            dispatch(showToast({type: "success", message: response.data}));
            dispatch(setLocationCode({locationCode: ''}));
            dispatch(setOrderId({orderId: 0}));
            dispatch(setAgency(''));
        })
        .catch(error => {
            dismiss();
            dispatch(setUpdateShipment({updateShipment: true}))
            dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
        });
    }

    if (packing.orderId !== 0) {
        return (
            <IonFooter>
                <IonToolbar>
                    <IonGrid className="ion-no-padding">
                        <IonRow class="ion-justify-content-center">
                            <IonCol sizeXl="4" sizeLg="6" sizeSm="6" sizeXs="12">
                                <IonButton color="success" expand="block" onClick={handleReady}>
                                    Listo
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonFooter>
        )
    } else {
        return null;
    }
}

export default PackingReady;
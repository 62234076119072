import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonRow, IonToolbar, useIonAlert, useIonLoading } from "@ionic/react";
import React  from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { storageReducer, refresh } from "../../store/storageSlice";
import { refreshOutline } from 'ionicons/icons';

const StorageSet: React.FC = () => {

    const dispatch = useAppDispatch();
    const [presentAlert] = useIonAlert();
    const [present, dismiss] = useIonLoading();
    const storage = useAppSelector(storageReducer);

    const handleStock = () => {
        present({
            message: 'Procesando...',
            spinner: 'circular'
        });
        ApiRequest.get("/es/storage_tmps/setAllItems").then(response => {
            dismiss();
            dispatch(refresh({refresh: true}));
        })
        .catch(error => {
            dismiss();
            dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
        });
    }

    const refresList = () => {
        dispatch(refresh({refresh: true}));
    }

    return (
        <IonToolbar>
            <IonGrid className="ion-no-padding">
                <IonRow class="ion-justify-content-center">
                    <IonCol sizeXl="4" sizeLg="6" sizeSm="6" sizeXs="12">
                        <IonGrid>
                            <IonRow>
                                <IonCol size="8">
                                    <IonButton color="unobike-red"
                                        expand="block"
                                        onClick={() => presentAlert({
                                                header: 'Alerta',
                                                subHeader: '¿Quieres depositar todos los artículos?',
                                                buttons: [
                                                    {
                                                        text: 'Cancelar',
                                                        role: 'cancel',
                                                    },
                                                    {
                                                        text: 'Añadir',
                                                        role: 'confirm',
                                                        handler: () => {
                                                            handleStock();
                                                        },
                                                    },
                                                ],
                                            })
                                        }
                                    >Depositar</IonButton>
                                </IonCol>
                                <IonCol size="2">
                                    <IonButton color="unobike-red" expand="block" onClick={ () => refresList() }>
                                        <IonIcon className='icon-text' icon={ refreshOutline }/>
                                    </IonButton>
                                </IonCol>
                                <IonCol size="2">
                                    <IonItem lines="none">{storage.count}</IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonToolbar>
    )
}

export default StorageSet;
import React, {useState} from 'react';
import { IonContent, IonRow, IonCol, IonIcon, IonPage, IonItem, IonLabel, IonInput, IonButton, IonGrid, IonText, IonFooter, IonImg, IonNote, getPlatforms } from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import { useHistory } from "react-router-dom";
import ApiRequest from '../shared/ApiRequest';
import AuthenticateJWT from '../shared/Authenticate';
import { useAppDispatch } from '../hooks/hook';
import { showToast } from '../store/toastSlice';
import { fetchToken } from '../firebase';
import { cloudDownloadOutline} from 'ionicons/icons';

const LoginComponent: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isValidEmail, setIsValidEmail] = useState<boolean>();
  const [isValidPassword, setIsValidPassword] = useState<boolean>();
  const dispatch = useAppDispatch();
  const isMobileweb = getPlatforms().includes('mobileweb');
  // const [deferredPrompt, setDeferredPrompt] = useState<any>(); 

  function checkEmail(email: string) {
    const re = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    return re.test(String(email).toLowerCase());
  }

  const validateEmail = () => {
    if (email === '') return; 
    checkEmail(email) !== false ? setIsValidEmail(true) : setIsValidEmail(false);
  }

  function loginUser() {

    if (!email) {
      setIsValidPassword(false);
    }

    if (!password) {
      setIsValidPassword(false);
    }

    if (email && password) {
      const loginData = {
        username: email,
        password: password,
      };
        
      ApiRequest.post("/login_check", loginData)
        .then((res:any) => {
          AuthenticateJWT.saveToken(res.data.token);
          if (AuthenticateJWT.isEmployee() || AuthenticateJWT.isAdmin()) {
              dispatch(showToast({type: "success", message: "Login correcto"}));
              history.push("/");
              fetchToken();
          } else {
            AuthenticateJWT.removeToken();
            dispatch(showToast({type: "danger", message: "Credenciales invalidas"}));
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            dispatch(showToast({type: "danger", message: "Credenciales invalidas"}));
          }
        })
    }
  };

  // window.addEventListener('beforeinstallprompt', (e) => {
  //   setDeferredPrompt(e);
  // });
  
  // const installPWAPrompt = () => {
  //   if (deferredPrompt) {
  //     deferredPrompt.prompt();
  //   }
  // };

  const buttonReaload = () => {
    if (isMobileweb) {

      const now = new Date();
      const timeMark = now.getTime();

      return (
        <IonRow>
          <IonCol className='ion-text-center ion-margin-top'>
            <IonButton color="unobike-red" href={`https://www.unobike.com/content/warehouse.apk?v=${timeMark}`} target='_blank'>
              <IonIcon slot="start" className="icon-text" icon={cloudDownloadOutline}/> Descargar
            </IonButton>
          </IonCol>
        </IonRow>
      )
    }
  }

  
  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow class="ion-justify-content-center">
            <IonCol sizeXs="12" sizeSm="6" sizeMd="4" sizeXl="3" >
              <IonImg src={"unobike.png"} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon className='icon-login' icon={personCircle}/>
            </IonCol>
          </IonRow>
          <IonRow class="ion-justify-content-center">
            <IonCol sizeXs="12" sizeSm="6" sizeMd="4" sizeXl="3" >
              <IonItem className={`${isValidEmail === false && 'ion-invalid'}`}>
                <IonLabel color="unobike-red" position="floating" >Email</IonLabel>
                <IonInput type="email" onIonChange={(event: any) => setEmail(event.target.value)} onIonBlur={() => validateEmail()}></IonInput>
                <IonNote slot="error">Email no valido</IonNote>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow class="ion-justify-content-center">
            <IonCol sizeXs="12" sizeSm="6" sizeMd="4" sizeXl="3" >
              <IonItem className={`${isValidPassword === false && 'ion-invalid'}`}>
                <IonLabel color="unobike-red" position="floating">Password</IonLabel>
                <IonInput type="password" onIonChange={(event: any) => setPassword(event.target.value)}></IonInput>
                <IonNote slot="error">Introduce una contraseña</IonNote>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className='ion-text-center ion-margin-top'>
              <IonButton color="unobike-red" fill='solid' onClick={loginUser}>Entrar</IonButton>
            </IonCol>
          </IonRow>
          {buttonReaload()}
        </IonGrid>
      </IonContent>
      <IonFooter className='unobike-background'>
        <IonRow>
          <IonCol className='ion-text-center'>
            <IonText className='unobike-text'>v. 1eebe7a</IonText>
          </IonCol>
        </IonRow>
      </IonFooter>
    </IonPage>
  );
};

export default LoginComponent;

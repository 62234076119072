import React from 'react'
import { DocumentType } from '../interfaces';
import { IonBadge } from '@ionic/react';

type Props = {
    documentType: DocumentType;
};

const DocumentTypeBadge: React.FC<Props> = ({ documentType }: Props) => {
  let badge = <></>;

  switch (documentType) {
    case DocumentType.DeliveryNote:
      badge = <IonBadge color="primary">Albarán</IonBadge>;
      break;

    case DocumentType.Invoice:
      badge = <IonBadge color="secondary">Factura</IonBadge>;
      break;

    case DocumentType.Other:
      badge = <IonBadge color="warning">Otros</IonBadge>;
      break;
  }

  return badge;
};

export default DocumentTypeBadge
import React from 'react';
import { Redirect } from 'react-router-dom';
import {  IonButtons, IonContent, IonHeader, IonItemDivider, IonMenuButton, IonPage, IonTitle, IonToolbar, IonFooter, IonItem, IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import AuthenticateJWT from '../shared/Authenticate';
import ButtonUser from '../components/ButtonUser';
import ReceivedSearch from '../components/Received/ReceivedSearch';
import ReceivedList from '../components/Received/ReceivedList';
import ButtonStockReceived from '../components/Received/ReceivedStock';
import { useAppDispatch, useAppSelector } from '../hooks/hook';
import { receivedReducer, setRefresh  } from '../store/receivedSlice';

const Received: React.FC = () => {

  const received = useAppSelector(receivedReducer);
  const dispatch = useAppDispatch();

  if (!AuthenticateJWT.isAuthenticated() && !AuthenticateJWT.isEmployee()) {
    return <Redirect to="/login" />
  }

  function handleRefreshReceived (event: CustomEvent<RefresherEventDetail>) {
    dispatch(setRefresh(true));
    event.detail.complete();
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonTitle>Recibidos</IonTitle>
          <ButtonUser />
        </IonToolbar>
      </IonHeader>
      <IonItem lines="none" className={ "scan "+received.scan}></IonItem>
      <IonContent className="unobike-background">
        <IonItemDivider sticky={true} className="shadow-box">
          <ReceivedSearch/>
        </IonItemDivider>
        <IonRefresher slot="fixed" onIonRefresh={handleRefreshReceived}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <ReceivedList/>
      </IonContent>
      <IonFooter>
        <ButtonStockReceived/>
      </IonFooter>
    </IonPage>
  );
};

export default Received;
import React from 'react';
import { Redirect } from 'react-router-dom';
import {  IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonItem, IonItemDivider, IonMenuButton, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, RefresherEventDetail } from '@ionic/react';
import AuthenticateJWT from '../shared/Authenticate';
import ButtonUser from '../components/ButtonUser';
import PickingList from '../components/Picking/PickingList';
import PickingRead from '../components/Picking/PickingRead';
import PickingReady from '../components/Picking/PickingReady';
import { useAppDispatch, useAppSelector } from '../hooks/hook';
import { pickingReducer, setRefresh  } from '../store/pickingSlice';

const Picking: React.FC = () => {

  const picking = useAppSelector(pickingReducer);
  const dispatch = useAppDispatch();

  if (!AuthenticateJWT.isAuthenticated() && !AuthenticateJWT.isEmployee()) {
    return <Redirect to="/login" />
  }

  function handleRefreshPicking (event: CustomEvent<RefresherEventDetail>) {
    dispatch(setRefresh(true));
    event.detail.complete();
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonTitle>Recolección</IonTitle>
          <ButtonUser />
        </IonToolbar>
      </IonHeader>
      <IonItem lines="none" className={ "scan "+picking.scan}></IonItem>
      <IonContent className="unobike-background">
        <IonItemDivider sticky={true} className="shadow-box">
          <PickingRead/>
        </IonItemDivider>
        <IonRefresher slot="fixed" onIonRefresh={handleRefreshPicking}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid>
          <IonRow>
            <IonCol>
              <PickingList />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <PickingReady />
      </IonFooter>
    </IonPage>
  );
};

export default Picking;
import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonItemDivider, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import ButtonUser from '../../components/ButtonUser';
import Authored from '../../components/AuthComponent';
import { receivedReducer } from "../../store/receivedSlice";
import { useAppSelector } from '../../hooks/hook';
import { OrderLoaderProvider } from './context/OrderLoaderProvider';
import './ReceptionOrder.css';
import { OrderLoaderList, Search, ProviderFindSelector } from './components';

const ReceptionOrder: React.FC = () => {

  const received = useAppSelector(receivedReducer);

  return (
    <Authored>
      <OrderLoaderProvider>
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton/>
              </IonButtons>
              <IonTitle>Recepcionar</IonTitle>
              <ButtonUser />
            </IonToolbar>
          </IonHeader>
          <IonItem lines="none" className={ "scan "+received.scan}></IonItem>
          <IonContent className="unobike-background">
          
            <IonItemDivider sticky={true} className="shadow-box">
              <IonGrid>
                <IonRow>                  
                  <IonCol size="auto">
                    <ProviderFindSelector />                    
                  </IonCol>
                  <IonCol size="auto">                  
                    <Search />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItemDivider>           
            
            <OrderLoaderList />

          </IonContent>      
        </IonPage>
      </OrderLoaderProvider>
    </Authored>
  );
};

export default ReceptionOrder;
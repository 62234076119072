import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';

interface Packing {
    scan : string,
    locationCode : string
    itemGtin : string,
    orderId : number,
    refresh: boolean,
    workstation: string,
    updateShipment: boolean,
    packaging: string,
    agency: string
}

const initialState: Packing = {
    scan: 'scan off',
    locationCode: '',
    itemGtin: '',
    orderId: 0,
    refresh: true,
    workstation: '1',
    updateShipment: false,
    packaging: '[]',
    agency: ''
}

export const packingSlice = createSlice({
    name: 'packing',
    initialState,
    reducers: {
        setItemGtin: (state, action: PayloadAction<{gtin: string}>) => {
            state.itemGtin = action.payload.gtin;
        },
        setScan: (state, action: PayloadAction<{scan: string}>) => {
            state.scan = action.payload.scan
        },
        setLocationCode: (state, action: PayloadAction<{locationCode: string}>) => {
            state.locationCode = action.payload.locationCode
        },
        setOrderId: (state, action: PayloadAction<{orderId: number}>) => {
            state.orderId = action.payload.orderId
        },
        refresh: (state, action: PayloadAction<{refresh: boolean}>) => {
            state.refresh = action.payload.refresh
        },
        setWorkstation: (state, action: PayloadAction<{workstation: string}>) => {
            state.workstation = action.payload.workstation
        },
        setUpdateShipment: (state, action: PayloadAction<{updateShipment: boolean}>) => {
            state.updateShipment = action.payload.updateShipment
        },
        setPackaging: (state, action: PayloadAction<string>) => {
            state.packaging = action.payload
        },
        setAgency: (state, action: PayloadAction<string>) => {
            state.agency = action.payload
        }
    }
});

export const {  setScan,
                setLocationCode,
                setItemGtin,
                setOrderId,
                refresh,
                setWorkstation,
                setUpdateShipment,
                setPackaging,
                setAgency
             } = packingSlice.actions
export const packingReducer = (state: RootState) => state.packing
export default packingSlice.reducer
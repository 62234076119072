import React , {useState} from "react";
import { IonInput, IonItem, IonLabel } from "@ionic/react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { setResetString } from '../../store/barcodeSlice';

type Props = {
    data: number,
}

const ReceivedBarcode: React.FC<Props> = ({data}) => {

    const [sku, setSku] = useState('');
    const dispatch = useAppDispatch();

    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        ApiRequest.post("es/warehouse_barcodes/addBarcode",{id: data, sku:sku}).then((res:any) => {
          dispatch(showToast({type: "success", message: res.data}));
        })
        .catch(error => {
          if (error.status >= 400) {
            dispatch(showToast({type: "danger", message: "Error"}));
          }
        });

        setSku('');
        dispatch(setResetString(true));
      }
    }
    
    return (
      <IonItem>
        <IonLabel position="floating">Referencia</IonLabel>
        <IonInput
          type="text"
          inputmode="text"
          autofocus={true}
          onIonChange={(e: any) => setSku(e.target.value)}
          onKeyDown={handleKeyDown}
          value={ sku }
        ></IonInput>
      </IonItem>
    );
}

export default ReceivedBarcode;
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { locationOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useReceptionOrder } from "../hooks";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { barcodeReducer, resetBarcode } from "../../../store/barcodeSlice";

const ModalLocation: React.FC = () => {
  
  const { location, setLocation, beep } = useReceptionOrder();
  const inputRef = useRef<HTMLIonInputElement>(null);
  const barcode = useAppSelector(barcodeReducer);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const onDidDismiss = () => {    
    setIsOpen(false);
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.setFocus();
    }
  };
  
  useEffect(() => {    
    if (isOpen && barcode.input !== '' && barcode.location.includes('reception-order-provider/')) {
      setLocation(barcode.input)
      dispatch(resetBarcode());
      setIsOpen(false);
      beep.play();
    }
    
  }, [isOpen, setLocation, dispatch, barcode.input, barcode.location, beep]);

  return (
    <>
      <IonButton onClick={() => setIsOpen(true)} color="unobike-red" style={{marginTop: '9px'}}>
        <IonIcon icon={locationOutline} ></IonIcon> {location}
      </IonButton>
      <IonModal isOpen={isOpen} onDidPresent={focusInput} onDidDismiss={onDidDismiss} >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsOpen(false)}>Cerrar</IonButton>
            </IonButtons>
            <IonTitle>Selecciona Localización</IonTitle>            
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>                  
            <IonInput
              ref={inputRef}
              readonly={true}
              value={location}
            />
          </IonItem>
        </IonContent>
      </IonModal>
    </>
  );
}

export default ModalLocation;
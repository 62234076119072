import React, { useEffect, useState } from "react";
import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonTextarea } from "@ionic/react";
import { InventoriesItem, InventoryVar } from "../../shared/domain/InventoriesItem";
import InventoryButtonDel from "./InventoryButtonDel";
import InventoryButtonStock from "./InventoryButtonStock";
import InventoryButtonStorage from "./InventoryButtonStorage";
import InventoryButtonSet from "./InventoryButtonSet";

type Props = {
    item: InventoriesItem | undefined,
}

const inventoryVar: InventoryVar = {
    stock: null,
    storageId: null,
    storage: null,
    note: null,
    hidden: false,
}

const InventoryItem: React.FC <Props> = ({item}) => {

    const [inventory, setInventory] = useState<InventoryVar>(inventoryVar);

    useEffect(() => {
        if (item) {
            setInventory((inventory) => {
                return {...inventory, hidden: false}
            });
        }
    }, [item]);

    if (item) {
        const finish = () => {
            if (item.variant.description) {
                return (
                    <span><strong>Acabado:</strong> { item.variant.description }</span>
                );
            }
        }

        return (
            <IonCol hidden={inventory.hidden} sizeXl="3" sizeLg="4" sizeSm="6" sizeXs="12" className="ion-no-padding" key={'i-'+item.id}>
                <IonItem lines="none">
                    <IonLabel>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="11">
                                    <h2><strong>{ item.variant.sku }</strong></h2>
                                </IonCol>
                                <IonCol size="1">
                                    <InventoryButtonDel item={ item } setInventory={ setInventory }/>
                                </IonCol>
                                <IonCol size="12">
                                    <h3>{ item.product.name }</h3>
                                    { finish() }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6" key={'buttonStock-'+item.id}>
                                    <InventoryButtonStock item={ item } setInventory={ setInventory }/>
                                </IonCol>
                                <IonCol size="6" key={'buttonStorage-'+item.id}>
                                {
                                    item.storages.map((storage) => {
                                        return (
                                            <InventoryButtonStorage storage={ storage } setInventory={ setInventory }/>
                                        )    
                                    })
                                }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem>
                                        <IonLabel position="floating">Comentario</IonLabel>
                                        <IonTextarea value={inventory.note} onIonChange={(event: any) => setInventory({ ...inventory, note: event.target.value})}/>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12">
                                    <InventoryButtonSet item={ item } setInventory={ setInventory } inventory={inventory}/>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonLabel>
                </IonItem>
            </IonCol>
        );
    } else {
        return (<></>)
    }
}

export default InventoryItem;
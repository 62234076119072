import { OrderProviderItem, ProviderDocument, ReceptionItem, ReceptionOrderState, ResponseResumeOrderProvider } from "../interfaces";

type ReceptionOrderAction =
  | {type: 'addAvailableItems', payload: {availableItems: OrderProviderItem[]}}
  | {type: 'addReceptionItem', payload: {receptionItem: ReceptionItem}}
	| {type: 'addReceptionItems', payload: {receptionItems: ReceptionItem[]}}  
	| {type: 'addDocuments', payload: {documents: ProviderDocument[]}}
  | {type: 'deleteReceptionItem', payload: {id: number}}
  | {type: 'increaseReceptionStockItem', payload: {id: number, stock: number}}
  | {type: 'setResumeOrderProvider', payload: {resumeOrderProvider: ResponseResumeOrderProvider}}  
  | {type: 'setNote', payload: {note: string}}
  | {type: 'setLocation', payload: {location: string}}
  | {type: 'setDocumentId', payload: {documentId: number}}
  | {type: 'updateEanFromReceptionItem', payload: {id: number, barcode: string}}
  | {type: 'toggleScanView'};  

export const receptionOrderReducer = (state: ReceptionOrderState, action: ReceptionOrderAction): ReceptionOrderState => {
  switch( action.type ) {
    case 'setResumeOrderProvider':

      const receptionItemsCount = action.payload.resumeOrderProvider.receptionItems.reduce((total, item) => total + item.stock, 0);      

      return {
				...state,
        order: {note: action.payload.resumeOrderProvider.note as string},
        availableItemsCount: action.payload.resumeOrderProvider.availableItems.length,
        receptionItemsCount,
        availableItems: [...action.payload.resumeOrderProvider.availableItems],
        receptionItems: [...action.payload.resumeOrderProvider.receptionItems]
			}
    
    case 'setNote':
			return {
				...state,
        order: {...state.order, note: action.payload.note}
			}

    case 'toggleScanView':
			return {
				...state,
        scanActive: !state.scanActive
			}

    case 'addReceptionItem':
			return {
				...state,
        receptionItems: [...state.receptionItems, action.payload.receptionItem]
			}

    case 'deleteReceptionItem':
      const receptinItemsNew = [...state.receptionItems.filter(item => item.id !== action.payload.id)];
      return {
        ...state,
        receptionItems: receptinItemsNew,
        receptionItemsCount: receptinItemsNew.length
      }

		case 'addDocuments':
			return {
				...state,
				documents: action.payload.documents
			}
    
    case 'increaseReceptionStockItem':
      const newState = {...state};
      const indiceProducto = newState.receptionItems.findIndex(producto => producto.id === action.payload.id);

      newState.receptionItems[indiceProducto] = {
        ...newState.receptionItems[indiceProducto],
        stock: action.payload.stock
      };
      return newState;

    case 'updateEanFromReceptionItem':
      const newStateEan = {...state};
      const indiceProductoEan = newStateEan.receptionItems.findIndex(producto => producto.id === action.payload.id);

      newStateEan.receptionItems[indiceProductoEan] = {
        ...newStateEan.receptionItems[indiceProductoEan],
        barcode: action.payload.barcode
      };
      return newStateEan;

    case 'setLocation':
      return {
        ...state,
        location: action.payload.location
      }

    case 'setDocumentId':
      return {
        ...state,
        documentId: action.payload.documentId
      }
    default:
      return state;
  }
}
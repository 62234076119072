import { configureStore } from '@reduxjs/toolkit';
import toastReducer from './toastSlice'
import receivedReducer from './receivedSlice'
import storageReducer from './storageSlice'
import ordersReadyReducer from './ordersReadySlice'
import pickingReducer from './pickingSlice'
import packingReducer from './packingSlice'
import barcodeReducer from './barcodeSlice'
import inventoryReducer from './inventorySlice'
import ordersReducer from './ordersSlice'
import measuresReducer from './measuresSlice'

export const store = configureStore({
  reducer: {
    toast: toastReducer,
    received: receivedReducer,
    storage: storageReducer,
    ordersReady: ordersReadyReducer,
    picking: pickingReducer,
    packing: packingReducer,
    barcode: barcodeReducer,
    inventory: inventoryReducer,
    orders: ordersReducer,
    measures: measuresReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
import { IonButton, IonCol, IonGrid, IonRow, IonToolbar, useIonAlert, useIonLoading } from "@ionic/react";
import React  from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";

const PickingReady: React.FC = () => {

    const dispatch = useAppDispatch();
    const [presentAlert] = useIonAlert();
    const [present, dismiss] = useIonLoading();

    const handleStock = () => {
        present({
            message: 'Procesando...',
            spinner: 'circular'
        });
        ApiRequest.get("/es/shipment/set-provider").then(response => {
            dismiss();
            dispatch(showToast({type: "success", message: response.data}));
        })
        .catch(error => {
            dismiss();
            dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
        });
    }

    return (
        <IonToolbar>
            <IonGrid className="ion-no-padding">
                <IonRow class="ion-justify-content-center">
                    <IonCol sizeXl="4" sizeLg="6" sizeSm="6" sizeXs="12">
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12">
                                    <IonButton color="unobike-red"
                                        expand="block"
                                        onClick={() => presentAlert({
                                                header: 'Alerta',
                                                subHeader: '¿Quieres preparar todos estos articulos?',
                                                buttons: [
                                                    {
                                                        text: 'Cancelar',
                                                        role: 'cancel',
                                                    },
                                                    {
                                                        text: 'Preparar',
                                                        role: 'confirm',
                                                        handler: () => {
                                                            handleStock();
                                                        },
                                                    },
                                                ],
                                            })
                                        }
                                    >Preparar</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonToolbar>
    )
}

export default PickingReady;
import React from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch } from "../../hooks/hook";
import { Hydramember } from "../../shared/domain/ItemReceived";
import { trashOutline} from 'ionicons/icons';
import { showToast } from "../../store/toastSlice";
import { IonButton, IonIcon, useIonAlert } from "@ionic/react";

type Props = {
    data: Hydramember,
}

const ReceivedButtonDel: React.FC <Props> = ({data}) => {
    const dispatch = useAppDispatch();
    const [ presentAlert ] = useIonAlert();

    const handleDelete = (id: number) => {
        ApiRequest.delete("/es/warehouse_barcodes/"+id)
        .then(response => {
            dispatch(showToast({type: "medium", message: "Artículo Borrado"}));
        })
        .catch(error => {
            dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
        });
    }

    return (
        <IonButton color="danger" className="ion-float-right ion-no-margin"
            onClick={() =>
                presentAlert({
                    header: 'Alerta',
                    subHeader: '¿Quieres borrar el artículo?',
                    buttons: [
                        {
                            text: 'Cancelar',
                            role: 'cancel',
                        },
                        {
                            text: 'Borrar',
                            role: 'confirm',
                            handler: () => {
                                handleDelete(data.id);
                            },
                        },
                    ],
                    
                })
            }
        >
            <IonIcon size="small" className='icon-text' icon={trashOutline}/>
        </IonButton>
    );
}

export default ReceivedButtonDel;
import { IonPage } from "@ionic/react";
import Authored from "../../../components/AuthComponent";
import { ListAndScanScreens } from "../components";
import { ReceptionOrderProvider } from "../context/ReceptionOrderProvider";

import './ReceptionOrderView.css';

const ReceptionOrderView: React.FC = () => {  

  return (
    <Authored>
      <ReceptionOrderProvider>
        <IonPage>
          <ListAndScanScreens />
        </IonPage>
      </ReceptionOrderProvider>
    </Authored>
  );
};

export default ReceptionOrderView;
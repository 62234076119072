import { useIonRouter, IonLoading, IonList, IonItem, IonLabel, IonText, IonButton, IonIcon } from "@ionic/react";
import { barcodeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useOrderLoader } from "../hooks";
import { OrderProviderStatus } from "../interfaces";

const OrderLoaderListByTerm: React.FC = () => {
    const { term, formatDateTime, fetchOrdersBySearch, loadingOrders, ordersBySearch } = useOrderLoader();
    const [state, setState] = useState({term, fetchOrdersBySearch});
    const router = useIonRouter();
  
    const goToPage = (id: number) => {
      router.push(`/reception-order-provider/${id}`);
    };
  
    useEffect(() => {    
      state.fetchOrdersBySearch(state.term);
    }, [state]);

    useEffect(() => {
      if ( term !== state.term ) {
        setState({...state, term})
      }
    }, [term, state])
  
    return (
      <>
        <IonLoading
          isOpen={loadingOrders}        
          message={'Cargando...'}        
        />
      
        <IonList>
          {ordersBySearch.map(({id, number, codeNumber, dateCreated, dateExpected, provider, pipelineStage, orderProviderItems}) => (
            <IonItem key={'t'+id}>
              <IonLabel>
                <h2>
                  {`Nº ${number} ${provider.name}`} <IonText color="medium"><small>{codeNumber}</small></IonText>
                </h2>
                <p>
                {
                  dateExpected && new Date(dateExpected) > new Date()
                    ? <IonText color="warning">{`Previsto: ${formatDateTime(String(dateExpected))}`}</IonText>
                    : formatDateTime(dateCreated)
                }
                </p>
              </IonLabel>
              
              <div slot="end">
                <IonButton className={pipelineStage.status === OrderProviderStatus.Parcial ? "partial-color-background" : "red-background"} size="small" onClick={() => goToPage(id)}>
                  {
                    pipelineStage.status === OrderProviderStatus.Parcial
                      ? `${orderProviderItems.filter(item => item.status === 2).length} (${orderProviderItems.length})`
                      : <IonIcon icon={barcodeOutline} color="light"></IonIcon>
                  }
                                        
                </IonButton>
              </div>
            </IonItem>
          ))}
        </IonList>       
      </>
    );
}

export default OrderLoaderListByTerm;
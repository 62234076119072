import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';

interface BarcodeState {
  location: string,
  value: string,
  input: string,
  resetString: boolean
}

const initialState: BarcodeState = {
    location: '',
    value: '',
    input: '',
    resetString: false
}

export const barcodeSlice = createSlice({
  name: 'barcode',
  initialState,
  reducers: {
    bodyBarcode: (state, action: PayloadAction<{location: string, value: string}>) => {
        state.location = action.payload.location;
        state.value = action.payload.value;
    },
    inputBarcode: (state, action: PayloadAction<{location: string, input: string}>) => {
        state.location = action.payload.location;
        state.input = action.payload.input;
    },
    resetBarcode: state => {
        state.location = '';
        state.value = '';
        state.input = '';
    },
    setResetString: (state, action: PayloadAction<boolean>) => {
        state.resetString = action.payload;
    } 
  }
});

export const { bodyBarcode, inputBarcode, resetBarcode, setResetString } = barcodeSlice.actions
export const barcodeReducer = (state: RootState) => state.barcode
export default barcodeSlice.reducer
import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRouterOutlet, IonTitle, IonToolbar, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Login from './pages/Login';
import Inventory from './pages/Inventory';
import Received from './pages/Received';
import Order from './pages/Order';
import Picking from './pages/Picking';
import Packing from './pages/Packing';
import Main from './pages/Main';
import Storage from './pages/Storage';
import OrdersReady from './pages/OrdersReady';
import Measures from './pages/Measures';
import { ReceptionOrder, ReceptionOrderView, RemoteDocument } from './pages';
import {cubeOutline,createOutline,pushOutline,giftOutline,bagCheckOutline,archiveOutline,analyticsOutline, diceOutline, layersOutline} from 'ionicons/icons';
import BarcodeReader from './components/BarcodeReader';
import { onMessageListener } from './firebase';
import { registerNotifications } from './components/NotificationPush';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/unobike.css';

setupIonicReact();

const App: React.FC = () => {

  if (window.location.pathname === '/index.html') {
    window.location.pathname = '/';
  }

  BarcodeReader();

  useEffect (() => {
    registerNotifications();
    onMessageListener()
  }, []);

  return (
  <IonApp className="unobike-theme">
    <IonReactRouter>
      <IonMenu side="start" contentId='menu'>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonMenuToggle>
              <IonItem routerLink='/received' routerDirection='none' lines='none'>
                <IonIcon slot="start" className="icon-text" icon={cubeOutline}/>
                <IonLabel>Recibidos</IonLabel>
              </IonItem>
              <IonItem routerLink='/order' routerDirection='none' lines='none'>
                <IonIcon slot="start" className="icon-text" icon={bagCheckOutline}/>
                <IonLabel>Pedidos</IonLabel>
              </IonItem>
              <IonItem routerLink='/picking' routerDirection='none' lines='none'>
                <IonIcon slot="start" className="icon-text" icon={archiveOutline}/>
                <IonLabel>Recolección</IonLabel>
              </IonItem>
              <IonItem routerLink='/packing' routerDirection='none' lines='none'>
                <IonIcon slot="start" className="icon-text" icon={giftOutline}/>
                <IonLabel>Empaquetado</IonLabel>
              </IonItem>
              <IonItem routerLink='/storage' routerDirection='none' lines='none'>
                <IonIcon slot="start" className="icon-text" icon={pushOutline}/>
                <IonLabel>Movimientos</IonLabel>
              </IonItem>
              <IonItem routerLink='/inventory' routerDirection='none' lines='none'>
                <IonIcon slot="start" className="icon-text" icon={createOutline}/>
                <IonLabel>Inventario</IonLabel>
              </IonItem>
              <IonItem routerLink='/measures' routerDirection='none' lines='none'>
                <IonIcon slot="start" className="icon-text" icon={analyticsOutline}/>
                <IonLabel>Medidas</IonLabel>
              </IonItem>
              <IonItem routerLink='/remote-document' routerDirection='none' lines='none'>
                <IonIcon slot="start" className="icon-text" icon={layersOutline}/>
                <IonLabel>Scan doc</IonLabel>
              </IonItem>
              <IonItem routerLink='/reception-order-provider' routerDirection='none' lines='none'>
                <IonIcon slot="start" className="icon-text" icon={diceOutline}/>
                <IonLabel>Recepcionar</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        </IonContent>
      </IonMenu>
      <IonRouterOutlet id="menu">
        <Route exact path="/" component={Main}/>
        <Route exact path="/received" component={Received}/>
        <Route exact path="/order" component={Order}/>
        <Route exact path="/picking" component={Picking}/>
        <Route exact path="/packing" component={Packing}/>
        <Route exact path="/storage" component={Storage}/>
        <Route exact path="/inventory" component={Inventory}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/orders-ready" component={OrdersReady}/>
        <Route exact path="/measures" component={Measures}/>
        <Route exact path="/reception-order-provider" component={ReceptionOrder}/>
        <Route exact path="/reception-order-provider/:id" component={ReceptionOrderView}/>        
        <Route exact path="/remote-document" component={RemoteDocument}/>        
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
)};

export default App;
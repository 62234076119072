import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonMenuButton, IonPage, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from '@ionic/react';
import AuthenticateJWT from '../shared/Authenticate';
import ButtonUser from '../components/ButtonUser';
import InventoryRead from '../components/Inventory/InventoryRead';
import { inventoryReducer, refresh  } from '../store/inventorySlice';
import { useAppDispatch, useAppSelector } from '../hooks/hook';
import InventoryList from '../components/Inventory/InventoryList';

const Inventory: React.FC = () => {

  const inventory = useAppSelector(inventoryReducer);
  const dispatch = useAppDispatch();
  const [isList, setIsList] = useState<string>('list');

  if (!AuthenticateJWT.isAuthenticated() && !AuthenticateJWT.isEmployee()) {
    return <Redirect to="/login" />
  }

  const content = () => {
    if (isList === 'list') {
      dispatch(refresh({refresh: true}));
      return (
        <InventoryList/>
      );
    } else {
      return (
        <InventoryRead/>
      );
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonTitle>Inventario</IonTitle>
          <ButtonUser />
        </IonToolbar>
      </IonHeader>
      <IonItem lines="none" className={ "scan "+inventory.scan}></IonItem>
      <IonContent className="unobike-background">
        <IonSegment value={isList} className="shadow-box" onIonChange={(e: any) => setIsList(e.detail.value)}>
          <IonSegmentButton value="list">
            <IonLabel>Listado</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="manual">
            <IonLabel>Manual</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        {content()}
      </IonContent>
    </IonPage>
  );
};

export default Inventory;
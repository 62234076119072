import { useReducer } from "react"
import { OrderProviderItem, ProviderDocument, ReceptionItem, ReceptionOrderState , ResponseDocumentList, ResponseResumeOrderProvider } from "../interfaces"
import { ReceptionOrderContext } from "./ReceptionOrderContext"
import { receptionOrderReducer } from "./ReceptionOrderReducer"
import { useParams } from "react-router"
import AuthenticateJWT from "../../../shared/Authenticate"
import { Hydramember } from "../../../shared/domain/ItemReceived";
import ApiRequest from "../../../shared/ApiRequest";
import { BeepService } from "../../../services/BeepService"

const INITIAL_STATE: ReceptionOrderState = {
    order: {note: ''},
    availableItemsCount: 0,
    receptionItemsCount: 0,
    availableItems: [],
    availableIdVariants: [],
    availableSkus: [],
    receptionItems: [],
    documents: [],
    location: 'B1A0',
    scanActive: false,
    documentId: 0
}

type Props = {
    children: JSX.Element | JSX.Element[]
}

export const ReceptionOrderProvider = ({children}: Props) => {

    const { id } = useParams<{id: string}>();
    const userId = AuthenticateJWT.getUser()!.sub;
    const [receptionOrderState, dispatch] = useReducer(receptionOrderReducer, INITIAL_STATE);

    const addDocuments = (documents: ProviderDocument[]) => {
        dispatch({type: 'addDocuments', payload: {documents}})
    }            
    const setNote = (note: string) => {
        dispatch({type: 'setNote', payload: {note}})
    }
    const setLocation = (location: string) => {
        dispatch({type: 'setLocation', payload: {location}})
    }
    const setResumeOrderProvider = (resumeOrderProvider: ResponseResumeOrderProvider) => {
        dispatch({type: 'setResumeOrderProvider', payload: {resumeOrderProvider}})
    }
    const toggleScanView = () => {
        dispatch({type: 'toggleScanView'})
    }
    const addReceptionItem = (receptionItem: ReceptionItem) => {
        dispatch({type: 'addReceptionItem', payload: {receptionItem}})    
    }
    const setDocumentId = (documentId: number) => {
        dispatch({type: 'setDocumentId', payload: {documentId}})
    }
    const updateEanFromReceptionItem = (id: number, barcode: string) => {
        dispatch({type: 'updateEanFromReceptionItem', payload: {id, barcode}})
    }

    const isStockAllowed = (idVariant: number, stockToAdd: number, receptionItems: Hydramember[], availableItems: OrderProviderItem[]): boolean => {

        const availableItem = availableItems.filter(item => item.variant!.id === idVariant)[0];
        const availableStock = availableItem.quantity;
        const availableVariantId = availableItem.variant!.id;
        const receptionItem = receptionItems.filter(item => item.variant.id === availableVariantId)[0];
        const receptionStock = receptionItem ? receptionItem.stock : 0;

        return ( stockToAdd + receptionStock <= availableStock ) ? true : false;        
    }

    const isStockAllowedBySku = (sku: string, stockToAdd: number, receptionItems: Hydramember[], availableItems: OrderProviderItem[]): boolean => {

        const availableItem = availableItems.filter(item => item.sku === sku)[0];
        const availableStock = availableItem.quantity;
        const availableVariantId = availableItem.variant!.id;
        const receptionItem = receptionItems.filter(item => item.variant.id === availableVariantId)[0];
        const receptionStock = receptionItem ? receptionItem.stock : 0;

        return ( stockToAdd + receptionStock <= availableStock ) ? true : false;        
    }

    const fetchAllDocuments = async() => {
        const responseDocuments = await ApiRequest.get<ResponseDocumentList>(`/es/scandocument/list/${id}`)    
        addDocuments(responseDocuments.data.data);
    };

    const fetchAvailableAndReceptionItems = async() => {
        const responseResumeOrderProvider = await ApiRequest.get<ResponseResumeOrderProvider>(`/es/reception-order-provider/resume/${id}`);  
        
        setResumeOrderProvider(responseResumeOrderProvider.data);
    };    

    const beep = new BeepService();

    return (
        <ReceptionOrderContext.Provider value={{
            orderId: id,
            userId,
            receptionOrderState,                    
            addDocuments,
            addReceptionItem,
            isStockAllowed,
            isStockAllowedBySku,
            setResumeOrderProvider,            
            toggleScanView,
            fetchAllDocuments,
            fetchAvailableAndReceptionItems,
            setNote,
            setLocation,
            setDocumentId,
            updateEanFromReceptionItem,
            beep
        }}>
            { children }
        </ReceptionOrderContext.Provider>
    )
}
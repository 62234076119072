import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';

interface OrdersReady {
    orders : string,
    orderBox : string,
    itemGtin : string,
    quantityOrders : number,
    quantityItems : number,
}

const initialState: OrdersReady = {
    orders: '[]',
    orderBox: '',
    itemGtin: '',
    quantityOrders: 0,
    quantityItems: 0,
}

export const ordersReadySlice = createSlice({
    name: 'ordersReady',
    initialState,
    reducers: {
        addOrder: (state, action: PayloadAction<{orderId: string}>) => {
            let arrayOrders = JSON.parse(state.orders);
            arrayOrders.push(action.payload.orderId);
            state.orders = JSON.stringify(arrayOrders);
        },
        delOrder: (state, action: PayloadAction<{orderId: number}>) => {
            let arrayOrders = JSON.parse(state.orders);
            arrayOrders = arrayOrders.filter((item: number) => item !== action.payload.orderId);
            state.orders = JSON.stringify(arrayOrders);
        },
        setOrderList: (state, action: PayloadAction<{orders: string}>) => {
            state.orders = action.payload.orders;
        },
        resetOrder: (state) => {
            state.orders = '[]';
        },
        setQuantityOrders: (state, action: PayloadAction<{quantity: number}>) => {
            state.quantityOrders = action.payload.quantity;
        },
        setQuantityItems: (state, action: PayloadAction<{quantity: number}>) => {
            state.quantityItems = action.payload.quantity;
        },
    }
});

export const {  addOrder,
                delOrder,
                setOrderList,
                resetOrder,
                setQuantityOrders,
                setQuantityItems,
             } = ordersReadySlice.actions
export const ordersReadyReducer = (state: RootState) => state.ordersReady
export default ordersReadySlice.reducer
import React from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch } from "../../hooks/hook";
import { trashOutline} from 'ionicons/icons';
import { showToast } from "../../store/toastSlice";
import { IonButton, IonIcon, useIonAlert } from "@ionic/react";

type Props = {
    id: number,
}

const OrderReadyDel: React.FC <Props> = ({id}) => {
    const dispatch = useAppDispatch();
    const [ presentAlert ] = useIonAlert();

    const handleDelete = () => {
        ApiRequest.get("/es/order_readies/del/"+id)
        .then(response => {
            dispatch(showToast({type: "medium", message: "Pedido Borrado"}));
        })
        .catch(error => {
            dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
        });
    }

    return (
        <IonButton size="default" color="danger" className="ion-float-right ion-no-margin"
            onClick={() =>
                presentAlert({
                    header: 'Alerta',
                    subHeader: '¿Quieres borrar este pedido?',
                    buttons: [
                        {
                            text: 'Cancelar',
                            role: 'cancel',
                        },
                        {
                            text: 'Borrar',
                            role: 'confirm',
                            handler: () => {
                                handleDelete();
                            },
                        },
                    ],
                    
                })
            }
        >
            <IonIcon className='icon-text' icon={trashOutline}/>
        </IonButton>
    );
}

export default OrderReadyDel;
import { createContext } from "react";
import { OrderProviderItem, ProviderDocument, ReceptionItem, ReceptionOrderState, ResponseResumeOrderProvider } from "../interfaces";
import { Hydramember } from "../../../shared/domain/ItemReceived";
import { BeepService } from "../../../services/BeepService";

export type ReceptionOrderContextProps = {
    orderId: string,
    userId: string,
    receptionOrderState: ReceptionOrderState,    
    addDocuments: (documents: ProviderDocument[]) => void,
    addReceptionItem: (receptionItem: ReceptionItem) => void,
    isStockAllowed: (idVariant: number, stockToAdd: number, receptionItems: Hydramember[], availableItems: OrderProviderItem[]) => boolean,
    isStockAllowedBySku: (sku: string, stockToAdd: number, receptionItems: Hydramember[], availableItems: OrderProviderItem[]) => boolean,    
    toggleScanView: () => void,
    fetchAllDocuments: () => void,
    fetchAvailableAndReceptionItems: () => void,
    setNote:(note: string) => void,
    setLocation:(location: string) => void,
    setResumeOrderProvider: (resumeOrderProvider: ResponseResumeOrderProvider) => void,
    setDocumentId: (documentId: number) => void,
    updateEanFromReceptionItem: (id: number, barcode: string) => void,
    beep: BeepService
}

export const ReceptionOrderContext = createContext<ReceptionOrderContextProps>({} as ReceptionOrderContextProps);
import { useState } from "react";
// import { useScanGallery } from "../../../hooks";
import { usePhotoGallery } from "../../../hooks";
import ScanDocumentService from "../../../services/ScanDocumentService";
import { useReceptionOrder } from "./";
import { DocumentType, ResponseUploadedScans } from "../interfaces";
import ApiRequest from "../../../shared/ApiRequest";

export const useDocumentScan = (orderId: number) => {
  const { fetchAllDocuments, toggleScanView, setDocumentId } = useReceptionOrder();
  const { scans, takeScan, setScans } = usePhotoGallery();
  const [showLoading, setShowLoading] = useState(false);
  const [returnDocumentList, setReturnDocumentList] = useState(false);
  const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.None);

  const handleUpload = async() => {   

    setShowLoading(true);

    const scanCodumentService = new ScanDocumentService( orderId );
    scanCodumentService.documentType = documentType;

    const documentId = await scanCodumentService.uploadAll(scans)
    if ( documentId ) {
      
      setDocumentId(documentId);
      setDocumentType(0);
      setScans([]);
      fetchAllDocuments();
      toggleScanView();
    }
    setShowLoading(false);
    setReturnDocumentList(true);
  }

  const handleAddDocument = async(documentId: number, orderProviderId: number) => {
    setShowLoading(true);
    
    const response = await ApiRequest.get<ResponseUploadedScans>(`/es/scandocument/add-document/${documentId}/${orderProviderId}`);
    setDocumentId(response.data.documentId);  
    toggleScanView();
    setShowLoading(false);    
  }

  const handleDeleteScan = (index: number) => {
      
    const updatedScans = [...scans];
    updatedScans.splice(index, 1);
    
    setScans(updatedScans);
  }   

  return {scans, takeScan, setScans, showLoading, setShowLoading, returnDocumentList, setReturnDocumentList, handleDeleteScan, handleUpload,
    documentType,
    setDocumentType,
    handleAddDocument
  };
}
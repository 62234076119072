import React , {useState, useEffect} from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { IonButton, IonIcon, IonInput, getPlatforms, IonCol, IonGrid, IonRow, IonItem, IonLabel } from "@ionic/react";
import { BarcodeScanner, SupportedFormat } from "@capacitor-community/barcode-scanner";
import { NativeAudio } from "@capacitor-community/native-audio";
import { stopCircleOutline, barcodeOutline } from "ionicons/icons";
import { setScan  } from '../../store/inventorySlice';
import { barcodeReducer, resetBarcode } from "../../store/barcodeSlice";
import ApiRequest from "../../shared/ApiRequest";
import { showToast } from "../../store/toastSlice";
import InventoryItem from "./InventoryItem";
import { InventoriesItem } from "../../shared/domain/InventoriesItem";

const InventoryRead: React.FC = () => {

    const dispatch = useAppDispatch();
    const [value, setSearchText] = useState<string>('');
    const [buttonStop, setButtonStop] = useState(false);
    const isMobile = getPlatforms().includes('capacitor') || getPlatforms().includes('cordova');
    const barcode = useAppSelector(barcodeReducer);
    const [code, setCode] = useState<string>('');
    const [item, setItem] = useState<InventoriesItem>();

    NativeAudio.preload({
        assetId: "beep",
        assetPath: "beep.mp3",
        audioChannelNum: 1,
        isUrl: false
    });

    const searchText = (e: any) => {
        if (e.key === 'Enter') {
            setCode(e.target.value);
            setSearchText('');
        } else {
            setSearchText(e.target.value);
        }
    }

    const startScan = async () => {
        await BarcodeScanner.checkPermission({ force: true });
        document.querySelector('body')!.classList.add('scan');
        dispatch(setScan({scan: 'on'}));
        setButtonStop(true);
        scanning();
    };
  
    const scanning = async () => {
        const result = await BarcodeScanner.startScan({ targetedFormats: [ 
            SupportedFormat.UPC_A,
            SupportedFormat.UPC_E,
            SupportedFormat.UPC_EAN_EXTENSION,
            SupportedFormat.EAN_8, 
            SupportedFormat.EAN_13,
            SupportedFormat.CODE_39,
            SupportedFormat.CODE_93,
            SupportedFormat.CODE_128,
            SupportedFormat.CODABAR,
            SupportedFormat.ITF,
            SupportedFormat.CODABAR,
        ]});
        
        if (result.hasContent) {
            setCode(result!.content as string);
            scanning();
        }
    }
  
    const stopScan = () => {
        BarcodeScanner.stopScan();
        document.querySelector('body')!.classList.remove('scan');
        dispatch(setScan({scan: 'off'}));
        setButtonStop(false);
    }
  
    const stopScanButton = () => {
        if (buttonStop) {
            return (
            <IonButton size="small" className="button-barcode" onClick={ stopScan }>
                <IonIcon size="large" className='icon-text' icon={stopCircleOutline}></IonIcon>
            </IonButton>

            );
        } else {
            return (
            <IonButton size="small" className="button-barcode" onClick={ startScan }>
                <IonIcon size="large" className='icon-text' icon={barcodeOutline}></IonIcon>
            </IonButton>
            );
        }
    }

    useEffect(() => {
        if (barcode.value !== '' && barcode.location === 'inventory') {
            setCode(barcode.value);
            dispatch(resetBarcode());
        }
    }, [barcode, dispatch]);

    useEffect(() => {
        if (code !== '') {
            ApiRequest.post('/es/inventories/add', {code: code}).then((response: any) => {
                response.data.manual = true;
                if (response.data.storages.lenght > 0) {
                    setItem(response.data);
                }
                setCode('');
                NativeAudio.play({ assetId: 'beep', time: 0.0 });
            }).catch((error: any) => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });
        }
    }, [code,dispatch]);

    if (isMobile) {
        return (
            <IonGrid className="ion-no-padding">
                <IonRow>
                    <IonCol sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="10">
                        <IonItem>
                            <IonLabel position="floating">EAN/SKU</IonLabel>
                            <IonInput
                                type="text" onKeyDown={(e: any) => searchText(e)}
                                value = { value }
                                id="search"
                            ></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="2">
                        { stopScanButton() }
                    </IonCol>
                </IonRow>
                <IonRow>
                    <InventoryItem item={item} />
                </IonRow>
            </IonGrid>
        )
    }

    return (
        <IonGrid className="ion-no-padding">
            <IonRow>
                <IonCol sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="12">
                    <IonItem>
                        <IonLabel position="floating">EAN/SKU</IonLabel>
                        <IonInput
                            type="text" onKeyDown={(e: any) => searchText(e)}
                            value = { value }
                            id="search"
                        ></IonInput>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <InventoryItem item={item} />
            </IonRow>
        </IonGrid>
    );
}

export default InventoryRead;
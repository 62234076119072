import { IonGrid, IonRow, IonCol, getPlatforms, IonItem, IonLabel, IonInput, IonButton, IonIcon } from "@ionic/react";
import { NativeAudio } from '@capacitor-community/native-audio';
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { barcodeOutline, stopCircleOutline } from 'ionicons/icons';
import { setScan, refresh } from "../../store/storageSlice";
import { showToast } from "../../store/toastSlice";
import React, {useEffect, useState}  from "react";
import { barcodeReducer, resetBarcode } from "../../store/barcodeSlice";

const StorageAdd: React.FC = () => {

    const [search, setSearchText] = useState('');
    const dispatch = useAppDispatch();
    const isMobile = getPlatforms().includes('capacitor') || getPlatforms().includes('cordova');
    const [buttonStop, setButtonStop] = useState(false);
    const barcode = useAppSelector(barcodeReducer);
    const [value, setValue] = useState<string>('');

    NativeAudio.preload({
      assetId: "beep",
      assetPath: "beep.mp3",
      audioChannelNum: 1,
      isUrl: false
    });

    const startScan = async () => {
      await BarcodeScanner.checkPermission({ force: true });
      document.querySelector('body')!.classList.add('scan');
      dispatch(setScan({scan: 'on'}));
      setButtonStop(true);
      scanning();
    };

    const scanning = async () => {
      const result = await BarcodeScanner.startScan({ targetedFormats: [ 
        SupportedFormat.UPC_A,
        SupportedFormat.UPC_E,
        SupportedFormat.UPC_EAN_EXTENSION,
        SupportedFormat.EAN_8, 
        SupportedFormat.EAN_13,
        SupportedFormat.CODE_39,
        SupportedFormat.CODE_93,
        SupportedFormat.CODE_128,
        SupportedFormat.CODABAR,
        SupportedFormat.ITF,
        SupportedFormat.CODABAR,
      ]});
      
      if (result.hasContent) {
        setValue(result.content as string);
        scanning();
      }
    }

    const stopScan = () => {
      BarcodeScanner.stopScan();
      document.querySelector('body')!.classList.remove('scan');
      dispatch(setScan({scan: 'off'}));
      setButtonStop(false);
    }

    const stopScanButton = () => {
      if (buttonStop) {
        return (
          <IonButton size="small" expand="block" className="button-barcode" onClick={ stopScan }>
            <IonIcon size="large" className='icon-text' icon={stopCircleOutline}></IonIcon>
          </IonButton>

        );
      } else {
        return (
          <IonButton size="small" expand="block" className="button-barcode" onClick={ startScan }>
            <IonIcon size="large" className='icon-text' icon={barcodeOutline}></IonIcon>
          </IonButton>
        );
      }
    }

    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        setValue(event.target.value);
        setSearchText('');
      }
    }

    useEffect(() => {
      if (barcode.value !== '' && barcode.location === 'storage') {
          setValue(barcode.value);
          dispatch(resetBarcode());
      }
    }, [barcode, dispatch]);

    useEffect(() => {
      if (value !== '') {
          ApiRequest.post("es/storage_tmps/addItem",{search:value}).then((res:any) => {
            NativeAudio.play({ assetId: 'beep', time: 0.0 });
            dispatch(refresh({refresh: true}))
            dispatch(showToast({type: "success", message: res.data}));
          })
          .catch(error => {
            if (error.status >= 400) {
              dispatch(showToast({type: "danger", message: "Error"}));
            }
          });
          setValue('');
      }
    }, [value,dispatch]);

    if (!isMobile) {
      return (
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="12">
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol size="10">
                    <IonItem class="item-search">
                      <IonLabel position="floating">EAN/SKU</IonLabel>
                      <IonInput
                        type="text" onIonChange={(e: any) => setSearchText(e.target.value)}
                        onKeyDown={handleKeyDown}
                        value={search}
                        id="search"
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="2">
                    { stopScanButton() }
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      )
    }

    return (
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="12">
            <IonItem class="item-search">
              <IonLabel position="floating">EAN/SKU</IonLabel>
              <IonInput
                type="text" onIonChange={(e: any) => setSearchText(e.target.value)}
                onKeyDown={handleKeyDown}
                value={search}
                id="search"
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
}

export default StorageAdd;
import { IonButton, IonCol, IonGrid, IonItem, IonRow, IonToolbar, useIonAlert, useIonLoading } from "@ionic/react";
import React  from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { receivedReducer, setProviderId } from "../../store/receivedSlice";
import { setResetString } from '../../store/barcodeSlice';

const ButtonStockReceived: React.FC = () => {

    const dispatch = useAppDispatch();
    const [presentAlert] = useIonAlert();
    const [presentWarning] = useIonAlert();
    const [present, dismiss] = useIonLoading();
    const received = useAppSelector(receivedReducer);

    const handleStock = () => {
        if (received.providerId !== 0) {
            present({
                message: 'Procesando...',
                spinner: 'circular'
            });

            ApiRequest.post("/es/warehouse_barcodes/addStock",{location: received.location, providerId: received.providerId}).then(response => {
                dismiss();
                dispatch(setProviderId({providerId: 0}));
                dispatch(setResetString(true));
            })
            .catch(error => {
                dismiss();
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });
        } else {
            presentWarning({
                header: 'Alerta',
                subHeader: 'No se ha elegido un proveedor',
                buttons: [
                    {
                        text: 'Cerrar',
                        role: 'cancel',
                    },
                ],
            });
        }
    }

    return (
        <IonToolbar>
            <IonGrid className="ion-no-padding">
                <IonRow class="ion-justify-content-center">
                    <IonCol sizeXl="4" sizeLg="6" sizeSm="6" sizeXs="12">
                        <IonGrid>
                            <IonRow>
                                <IonCol size="9">
                                    <IonButton color="unobike-red"
                                        expand="block"
                                        onClick={() => presentAlert({
                                                header: 'Alerta',
                                                subHeader: '¿Quieres añadir los artículos en '+received.location+'?',
                                                buttons: [
                                                    {
                                                        text: 'Cancelar',
                                                        role: 'cancel',
                                                    },
                                                    {
                                                        text: 'Añadir',
                                                        role: 'confirm',
                                                        handler: () => {
                                                            handleStock();
                                                        },
                                                    },
                                                ],
                                            })
                                        }
                                    >Añadir</IonButton>
                                </IonCol>
                                <IonCol size="3">
                                    <IonItem lines="none">Uds: {received.count}</IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonToolbar>
    )
}

export default ButtonStockReceived;
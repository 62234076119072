import React from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { resetOrder } from "../../store/ordersReadySlice";
import { ordersReadyReducer } from "../../store/ordersReadySlice";
import { IonButton, IonCol, IonGrid, IonRow, IonToolbar, useIonAlert } from "@ionic/react";

const OrderItemList: React.FC = () => {

    const dispatch = useAppDispatch();
    const [presentAlert] = useIonAlert();
    const ordersReady = useAppSelector(ordersReadyReducer);

    const handleSendOrders = () => {
        ApiRequest.post("/es/order_readies/setOrders",{ordersId: ordersReady.orders}).then(response => {
            dispatch(showToast({type: "success", message: response.data}));
            dispatch(resetOrder());
            window.location.href="/picking";
        })
        .catch(error => {
            dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
        });
    }

    return (
        <IonToolbar>
            <IonGrid className="ion-no-padding">
                <IonRow class="ion-justify-content-center">
                    <IonCol sizeXl="3" sizeLg="6" sizeSm="6" sizeXs="12">
                        <IonButton color="unobike-red"expand="block" onClick={() => presentAlert({
                                                header: 'Atención',
                                                subHeader: '¿Quieres preparar estos pedidos?',
                                                buttons: [
                                                    {
                                                        text: 'Cancelar',
                                                        role: 'cancel',
                                                    },
                                                    {
                                                        text: 'Preparar',
                                                        role: 'confirm',
                                                        handler: () => {
                                                            handleSendOrders();
                                                        },
                                                    },
                                                ],
                                            })
                                        }>Preparar</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonToolbar>
    );
}

export default OrderItemList;
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import ApiRequest from "./shared/ApiRequest";

const firebaseConfig = {
  apiKey: "AIzaSyDaP7HOdYAT_rCjy9lQYebuvU7fY_S-nds",
  authDomain: "notificaciones-push-unobike.firebaseapp.com",
  databaseURL: "https://notificaciones-push-unobike.firebaseio.com",
  projectId: "notificaciones-push-unobike",
  storageBucket: "notificaciones-push-unobike.appspot.com",
  messagingSenderId: "369943607707",
  appId: "1:369943607707:web:bd937f56f99cd8da51493e"
};

const vapidKey = "BPxObxj3LIfLn8RHBxAprBjFcrgj8qA2oYuMiIkfmDnLfJ6KdrZQEzzN8IIqB7ts1Itn4Mm3A_nozIJER5LJAPQ";

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
      navigator.serviceWorker
          .register('firebase-messaging-sw.js')
          .then(function (registration) {
              return registration.scope;
          })
          .catch(function (err) {
              return err;
          });
  }
};

export const fetchToken = () => {
  return getToken(messaging, {vapidKey: vapidKey}).then((currentToken) => {
    if (currentToken) {
      ApiRequest.post('/es/user_notification/firebase_token', {token: currentToken})
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () => {
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Update the UI to include the received message.
  });
}
  
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/hook';
import { bodyBarcode, inputBarcode, setResetString, barcodeReducer } from '../store/barcodeSlice';

const BarcodeReader = () => {

    const dispatch = useAppDispatch();
    const barcode = useAppSelector(barcodeReducer);
    const [string, setString] = useState('');

    useEffect(() => {
        document.addEventListener('keydown', (event) => {
            let target = event.target as HTMLInputElement;
            let parent = target.parentElement as HTMLInputElement;
            let location = target.ownerDocument.URL.replace(target.ownerDocument.baseURI, '');

            if (event.key !== 'Shift' && event.key !== 'Enter') {
                setString((string) => string + event.key);
            }

            if (event.key === 'Enter') {
                let localName = target.localName;

                if (target.nodeName === 'ION-BUTTON') {
                    event.preventDefault();
                    localName = 'body';
                }

                setString((string) => {
                    if (localName === 'body' &&  parent.id !== 'search') {
                        dispatch(bodyBarcode({location: location, value: string}));
                    } else if (localName === 'input' &&  parent.id !== 'search') {
                        dispatch(inputBarcode({location: location, input: string}));
                        if (document.activeElement === event.target) {
                            target.blur();
                        }
                    }
                    return '';
                });
            }

        }, false);
    }, [dispatch]);

    useEffect(() => {
        if (barcode.resetString) {
            setString('');
            dispatch(setResetString(false));
        }
    }, [barcode.resetString, dispatch, string]);
}

export default BarcodeReader;
export interface ResponseOrderProviders {
    "@context":         string;
    "@id":              string;
    "@type":            string;
    "hydra:member":     OrderProvider[];
    "hydra:totalItems": number;
    "hydra:view":       HydraView;
    "hydra:search":     HydraSearch;
}

export interface OrderProvider {
    "@id":             string;
    "@type":           HydraMemberType;
    id:                number;
    number:            number;
    provider:          Provider;
    codeNumber:        null | string;
    priceDeliveryNote: string;
    priceInvoice:      null;
    shipCost:          null | string;
    note:              null | string;
    dateCreated:       string;
    dateReceived:      null;
    dateExpected:      Date | null;
    datePurchase:      Date | null;
    pipelineStage:     PipelineStage;
    orderProviderItems: orderProviderItem[]
}

interface orderProviderItem {
    "@id":             string;
    "@type":           HydraMemberType;
    status:            number
}

enum HydraMemberType {
    OrderProvider = "OrderProvider",
}

interface PipelineStage {
    "@type": PipelineStageType;
    id:      number;
    status:  Status;
}

enum PipelineStageType {
    PipelineStage = "PipelineStage",
}

export enum Status {
    Abierto = "Abierto",
    Parcial = "Parcial",
    Pedido = "Pedido",
}

interface Provider {
    "@id":   string;
    "@type": ProviderType;
    id:      number;
    name:    string;
}

enum ProviderType {
    Provider = "Provider",
}

interface HydraSearch {
    "@type":                        string;
    "hydra:template":               string;
    "hydra:variableRepresentation": string;
    "hydra:mapping":                HydraMapping[];
}

interface HydraMapping {
    "@type":  string;
    variable: string;
    property: string;
    required: boolean;
}

interface HydraView {
    "@id":         string;
    "@type":       string;
    "hydra:first": string;
    "hydra:last":  string;
    "hydra:next":  string;
}

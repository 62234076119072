import { IonGrid, IonRow, IonCol, IonButton, IonIcon, IonText } from "@ionic/react";
import React,{ useEffect, useState } from "react";
import { Storage, InventoryVar } from "../../shared/domain/InventoriesItem";
import { addOutline, removeOutline} from 'ionicons/icons';
import { useAppDispatch } from "../../hooks/hook";

type Props = {
    storage: Storage,
    setInventory: (inventory: InventoryVar | ((prev: InventoryVar) => InventoryVar)) => void,
}

const InventoryButtonStorage: React.FC<Props> = ({storage,setInventory}) => {

    const dispatch = useAppDispatch();
    const [uds, setUds] = useState<number>(storage.stock);
    const stock = storage.stock;
    const diff = uds - stock;
    const [colorDiff, setColorDiff] = useState<string>('success');
    const [hidden, setHidden] = useState<boolean>(true);

    useEffect(() => {
        if (diff !== 0) {
            setInventory((inventory) => ({...inventory, storage: diff, storageId: storage.id}));
        } else {
            setInventory((inventory) => ({...inventory, storage: null, storageId: null}));
        }
    }, [diff,stock,storage.id,dispatch,setInventory]);

    useEffect(() => {
        if (diff < 0) {
            setColorDiff('danger');
            setHidden(false);
        } else if (diff > 0) {
            setColorDiff('success');
            setHidden(false);
        } else {
            setHidden(true);
        }
    }, [diff]);

    return (
        <IonGrid class="buttons-received ion-margin-bottom">
            <IonRow>
                <IonCol size="4">
                    {storage.location.code}
                </IonCol>
                <IonCol sizeXl="2" size="3">
                    <IonButton size="small" color="warning" onClick={ () => setUds(uds+1) }>
                        <IonIcon icon={addOutline}/>
                    </IonButton>
                </IonCol>
                <IonCol size="2">
                    <IonButton size="small" fill="clear" color="light" expand="full">
                        <IonGrid className="ion-no-margin">
                            <IonRow>
                                <IonCol size="12">
                                    <IonText hidden={hidden} color={colorDiff} className="inventory-number-font-xs">{ diff }</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonText className='icon-text inventory-number-font-bg'>{ uds }</IonText>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonButton>
                </IonCol>
                <IonCol sizeXl="2" size="3">
                    <IonButton size="small" color="warning" onClick={ () => setUds(uds-1) } >
                        <IonIcon icon={removeOutline}/>
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default InventoryButtonStorage;
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';
import { MeasureItem, Variant } from '../shared/domain/Measures';

interface MeasureInterface {
  x: number,
  y: number,
  z: number,
  type: number,
  variant: Variant | undefined,
  workstation: number
}

const initialState: MeasureInterface = {
    x: 0,
    y: 0,
    z: 0,
    type: 1,
    variant: undefined,
    workstation: 1,
}

export const measuresSlice = createSlice({
  name: 'measureItem',
  initialState,
  reducers: {
    reset: (state) => {
        state.x = 0
        state.y = 0
        state.z = 0
    },
    setMeasures: (state, action: PayloadAction<MeasureItem>) => {
      state.x = action.payload.x
      state.y = action.payload.y
      state.z = action.payload.z
    },
    setProduct: (state, action: PayloadAction<Variant|undefined>) => {
      state.variant = action.payload
    },
    setType: (state, action: PayloadAction<number>) => {
      state.type = action.payload
    },
    setWorkstation: (state, action: PayloadAction<number>) => {
      state.workstation = action.payload
    },
  }
});

export const { reset, setMeasures, setProduct, setType, setWorkstation} = measuresSlice.actions
export const measuresReducer = (state: RootState) => state.measures
export default measuresSlice.reducer
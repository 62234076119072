import React , {useEffect, useState} from "react";

import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { IonAvatar, IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow } from "@ionic/react";
import { Hydramember, ItemReceived } from "../../shared/domain/ItemReceived";
import { countProduct, setRefresh, receivedReducer } from '../../store/receivedSlice';
import ButtonsReceived from "./ReceivedButtons";
import ReceivedBarcode from "./ReceviedBarcode";
import ReceivedButtonDel from "./ReceivedButtonDel";
import AuthenticateJWT from '../../shared/Authenticate';
import ReceivedButtonReturn from "./ReceivedButtonReturn";
import Mercure from '../../shared/Mercure';

const ReceivedList: React.FC = () => {

    const [list, setList] = useState<Hydramember[]>([]);
    const dispatch = useAppDispatch();
    const received = useAppSelector(receivedReducer);
    const userId = AuthenticateJWT.getUser()?.sub;
    const [updateItem, setUpdateItemArray] = useState<Hydramember>();

    useEffect(() => {
        if (received.refresh) {
            ApiRequest.get<ItemReceived>("/es/warehouse_barcodes?order[id]=desc&user="+userId).then(response => {
                setList( response.data["hydra:member"] );
            })
            .catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });

            dispatch(setRefresh(false));
        }
    }, [dispatch, userId, received.refresh]);

    useEffect(() => {
        const eventSource = Mercure.subscribe("warehouseBarcodes", (itemUpdate: Hydramember) => {
            setUpdateItemArray(itemUpdate);
        });

        return () => {
            eventSource.close();
        }
    }, []);

    useEffect(() => {
        const eventSourceDel = Mercure.subscribe("delWarehouseBarcode", () => {
            dispatch(setRefresh(true));
        });

        return () => {
            eventSourceDel.close();
        }
    }, [dispatch]);

    useEffect(() => {
        if (updateItem && updateItem.user === '/es/users/'+userId) {
            let newList = [...list];
            let elementList = newList.findIndex((item) => item.id === updateItem.id);

            if (elementList === -1) {
                newList.unshift(updateItem);
            } else {
                newList[elementList] = updateItem;
            }

            setList(newList);
            setUpdateItemArray(undefined);
        }
    }, [updateItem, userId, list]);

    useEffect(() => {
        let stock = 0;
        list.forEach((item) => {
            stock += item.stock;
        });
        dispatch(countProduct({count: stock}));
    }, [list, dispatch]);

    return (
        <IonList lines="full" className="label-list">
            <IonGrid>
                <IonRow>
            {
                list.map((item) => {
                        if ( item.product ) {
                            var receivedId = "received-"+item.id;
                            return (
                                <IonCol sizeXl="3" sizeLg="4" sizeSm="6" sizeXs="12" className="ion-no-padding" id={receivedId}>
                                    <IonItem className="unobike-item-border">
                                        <IonAvatar slot="start">
                                            <img alt={ item.product.name } src={ "https://www.unobike.com/"+item.product.thumbnailUrl } />
                                        </IonAvatar>
                                        <IonLabel>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <h3>{ item.product.name }</h3>
                                                    </IonCol>
                                                    <IonCol size="2">
                                                        <ReceivedButtonReturn data={ item }/>
                                                    </IonCol>
                                                    <IonCol size="2" >
                                                        <ReceivedButtonDel data={ item }/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="6">
                                                        <p className="variant-text-list">{ item.variant.sku } { item.variant.description }</p>
                                                    </IonCol>
                                                    <IonCol size="6">
                                                        <ButtonsReceived data={ item }/>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonLabel>
                                    </IonItem>
                                </IonCol>
                            )
                        } else {
                            return (
                                <IonCol sizeXl="3" sizeLg="4" sizeSm="6" sizeXs="12" className="ion-no-padding">
                                    <IonItem className="unobike-item-border">
                                        <IonAvatar slot="start"></IonAvatar>
                                        <IonLabel>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="10">
                                                        <ReceivedBarcode data = { item.id }/>
                                                    </IonCol>
                                                    <IonCol size="2">
                                                        <ReceivedButtonDel data={ item }/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="6">
                                                        <h3>{ item.barcode }</h3>
                                                    </IonCol>
                                                    <IonCol size="6">
                                                        <ButtonsReceived data={ item }/>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonLabel>
                                    </IonItem>
                                </IonCol>
                            )
                        }
                    }
                )
            }
                </IonRow>
            </IonGrid>
        </IonList>
    );
}

export default ReceivedList;
import { IonRow, IonCol, IonIcon, IonGrid } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import React from "react";
import { Hydramember, LocationEnd, StorageStart, Location } from "../../shared/domain/ItemStorage";
import StorageLocationEnd from "./StorageLocationEnd";
import StorageLocationStart from "./StorageLocationStart";

type Props = {
    item: Hydramember,
    setStorageStart: (StorageStart: StorageStart|undefined) => void,
    setLocationEnd: (locationEnd: LocationEnd|Location|undefined) => void,
    storageStart: StorageStart|undefined,
    locationEnd: LocationEnd|Location|undefined
}

const StorageButtonsLocation: React.FC<Props> = ({item, setStorageStart, setLocationEnd, storageStart, locationEnd}) => {
    return (
        <IonGrid>
            <IonRow>
                <IonCol size="5" className="ion-no-padding location">
                    <StorageLocationStart data={ item } setStorageStart={ setStorageStart } storageStart={ storageStart }/>
                </IonCol>
                <IonCol size="2" className="ion-no-padding unobike-arrow-location">
                    <IonIcon className='icon-text' icon={ chevronForwardOutline }/>
                </IonCol>
                <IonCol size="5" className="ion-no-padding location">
                    <StorageLocationEnd item={ item } setLocationEnd={ setLocationEnd } locationEnd={ locationEnd }/>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
}

export default StorageButtonsLocation;
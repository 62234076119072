import React, { useRef, useState } from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch } from "../../hooks/hook";
import { Hydramember } from "../../shared/domain/ItemReceived";
import { arrowUndoCircleOutline} from 'ionicons/icons';
import { showToast } from "../../store/toastSlice";
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import './modal.css';

type Props = {
    data: Hydramember,
}

const ReceivedButtonReturn: React.FC <Props> = ({data}) => {

    const dispatch = useAppDispatch();
    const modal = useRef<HTMLIonModalElement>(null);
    const [orderNumber, setOrderNumber] = useState<number|undefined>(data.customerOrder?.number);
    const [note, setNote] = useState<string|undefined>(data.productNote);

    function dismiss() {
        modal.current?.dismiss();
    }

    const setReturn = () => {
        if (orderNumber) {
            ApiRequest.post("/es/warehouse_barcodes/"+data.id+"/addReturn", {
                orderNumber: orderNumber,
                note: note
            })
            .then(response => {
                dispatch(showToast({type: "medium", message: response.data}));
                dismiss();
            })
            .catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.response.data}));
            });
        }
    }

    const removeReturn = () => {
        ApiRequest.post("/es/warehouse_barcodes/"+data.id+"/addReturn",{
            orderNumber: null,
            note: null
        })
        .then(response => {
            setNote(undefined);
            setOrderNumber(undefined);
            dispatch(showToast({type: "medium", message: response.data}));
        })
        .catch(error => {
            dispatch(showToast({type: "danger", message: 'Error: '+error.response.data}));
        });
    }

    const buttonDelReturn = () => {
        if (data.customerOrder?.number) {
            return (
                <IonRow>
                    <IonCol className="ion-text-center">
                        <IonButton color="unobike-red" fill='solid' onClick={removeReturn}>Borrar Devolución</IonButton>
                    </IonCol>
                </IonRow>
            );
        }
    }

    let trigger = 'open-modal-'+data.id;
    return (
        <IonContent>
            <IonButton fill={data.customerOrder?.number ? 'solid' : 'outline'} color="warning" className="ion-float-right ion-no-margin" id={trigger}>
                <IonIcon icon={arrowUndoCircleOutline}/>
            </IonButton>
            <IonModal id="modalReturn" ref={modal} trigger={trigger}>
                <IonContent>
                    <IonToolbar>
                        <IonTitle>Devolución</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => dismiss()}>
                                Cerrar
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel color="unobike-red" position="floating" >Nº Pedido</IonLabel>
                                    <IonInput type="number" value={orderNumber} onIonChange={(event: any) => setOrderNumber(event.target.value)}/>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel color="unobike-red" position="floating" >Nota del Producto</IonLabel>
                                    <IonTextarea rows={5} value={note} placeholder="Descripción de las condiciones del producto" onIonChange={(event: any) => setNote(event.target.value)}/>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <IonButton color="unobike-red" fill='solid' onClick={setReturn}>Introducir</IonButton>
                            </IonCol>
                        </IonRow>
                        {buttonDelReturn()}
                    </IonGrid>
                </IonContent>
            </IonModal>
        </IonContent>
    );
}

export default ReceivedButtonReturn;
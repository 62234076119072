import React , {useEffect, useState} from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { IonCol, IonGrid, IonList, IonRow } from "@ionic/react";
import { Hydramember, ItemStorage } from "../../shared/domain/ItemStorage";
import { storageReducer, refresh, countProduct } from '../../store/storageSlice';
import StorageItem from "./StorageItem";
import AuthenticateJWT from "../../shared/Authenticate";

const ReceivedList: React.FC = () => {
    const [list, setList] = useState<Hydramember[]>([]);
    const dispatch = useAppDispatch();
    const storage = useAppSelector(storageReducer);
    const userId = AuthenticateJWT.getUser()?.sub;

    useEffect(() => {
        if (storage.refresh) {
            ApiRequest.get<ItemStorage>("/es/storage_tmps?order[id]=desc&user="+userId).then(response => {
                setList( response.data["hydra:member"] );
            })
            .catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });
        }
        dispatch(refresh({refresh: false}));
    }, [dispatch,storage.refresh,userId]);

    useEffect(() => {
        let stock = 0;
        list.forEach((item) => {
            stock += item.stock;
        });
        dispatch(countProduct({count: stock}));
    }, [list, dispatch]);

    return (
        <IonList lines="full" className="label-list">
            <IonGrid>
                <IonRow>
                {
                    list.map((item) => {
                        return (
                            <IonCol sizeXl="3" sizeLg="4" sizeSm="6" sizeXs="12" className="ion-no-padding" key={item.id}>
                                <StorageItem item={item} />
                            </IonCol>
                        )
                    })
                }
                </IonRow>
            </IonGrid>
        </IonList>
    );
}

export default ReceivedList;
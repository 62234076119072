import React , {useState, useEffect} from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { inventoryReducer, refresh } from "../../store/inventorySlice";
import { showToast } from "../../store/toastSlice";
import ApiRequest from "../../shared/ApiRequest";
import { InventoriesItem } from "../../shared/domain/InventoriesItem";
import InventoryItem from "./InventoryItem";
import { IonList, IonGrid, IonRow } from "@ionic/react";


const InventoryList: React.FC = () => {

    const [list, setList] = useState<InventoriesItem[]>([]);
    const dispatch = useAppDispatch();
    const inventory = useAppSelector(inventoryReducer);

    useEffect(() => {
        if (inventory.refresh) {
            ApiRequest.get<InventoriesItem[]>("/es/inventories/all").then(response => {
                setList( response.data );
            })
            .catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });
        }
        dispatch(refresh({refresh: false}));
    }, [dispatch,inventory.refresh]);

    return (
        <IonList lines="full" className="label-list">
            <IonGrid key={'listInventory'}>
                <IonRow>
                {
                    list.map((item) => {
                        return (
                            <InventoryItem item={ item }/>
                        )    
                    })
                }
                </IonRow>
            </IonGrid>
        </IonList>
    );
}

export default InventoryList;
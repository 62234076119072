import { IonButton, IonIcon, useIonPicker } from "@ionic/react";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { measuresReducer, setWorkstation } from "../../store/measuresSlice";
import { location } from 'ionicons/icons';

const MeasureWorkstation: React.FC = () => {

    const dispatch = useAppDispatch();
    const [present] = useIonPicker();
    const measures = useAppSelector(measuresReducer);

    const workstationPicker = async () => {
        present({
            columns: [
              {
                name: 'workstation',
                options: [
                  {
                    text: 'Puesto 1',
                    value: 1,
                    selected: measures.workstation === 1 ? true : false,
                  },
                  {
                    text: 'Puesto 2',
                    value: 2,
                    selected: measures.workstation === 2 ? true : false,
                  },
                ],
              },
            ],
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Confirmar',
                handler: (value) => {
                    dispatch(setWorkstation(value.workstation.value));
                },
              },
            ],
        });
    };

    return (
        <IonButton size="small" className="button-barcode" onClick={ workstationPicker }>
            <IonIcon size="large" className='icon-text' icon={location}></IonIcon>
        </IonButton>
    );
}

export default MeasureWorkstation;
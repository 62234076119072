import { IonItem, IonLabel, IonButton, IonInput, IonGrid, IonRow, IonCol, IonSelect, IonSelectOption, IonText, IonIcon } from "@ionic/react"
import { FileItem } from "../interfaces"
import { DocumentType } from "../../ReceptionOrder/interfaces"
import { useState } from "react"
import { cloudUploadOutline } from "ionicons/icons"
import { useAppDispatch } from "../../../hooks/hook";
import { showToast } from "../../../store/toastSlice";

type Props = {
    file: FileItem,
    handleSubmit: (file: FileItem, name: string, type: DocumentType) => void
}

const FileToUpload: React.FC<Props> = ({file, handleSubmit}) => {
  
  const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.None);
  const [name, setName] = useState<string>(file.fileName);
  const dispatch = useAppDispatch();

  const {fileName, size} = file;
  const fileSizeInMB = (size / 1048576).toFixed(2);

  const handleUpload = () => {
    if ( name.length < 3 ) {
      dispatch(showToast({type: "danger", message: 'Error: texto requerido'}));
      return;
    }

    if ( documentType === DocumentType.None ) {
      dispatch(showToast({type: "danger", message: 'Error: tipo de documento requerido'}));
      return;
    }

    handleSubmit(file, name, documentType);
  }

  const handleChange = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;

    setName(value);
  };

  return (
    <IonItem>      

      <IonGrid>
        <IonRow>
          <IonCol>
            <IonText><h3>{fileName} <small>{fileSizeInMB} Mb</small></h3></IonText>            
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Nombre</IonLabel>
              <IonInput placeholder="Enter text" value={name} onIonChange={handleChange}></IonInput>
            </IonItem>
          </IonCol>
          <IonCol>
          
            <IonSelect placeholder="Tipo doc" onIonChange={(e) => setDocumentType(e.detail.value as DocumentType)} >          
              <IonSelectOption value={DocumentType.DeliveryNote}>Albarán</IonSelectOption>
              <IonSelectOption value={DocumentType.Invoice}>Factura</IonSelectOption>
              <IonSelectOption value={DocumentType.Other}>Otros</IonSelectOption>
            </IonSelect>
        
          </IonCol>
          
        </IonRow>
      </IonGrid>
      <IonButton onClick={handleUpload}>
        Subir <IonIcon icon={cloudUploadOutline}></IonIcon>
      </IonButton>
    </IonItem>    
  )
}

export default FileToUpload
import { IonSearchbar } from "@ionic/react";
import { useOrderLoader } from "../hooks";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { resetBarcode, barcodeReducer } from "../../../store/barcodeSlice";
import { useEffect, useState } from "react";
import { showToast } from "../../../store/toastSlice";
import './customSearch.css'

const Search: React.FC = () => { 

  const { setProvider, setTerm, beep } = useOrderLoader();
  const dispatch = useAppDispatch();
  const barcode = useAppSelector(barcodeReducer);  
  const [state, setState] = useState({
    inputValue: '',
    setTerm,
    setProvider
  });

  const handleInput = (ev: Event) => {
    
    const target = ev.target as HTMLIonSearchbarElement;    

    setState({
      ...state,
      inputValue: target.value!    
    })    
  };

  useEffect(() => {

    let delayInputTimeoutId: any;

    if ( state.inputValue.length === 0 ) {
      state.setProvider(0);
      state.setTerm('');
    }

    if ( state.inputValue.length > 0 ) {
      delayInputTimeoutId = setTimeout(() => {
        state.setTerm(state.inputValue);
      }, 500);
    }

    return () => {
      if ( delayInputTimeoutId ) {
        clearTimeout(delayInputTimeoutId);
      }      
    };
  }, [state]);

  // Barcode útil
  useEffect(() => {    
    if ( barcode.value !== '' && barcode.location.includes('reception-order-provider') ) {
      beep.play();
      setTerm(barcode.value);      
      dispatch(resetBarcode());
      dispatch(showToast({type: "success", message: 'Búsqueda añadida'}));
    }
  }, [setTerm, barcode, dispatch, beep]);

  return (
    <IonSearchbar
      id="order-loader-search"      
      onIonChange={handleInput}
      placeholder="Fitlrar por SKU/EAN"      
    >
    </IonSearchbar>
  );
}

export default Search;
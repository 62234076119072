import { IonButton, IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import ApiRequest from "../../../shared/ApiRequest";
import { showToast } from "../../../store/toastSlice";
import { useAppDispatch } from "../../../hooks/hook";
import { useReceptionOrder } from "../hooks/useReceptionOrder";
import { AvailableItem, ResponseResumeOrderProvider } from "../interfaces";

type Props = {
  item: AvailableItem
}

const AddingButton: React.FC<Props> = ({item}: Props) => {

  const { orderId, setResumeOrderProvider, beep } = useReceptionOrder();
  const dispatch = useAppDispatch();  

  const handleAddStock = () => {

    ApiRequest.get<ResponseResumeOrderProvider>(`/es/reception-order-provider/create-one-by-ref/${orderId}?term=${item.sku}`)
      .then(response => {      
        setResumeOrderProvider(response.data);
        dispatch(showToast({type: "success", message: 'Referencia añadida'}));
        beep.play();
    })
    .catch(error => {
        dispatch(showToast({type: "danger", message: `Error: ${error.response.data}`}));
    });
  };  

  return (    
    <IonButton size="small" color="medium" onClick={ () => handleAddStock() }>
      <IonIcon className='icon-text' icon={chevronForwardOutline}/>
    </IonButton>           
  );
}

export default AddingButton;
import { IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonItem, IonLabel, IonList, IonModal, IonSearchbar, IonTitle, IonToolbar } from "@ionic/react";
import { useOrderLoader } from "../hooks";
import { Provider } from "../interfaces";
import { useEffect, useRef, useState } from "react";

const getProviderName = (provider: number, providers: Provider[]): string => {
  return providers.filter(item => item.id===provider)[0].name;
}

const ProviderFindSelector = () => {

  const {provider, providers, setProvider, fetchProviders} = useOrderLoader();
  const modalProvider = useRef<HTMLIonModalElement>(null);
  const [filteredProviders, setFilteredProviders] = useState<Provider[]>([...providers]);
  const [searchQuery, setSearchQuery] = useState<string | null>(null); 
  const [firstLoad, setFirstLoad] = useState(true);

  const searchbarInput = (ev: any) => {
    setSearchQuery(ev.target.value);
  };    

  useEffect(() => {    
    
    if ( firstLoad ) {
      fetchProviders();
      setFirstLoad(false);
    }    

  }, [fetchProviders, firstLoad]);

  useEffect(() => {
    
    const filterList = (searchQuery: string | null | undefined) => {
      if (searchQuery === undefined || searchQuery === null) {
        setFilteredProviders([...providers]);
      } else {
        const normalizedQuery = searchQuery.toLowerCase();
        setFilteredProviders(
          providers.filter((item) => {
            return item.name.toLowerCase().includes(normalizedQuery);
          })
        );
      }
    };

    if ( providers.length > 0 ) {      
      filterList(searchQuery);
    }
    
  }, [searchQuery, providers]);

  const handleClick = (event: any) => {
    setProvider(event.target.value);
    setSearchQuery(null);
    modalProvider.current?.dismiss();
  }

  return (
    <>
      <IonButton id="selectProviderOrderLoader" size="default" style={{marginTop: '12px'}}>
        <IonLabel>
          {
            provider === 0
            ? 'Seleccionar proveedor'
            : `Proveedor: ${getProviderName(provider, providers)}`
          }
        </IonLabel>        
      </IonButton>
      <IonModal trigger="selectProviderOrderLoader" ref={modalProvider}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Proveedores</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => modalProvider.current?.dismiss()}>Cerrar</IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar placeholder="Buscar..." onIonInput={searchbarInput}></IonSearchbar>
          </IonToolbar>
        </IonHeader>
        <IonContent class="ion-padding-none">
          <IonList>
          {filteredProviders.map(({id,name}) => (
            <IonItem key={id} lines='full'>
              <IonCheckbox value={id} slot='start' onClick={handleClick} />{name}
            </IonItem>
          ))}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  )
}

export default ProviderFindSelector
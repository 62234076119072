import { useContext } from "react"
import { ReceptionOrderContext } from "../context/ReceptionOrderContext"

export const useReceptionOrder = () => {

    const {orderId, userId, receptionOrderState, addDocuments, isStockAllowed, 
        isStockAllowedBySku,
        setResumeOrderProvider,        
        fetchAllDocuments,
        fetchAvailableAndReceptionItems,
        toggleScanView,
        setNote,
        addReceptionItem,
        setLocation,
        setDocumentId,
        updateEanFromReceptionItem,
        beep
    } = useContext( ReceptionOrderContext );
    const {availableItems, availableSkus, availableItemsCount, availableIdVariants, receptionItems, receptionItemsCount, documents, location,
        scanActive,
        order,
        documentId
    } = receptionOrderState;

    return {
        orderId,
        userId,        
        addDocuments,
        addReceptionItem,
        availableItems,
        availableSkus,
        receptionItems,
        documentId,
        documents,
        availableItemsCount,
        availableIdVariants,
        receptionItemsCount,
        isStockAllowed,
        isStockAllowedBySku,
        setResumeOrderProvider,        
        toggleScanView,
        location,
        scanActive,
        fetchAllDocuments,
        fetchAvailableAndReceptionItems,
        note: order.note,
        setNote,
        setLocation,
        setDocumentId,
        updateEanFromReceptionItem,
        beep
    };
}
import { IonInput, IonItem } from "@ionic/react";
// import { getPlatforms } from "@ionic/react";
// import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { NativeAudio } from '@capacitor-community/native-audio';
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
// import { refresh, setScan } from "../../store/storageSlice";
import React , {useState, useEffect} from "react";
import { Hydramember, LocationEnd, Location } from "../../shared/domain/ItemStorage";
import { barcodeReducer, resetBarcode } from "../../store/barcodeSlice";

type Props = {
    item: Hydramember,
    setLocationEnd: (locationEnd: LocationEnd|Location|undefined) => void,
    locationEnd: LocationEnd|Location|undefined
}

const StorageLocationEnd: React.FC <Props> = ({item, setLocationEnd, locationEnd}) => {

    NativeAudio.preload({
        assetId: "beep",
        assetPath: "beep.mp3",
        audioChannelNum: 1,
        isUrl: false
    });

    const dispatch = useAppDispatch();
    const barcode = useAppSelector(barcodeReducer);
    const [isFocus, setFocus] = useState<boolean>(false);
    const [location, addLocationEnd] = useState<string>('');
    // const isMobile = getPlatforms().includes('capacitor') || getPlatforms().includes('cordova');

    // const scan = async () => {
    //     await BarcodeScanner.checkPermission({ force: true });
    //     BarcodeScanner.hideBackground();
    //     document.querySelector('body')!.classList.add('scan');
    //     dispatch(setScan({scan: 'on'}));

    //     const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.CODE_128]});
    //     if (result.hasContent) {
    //         addLocationEnd(result.content as string);
    //     }

    //     BarcodeScanner.stopScan();
    //     BarcodeScanner.showBackground();
    //     document.querySelector('body')!.classList.remove('scan');
    //     dispatch(setScan({scan: 'off'}));
    //     dispatch(refresh({refresh: true}));
    // };

    const buttonScan = async () => {
        setFocus(true);
        // if (isMobile) {
        //     scan();
        // } else {
        //     setFocus(true);
        // }
    }

    useEffect(() => {
        if (isFocus) {
            if (barcode.input !== '' && barcode.location === 'storage') {
                addLocationEnd(barcode.input);
                dispatch(resetBarcode());
                setFocus(false);
            }
        }
    }, [barcode.input, barcode.location, isFocus,dispatch]);

    useEffect(() => {
        if (location !== '') {
            ApiRequest.get("/es/locations?code="+location).then(response => {
                let location = response.data['hydra:member'][0] as LocationEnd;
                ApiRequest.patch("/es/storage_tmps/"+item.id,{locationEnd: location.id}).then(response => {
                    let data = response.data as Hydramember;
                    setLocationEnd(data.locationEnd);
                    dispatch(showToast({type: "success", message: 'Localización Final Añadida'}));
                })
                NativeAudio.play({ assetId: 'beep', time: 0.0 });
            })
            addLocationEnd('');
        }
    }, [location,dispatch,item,setLocationEnd]);

    if (!locationEnd) {
        return (  
            <IonItem lines="none">
                <IonInput readonly={true} value="FIN" className="input-focus" color="medium" onClick={ buttonScan }></IonInput>
            </IonItem>
        );
    } else {
        return (
            <IonItem lines="none">
                <IonInput readonly={true} value={ locationEnd.code } className="input-focus" color="success" onClick={ buttonScan }></IonInput>
            </IonItem>
        );
    }
}

export default StorageLocationEnd;
import { IonChip, IonIcon, IonInput, IonItem, IonLabel, useIonAlert } from "@ionic/react";
// import { getPlatforms } from "@ionic/react";
import React , {useEffect, useState} from "react";
import ApiRequest from "../../shared/ApiRequest";
import { showToast } from "../../store/toastSlice";
// import { setScan } from "../../store/pickingSlice";
import { Hydramember, LocationOrder } from "../../shared/domain/OrderStorageReady";
import { close } from 'ionicons/icons';
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
// import { BarcodeScanner, SupportedFormat } from "@capacitor-community/barcode-scanner";
import { NativeAudio } from "@capacitor-community/native-audio";
import { resetBarcode,barcodeReducer } from "../../store/barcodeSlice";

type Props = {
    item: Hydramember,
}

const PickingOrderLocation: React.FC <Props> = ({item}) => {

    NativeAudio.preload({
        assetId: "beep",
        assetPath: "beep.mp3",
        audioChannelNum: 1,
        isUrl: false
    });

    const dispatch = useAppDispatch();
    const [buttonChip, setButtonChip] = useState(false);
    const [presentAlert] = useIonAlert();
    // const isMobile = getPlatforms().includes('capacitor') || getPlatforms().includes('cordova');
    const barcode = useAppSelector(barcodeReducer);
    const [isFocus, setFocus] = useState<boolean>(false);
    const [location, addLocation] = useState<string>('');

    useEffect(() => {
        if (item.orderItemReady.orderReady.location) {
            setButtonChip(true);
        } else {
            setButtonChip(false);
        }
    }, [item]);

    const deleteLocation = () => {
        ApiRequest.patch("/es/order_readies/"+item.orderItemReady.orderReady.id,{location: null})
        .catch(error => {
            dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
        });
        item.orderItemReady.orderReady.location = undefined;
        setButtonChip(false);
    }

    // const scan = async () => {
    //     await BarcodeScanner.checkPermission({ force: true });

    //     document.querySelector('body')!.classList.add('scan');
    //     dispatch(setScan({scan: 'on'}));
  
    //     const result = await BarcodeScanner.startScan({ targetedFormats: [ 
    //         SupportedFormat.CODE_128
    //     ]});

    //     if (result.hasContent) {
    //         addLocation(result.content as string);
    //         NativeAudio.play({ assetId: 'beep', time: 0.0 });
    //         BarcodeScanner.stopScan();
    //         document.querySelector('body')!.classList.remove('scan');
    //         dispatch(setScan({scan: 'off'}));
    //     }
    // };

    const buttonScan = async () => {
        setFocus(true);
        // if (isMobile) {
        //     scan();
        // } else {
        //     setFocus(true);
        // }
    }

    useEffect(() => {
        if (isFocus) {
            if (barcode.input !== '' && barcode.location === 'picking') {
                addLocation(barcode.input);
                dispatch(resetBarcode());
                setFocus(false);
            }
        }
    }, [barcode, isFocus, dispatch]);

    useEffect(() => {
        if (location !== '') {
            ApiRequest.get("/es/locations?code="+location)
            .then(response => {
                let location = response.data['hydra:member'][0] as LocationOrder;
                ApiRequest.patch("/es/order_readies/"+item.orderItemReady.orderReady.id,{location: location.id})
                .then(response => {
                    NativeAudio.play({ assetId: 'beep', time: 0.0 });
                })
                .catch(error => {
                    dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
                });
            })
            .catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });
            addLocation('');
        }
    }, [location, dispatch, item]);

    let label = item.orderItemReady.orderReady.location?.description !== '' ? item.orderItemReady.orderReady.location?.description : item.orderItemReady.orderReady.location?.code;

    if (buttonChip) {
        return (
            <IonChip color="success" 
                     onClick={() => presentAlert({
                                            header: 'Alerta',
                                            subHeader: '¿Quieres borrar ubicación de este pedido?',
                                            buttons: [
                                                {
                                                    text: 'Cancelar',
                                                    role: 'cancel',
                                                },
                                                {
                                                    text: 'Borrar',
                                                    role: 'confirm',
                                                    handler: () => {
                                                        deleteLocation();
                                                    },
                                                },
                                            ],
                                        })
                                    }>
                <IonIcon size="small" icon={close}/>
                <IonLabel>{label}</IonLabel>
            </IonChip>
        )
    } else {
        return (
            <IonItem lines="none">
                <IonInput readonly={true} value="Sin ubicación" className="chip-unobike" onClick={ () => buttonScan()}></IonInput>
            </IonItem>
        )
    }
}

export default PickingOrderLocation;
import { IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonLoading, IonFooter, IonButton, IonIcon } from "@ionic/react";
import ButtonUser from "../../../components/ButtonUser";
import ButtonStockReceived from "./ButtonStockReceived";
import DocumentScan from "./DocumentScan/DocumentScan";
import ItemList from "./ItemList";
import { useReceptionOrder, useListAndScanScreens } from "../hooks";
import { arrowBackOutline } from "ionicons/icons";

const ListAndScanScreens: React.FC = () => {

  const { scanActive } = useReceptionOrder();
  const { id, order, loading } = useListAndScanScreens();

  return (<>
  {!scanActive && <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => window.location.href = '/reception-order-provider'} >
              <IonIcon icon={arrowBackOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>Recepcionar pedido {order.number}</IonTitle>
          
          <ButtonUser />
        </IonToolbar>
      </IonHeader>    
      <IonContent className="unobike-background">        
      
        <IonLoading isOpen={loading} message={'Loading...'} />          

        <ItemList />
      </IonContent>

      <IonFooter>
        <ButtonStockReceived />
      </IonFooter>            
    </>}

    {scanActive && <DocumentScan orderId={Number(id)} orderNumber={order.number} />}
  </>);
}

export default ListAndScanScreens;
import React, { useEffect, useState } from "react";
import Mercure from "../../shared/Mercure";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { addCircleOutline, trashOutline } from 'ionicons/icons';
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonList, IonRow, IonText } from "@ionic/react";
import { packingReducer, setPackaging } from "../../store/packingSlice";
import { MeasureItem } from "../../shared/domain/Measures";


const PackingMeasure:React.FC = () => {

    const packing = useAppSelector(packingReducer);
    const dispatch = useAppDispatch();
    const [measure, setMeasure] = useState<MeasureItem>();

    useEffect(() => {    
        const eventSource = Mercure.subscribe("measures"+packing.workstation, (measures: MeasureItem) => {
            setMeasure(measures)
        });

        return () => {
            eventSource.close();
        }
    }, [packing.workstation]);

    useEffect(() => {
        setMeasure(undefined);
        dispatch(setPackaging('[]'));
    }, [packing.orderId, dispatch]);

    useEffect(() => {
        let allMeasures = JSON.parse(packing.packaging);
        if (measure && allMeasures.length > 0) {
            allMeasures[allMeasures.length - 1].x = measure.x
            allMeasures[allMeasures.length - 1].y = measure.y
            allMeasures[allMeasures.length - 1].z = measure.z
            dispatch(setPackaging(JSON.stringify(allMeasures)));
        }
    },[dispatch, measure, packing.packaging]);

    const addPackage = () => {
        let measures = JSON.parse(packing.packaging);
        measures.push({
            x: 0,
            y: 0,
            z: 0,
        });
        dispatch(setPackaging(JSON.stringify(measures)));
        setMeasure(undefined);
    }

    const delPackage = (index: number) => {
        let measures = JSON.parse(packing.packaging);
        measures.splice(index, 1);
        dispatch(setPackaging(JSON.stringify(measures)));
        setMeasure(undefined);
    }

    return (
        <>
            <IonButton color="danger" shape="round" className="ion-no-margin" onClick={() => addPackage()}>
                <IonIcon icon={addCircleOutline}/>
                <IonText>Añadir Bulto</IonText>
            </IonButton>
            <IonList lines="none">
                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-no-padding">
                    { 
                        JSON.parse(packing.packaging).map((measure: MeasureItem, index: number) => {
                            return (
                                <IonCol size="12">
                                    <IonItem className="unobike-item-border">
                                        <p> Bulto {index + 1}<br/>
                                            {measure.x} cm. | {measure.y} cm. | {measure.z} cm.
                                        </p>
                                        <IonButton color="danger" shape="round" slot="end" onClick={() => delPackage(index)}>
                                            <IonIcon icon={trashOutline}/>
                                        </IonButton>
                                    </IonItem>
                                </IonCol>
                            )
                        })
                    }
                    </IonRow>
                </IonGrid>
            </IonList>
        </>
    );
}

export default PackingMeasure;
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonModal, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useReceptionOrder } from "../hooks";
import ApiRequest from "../../../shared/ApiRequest";
import { pencilOutline } from "ionicons/icons";

const ModalNote: React.FC = () => {

  const { orderId, note, setNote } = useReceptionOrder();
  const modal = useRef<HTMLIonModalElement>(null);
  const textArea = useRef<HTMLIonTextareaElement>(null);
  const [textValue, setTextValue] = useState(note);
  
  const confirm = async() => {
    const newNote = textArea.current?.value as string;
    setNote(newNote)    
    await ApiRequest.patch(`/es/order_providers/${orderId}`, {note: newNote});
    modal.current?.dismiss()
  } 
  
  const handleTextChange = (event: CustomEvent) => {
    setTextValue(event.detail.value); // Actualiza el estado con el nuevo valor
  };

  useEffect(() => {
    setTextValue(note);
  },[note])
  
  return (
    <>
      <IonButton id="open-modal" color="unobike-red">
        <IonIcon icon={pencilOutline} ></IonIcon>
        Nota
      </IonButton>
      <IonModal ref={modal} trigger="open-modal">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>Cerrar</IonButton>
            </IonButtons>
            <IonTitle>Nota del pedido</IonTitle>
            <IonButtons slot="end">
              <IonButton strong={true} onClick={() => confirm()} >                
                Guardar
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>                  
            <IonTextarea value={textValue} ref={textArea} placeholder="Nota sobre el pedido..." onIonChange={handleTextChange}></IonTextarea>
          </IonItem>
        </IonContent>
      </IonModal>
    </>
  );
}

export default ModalNote;
import { IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItemDivider, IonLoading, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import Authored from '../../components/AuthComponent'
import { FileItem, ResponseFileList, ResponseIsOnline } from './interfaces';
import ApiRequest from '../../shared/ApiRequest';
import ButtonUser from '../../components/ButtonUser';
import { reloadOutline } from 'ionicons/icons';
import { FileToUpload } from './components';
import { DocumentType } from '../ReceptionOrder/interfaces';


const RemoteDocument: React.FC = () => {  

  const [loading, setLoading] = useState(false);
  const [isOnLine, setIsOnLine] = useState(false);
  const [fileList, setFileList] = useState<FileItem[]>([]);

  useEffect(() => {

    ApiRequest.get<ResponseIsOnline>(`/es/remote-document/online`)
      .then(response => {
        if ( response.data.ok) {
          setIsOnLine(true);
        }
      })
      .catch(error => {
        setIsOnLine(false);
      });
  }, []);
  
  useEffect(() => {

    if ( isOnLine ) {
      ApiRequest.get<ResponseFileList>(`/es/remote-document/file-list`)
      .then(response => {
        setFileList(response.data.data.fileSizes);        
      })
      .catch(error => {
        // setIsOnLine(false);
      });
    }
  }, [isOnLine, setFileList]);

  const handleSubmit = (file: FileItem, name: string, type: DocumentType) => {

    setLoading(true);

    ApiRequest.post('/es/remote-document/file/upload',{file: file.fileName, name, type}).then(response => {
      ApiRequest.get<ResponseFileList>(`/es/remote-document/file-list`)
      .then(response => {
        setFileList(response.data.data.fileSizes);   
        setLoading(false);     
      })
      .catch(error => {
        setLoading(false);
      });
    })
    .catch(error => {
      setLoading(false);
    });   
  }

  const update = () => {

    setLoading(true);

    ApiRequest.get<ResponseFileList>(`/es/remote-document/file-list`)
      .then(response => {
        setFileList(response.data.data.fileSizes);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }

  return (
  <Authored>
    
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Documentos Scanner</IonTitle>
          <ButtonUser />
        </IonToolbar>
      </IonHeader>    
      <IonContent className="unobike-background">

        <IonLoading isOpen={loading} message={'Loading...'} />

        <IonItemDivider sticky={true} className="shadow-box" style={{paddingLeft: '5px', paddingTop: '10px', paddingBottom: '10px'}}>
          <IonButton onClick={() => update()} style={{marginRight: '10px'}}>
            Actualizar <IonIcon icon={reloadOutline}></IonIcon>
          </IonButton>
          { isOnLine
            ? <IonBadge color="success">en línea</IonBadge>
            : <IonBadge color="warning">esperando</IonBadge>
          }
                    
        </IonItemDivider>

        {fileList.map((file, index) => (
          <FileToUpload file={file} key={index} handleSubmit={handleSubmit}/>
        ))}        
        
      </IonContent>     
          
    </IonPage>    
  </Authored>    
  )
}

export default RemoteDocument

import { IonItem, IonAvatar, IonLabel, IonGrid, IonRow, IonCol } from "@ionic/react";
import React , { useEffect, useState} from "react";
import StorageButtonDel from "./StorageButtonDel";
import StorageButtonsLocation from "./StorageButtonsLocation";
import StorageButtonStock from "./StorageButtonStock";
import { Hydramember, LocationEnd, Location, StorageStart } from "../../shared/domain/ItemStorage";

type Props = {
    item: Hydramember
}

const StorageItem: React.FC<Props> = ({item}) => {

    const [storageStart, setStorageStart] = useState<StorageStart|undefined>(item.storageStart);
    const [locationEnd, setLocationEnd] = useState<LocationEnd|Location|undefined>(item.locationEnd);
    const [borderCard, setBorderCard] = useState('');

    useEffect(() => {
        if (storageStart && locationEnd) {
            setBorderCard('unobike-item-success');
        } else {
            setBorderCard('unobike-item-border');
        }
    }, [storageStart, locationEnd]);

    return (
        <IonItem className={ borderCard }>
            <IonAvatar slot="start">
                <img alt={ item.product.name } src={ "https://www.unobike.com/"+item.product.thumbnailUrl } />
            </IonAvatar>
            <IonLabel>
                <IonGrid>
                    <IonRow>
                        <IonCol size="10" className="ion-no-padding">
                            <IonRow>
                                <IonCol size="12" className="ion-no-padding">
                                    <h3>{ item.product.name }</h3>
                                    <p className="variant-text-list">{ item.variant.sku } { item.variant.description }</p>
                                </IonCol>
                            </IonRow>
                            <StorageButtonsLocation
                                item={ item }
                                setStorageStart={ setStorageStart }
                                setLocationEnd = { setLocationEnd }
                                storageStart={ storageStart }
                                locationEnd={ locationEnd }
                            />
                        </IonCol>
                        <IonCol size="2" className="ion-no-padding">
                            <IonRow>
                                <IonCol size="12">
                                    <StorageButtonDel data={ item }/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <StorageButtonStock data={ item } storageStart={ storageStart }/>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonLabel>
        </IonItem>
    )
}
export default StorageItem;
import { useContext } from "react";
import { OrderLoaderContext } from "../context/OrderLoaderContext";

export const useOrderLoader = () => {
    
  const { addOrders, addProviders, fetchOrdersBySearch, setProvider, fetchProviders, fetchOrders, formatDateTime, setTerm, orderLoaderState,
    beep
  } = useContext( OrderLoaderContext );  
  const { page, provider, providers, orders, term, loadingOrders, ordersBySearch } = orderLoaderState;
  return {
    addOrders,
    addProviders,
    fetchOrdersBySearch,
    fetchProviders,
    fetchOrders,
    setProvider,
    setTerm,
    formatDateTime,
    page,
    provider,
    providers,
    orders,
    term,
    loadingOrders,
    ordersBySearch,
    beep
  };
}
import React from "react";
import { useAppDispatch } from "../../hooks/hook";
import { trashOutline} from 'ionicons/icons';
import { IonButton, IonIcon, useIonAlert } from "@ionic/react";
import { setProduct } from "../../store/measuresSlice";

const MeasureDel:React.FC = () => {
    const dispatch = useAppDispatch();
    const [ presentAlert ] = useIonAlert();

    return (
        <IonButton color="danger" className="ion-float-right ion-no-margin"
            onClick={() =>
                presentAlert({
                    header: 'Alerta',
                    subHeader: '¿Quieres borrar el artículo?',
                    buttons: [
                        {
                            text: 'Cancelar',
                            role: 'cancel',
                        },
                        {
                            text: 'Borrar',
                            role: 'confirm',
                            handler: () => {
                                dispatch(setProduct(undefined))
                            },
                        },
                    ],
                    
                })
            }
        >
            <IonIcon size="small" className='icon-text' icon={trashOutline}/>
        </IonButton>
    );
}

export default MeasureDel;
import React , {useEffect, useState, useRef} from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { ordersReadyReducer, setQuantityOrders, setQuantityItems } from "../../store/ordersReadySlice";
import { IonCol, IonGrid, IonItem, IonItemDivider, IonLabel, IonList, IonRow, getPlatforms, IonButton, IonContent, IonHeader, IonModal, IonTitle, IonToolbar, IonIcon } from "@ionic/react";
import { Hydramember,OrderItem } from "../../shared/domain/OrderItem";
import { closeCircleOutline } from 'ionicons/icons';

const OrderItemList: React.FC = () => {

    const dispatch = useAppDispatch();
    const [list, setList] = useState<Hydramember[]>([]);
    const ordersReady = useAppSelector(ordersReadyReducer);
    const isTablet = getPlatforms().includes('tablet');
    const isDesktop = getPlatforms().includes('desktop');

    const modal = useRef<HTMLIonModalElement>(null);
    
    function dismiss() {
        modal.current?.dismiss();
    }

    useEffect(() => {
        let arrayOrders = JSON.parse(ordersReady.orders);
        if (arrayOrders.length > 0) {
            dispatch(setQuantityOrders({quantity: arrayOrders.length}));
            let params = arrayOrders.map(function(el: number) {
                return 'order[]=' + el;
            }).join('&');

            ApiRequest.get<OrderItem>("/es/order_items?"+params).then(response => {
                setList( response.data['hydra:member'] );
                dispatch(setQuantityItems({quantity: response.data['hydra:totalItems']}));
            })
            .catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });
            return;
        }

        setList([]);
        dispatch(setQuantityOrders({quantity: 0}));
        dispatch(setQuantityItems({quantity: 0}));
    }, [ordersReady.orders, dispatch]);

    if (isTablet || isDesktop) {
        return (
            <IonCol sizeXl="10" sizeMd="8" size="12" className="boxListItems unobike-height-screen">
                <IonList className="ion-no-padding">
                    <IonItemDivider sticky={true} className="shadow-box">
                        <IonLabel>
                            <h5><strong>Pedido:</strong> { ordersReady.quantityOrders } | <strong>Artículos:</strong> { ordersReady.quantityItems }</h5>
                        </IonLabel>
                    </IonItemDivider>
                { list.map((item) => {
                    return (
                        <IonItem key={ item.id } lines="full">
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="12">
                                        { item.productName }
                                    </IonCol>
                                    <IonCol size="12" className="variant-text-list">
                                        SKU: {item.sku} | Cantidad: {item.uds}
                                    </IonCol>
                                    <IonCol size="12" className="variant-text-list">
                                        Nota: {item.productNote}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonItem>
                    );
                })}
                </IonList>
            </IonCol>
        );
    } else {
        return (
            <IonModal ref={modal} trigger="modalListItem">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle><h5><strong>Pedido:</strong> { ordersReady.quantityOrders } | <strong>Artículos:</strong> { ordersReady.quantityItems }</h5></IonTitle>
                        <IonButton size="small" color="danger" fill="clear" slot="end"onClick={() => dismiss()}>
                            <IonIcon icon={ closeCircleOutline }></IonIcon>
                        </IonButton>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                { list.map((item) => {
                    return (
                        <IonItem key={ item.id } lines="full">
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="12">
                                        { item.productName }
                                    </IonCol>
                                    <IonCol size="12" className="variant-text-list">
                                        SKU: {item.sku}
                                    </IonCol>
                                    <IonCol size="12" className="variant-text-list">
                                        Nota: {item.productNote}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonItem>
                    );
                })}
                </IonContent>
            </IonModal>
        );
    }
}

export default OrderItemList;
import React , {useEffect, useState} from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { packingReducer, setOrderId, refresh, setUpdateShipment } from "../../store/packingSlice";
import { showToast } from "../../store/toastSlice";
import { IonCard, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonRow } from "@ionic/react";
import { OrderReady, OrderReadyItem } from "../../shared/domain/OrderReady";
import { Shipment, Packaging } from "../../shared/domain/Shipment";
import { Packing, Hydramember } from "../../shared/domain/Packing";
import { ShipmentLog } from "../../shared/domain/ShipmentLog";
import { Order } from "../../shared/domain/Order";
import PackingItem from "./PackingItem";
import PackingMeasure from "./PackingMeasure";
import SelectShipment from "./SelectShipment";

const PackingOrder: React.FC = () => {

    const dispatch = useAppDispatch();
    const packing = useAppSelector(packingReducer);
    const [order, setOrder] = useState<Order|undefined>();
    const [shipment, setShipment] = useState<Shipment|undefined>()
    const [items, setItems] = useState<OrderReadyItem[]>([]);
    const [agency, setAgency] = useState<string>("");
    const [packingBoxs , setPackingBoxs] = useState<Packaging[]>([]);
    const [boxList, setBoxList] = useState<Hydramember[]>([]);
    const [shipmentLogList, setShipmentLogList] = useState<ShipmentLog[]>([]);
    const [updateShipmentLog, setUpdateShipmentLog] = useState<boolean>(false);

    useEffect(() => {
        if (packing.locationCode) {
            ApiRequest.get<OrderReady>("/es/order_readies?status=2&location.code="+packing.locationCode).then(response1 => {
                ApiRequest.get("/es/orders/"+response1.data['hydra:member'][0]['order']['id']).then(response2 => {
                    setOrder(response2.data as Order);
                    setItems(response1.data['hydra:member'][0]['orderReadyItems']);
                    dispatch(setUpdateShipment({updateShipment: true}))
                })
                .catch(error => {
                    dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
                });
            })
            .catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });
        } else {
            setOrder(undefined);
            setItems([]);
        }
        dispatch(refresh({refresh: false}));
    }, [dispatch, packing.locationCode, packing.refresh]);

    useEffect(() => {
        if (packing.updateShipment && order?.id) {
            ApiRequest.get("/es/shipments?order="+order?.id+"&orderType=1&ord[id]=desc").then(response3 => {
                if (response3.data['hydra:member'][0]) {
                    setShipment(response3.data['hydra:member'][0] as Shipment);

                    if (response3.data['hydra:member'][0]['status'] === 19) {
                        setUpdateShipmentLog(true);
                    }

                    ApiRequest.get("/es/shipment_agencies/"+response3.data['hydra:member'][0]['agencyId']).then(response4 => {
                        setAgency(response4.data['agency']);
                    });

                    if (response3.data['hydra:member'][0]['packaging']['packaging']) {
                        ApiRequest.get<Packing>("/es/packings?discr=2").then(response5 => {
                            setBoxList(response5.data['hydra:member']);
                        });

                        setPackingBoxs(response3.data['hydra:member'][0]['packaging']['packaging'] as Packaging[]);
                    }
                } else {
                    setShipment(undefined);
                    setAgency("");
                    setPackingBoxs([]);
                    setBoxList([]);
                    setShipmentLogList([]);
                }
            }).catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });
            
            dispatch(setUpdateShipment({updateShipment: false}))
        }
    }, [packing.updateShipment, dispatch, order?.id]);

    useEffect(() => {
        if (updateShipmentLog && shipment) {
            ApiRequest.get("/es/shipment_logs?shipment="+shipment.id).then(responseLog => {
                setShipmentLogList(responseLog.data['hydra:member']);
            })
        }
        setUpdateShipmentLog(false);
    }, [updateShipmentLog, shipment]);

    useEffect(() => {
        let itemsReady = 0;
        if (items.length > 0 && order?.id) {
            items.forEach(element => {
                if (element.status === 3) {
                    itemsReady++;
                }
            });

            if (itemsReady === items.length) {
                dispatch(setOrderId({orderId: order.id}));
            }
        }
    }, [items, dispatch, order?.id]);

    const dateCreated = new Date(String(order?.dateCreated));

    const shipmentData = () => {
        if (shipment && shipment.status === 15) {
            return (
                <>
                    <IonRow>
                        <IonCol sizeXl="4" sizeLg="4" size="6">
                            <IonItem lines="none">
                                <IonLabel position="stacked">Seguimiento</IonLabel>
                                <IonInput type="text" readonly={true} placeholder={ String(shipment?.shipment) }></IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXl="4" sizeLg="4" size="6">
                            <IonItem lines="none">
                                <IonLabel position="stacked">Agencia</IonLabel>
                                <IonInput type="text" readonly={true} placeholder={ agency }></IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXl="4" sizeLg="4" size="6">
                            <IonItem lines="none">
                                <IonLabel position="stacked">Bultos</IonLabel>
                                <IonInput type="text" readonly={true} placeholder={ String(shipment?.parcel) }></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        {packagingList()}
                    </IonRow>
                </>
            );
        } else if (shipment) {
            return (
                <>
                    <h5>ERRORES</h5>
                    <IonRow>
                        {
                            shipmentLogList.map((log) => {
                                let date = new Date(String(log.details?.date)).toLocaleDateString();
                                let data = JSON.stringify(log.details?.shipment);

                                return (
                                    <IonItem lines="none">
                                        <IonLabel>{ date } - { data } </IonLabel>                                        
                                    </IonItem>
                                );
                            })
                        }
                    </IonRow>
                </>
            );
        } else {
            return (
                <PackingMeasure/>
            );
        }
    }

    const packagingList = () => {
        if (packingBoxs) {
            return (
                packingBoxs.map((box) => {
                    let boxName = boxList.find((boxList) => boxList.id === box.boxId);

                    return (
                    <IonCol sizeXl="3" sizeLg="3" size="6">
                        <IonList>
                            <IonListHeader>
                                <IonLabel>{ boxName?.name }</IonLabel>
                            </IonListHeader>
                            { box.products.map((product) => {
                                let productItem = items.find((item) => item.orderItem.product.id === product);

                                return (<IonItem lines="none">
                                        <IonLabel>- { productItem?.orderItem.productName }</IonLabel>
                                    </IonItem>
                                );
                            })}
                        </IonList>
                    </IonCol>
                    );
                })
            )
        } else {
            return (<></>)
        }
    }

    if (order) {
        return (
            <IonCard>
                <IonCardHeader>
                    <IonGrid className="ion-no-padding">
                        <IonRow>
                            <IonCol sizeXl="6" sizeLg="6" size="12">
                                <IonRow>
                                    <IonCol sizeXl="3" sizeLg="3" size="6">
                                        <IonItem lines="none">
                                            <IonLabel position="stacked">NºPedido</IonLabel>
                                            <IonInput type="text" readonly={true} placeholder={ String(order?.number) }></IonInput>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXl="4" sizeLg="4" size="6">
                                        <IonItem lines="none">
                                            <IonLabel position="stacked">Fecha</IonLabel>
                                            <IonInput type="text" readonly={true} placeholder={ dateCreated.toLocaleDateString() }></IonInput>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonList lines="none">
                                        <IonRow>
                                            <IonCol size="6">
                                                <IonItem lines="none">
                                                    <IonLabel position="stacked">Nombre</IonLabel>
                                                    <IonInput type="text" readonly={true} placeholder={ order?.nameShip }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                            <IonCol size="6">
                                                <IonItem lines="none">
                                                    <IonLabel position="stacked">Teléfono</IonLabel>
                                                    <IonInput type="text" readonly={true} placeholder={ order?.phoneShip }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <IonItem lines="none">
                                            <IonLabel position="stacked">Dirección</IonLabel>
                                            <IonInput type="text" readonly={true} placeholder={ order?.addressShip }></IonInput>
                                        </IonItem>
                                        <IonRow>
                                            <IonCol sizeXl="3" sizeLg="4" size="6">
                                                <IonItem lines="none">
                                                    <IonLabel position="stacked">Codigo Postal</IonLabel>
                                                    <IonInput type="text" readonly={true} placeholder={ String(order?.postalShip) }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                            <IonCol sizeXl="3" sizeLg="4" size="6">
                                                <IonItem lines="none">
                                                    <IonLabel position="stacked">Ciudad</IonLabel>
                                                    <IonInput type="text" readonly={true} placeholder={ order?.cityShip }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                            <IonCol sizeXl="3" sizeLg="4" size="6">
                                                <IonItem lines="none">
                                                    <IonLabel position="stacked">Provincia</IonLabel>
                                                    <IonInput type="text" readonly={true} placeholder={ order?.stateShip?? order?.province }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                            <IonCol sizeXl="3" sizeLg="4" size="6">
                                                <IonItem lines="none">
                                                    <IonLabel position="stacked">País</IonLabel>
                                                    <IonInput type="text" readonly={true} placeholder={ order?.countryShip }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol sizeXl="5" sizeLg="5" size="12">
                                                <IonItem lines="none">
                                                    <IonLabel position="stacked">Observaciones</IonLabel>
                                                    <IonInput type="text" readonly={true} placeholder={ order?.customerNote }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                            <IonCol sizeXl="5" sizeLg="5" size="12">
                                                <IonItem lines="none">
                                                    <IonLabel position="stacked">Notas Internas</IonLabel>
                                                    <IonInput type="text" readonly={true} placeholder={ order?.privateNote }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                    </IonList>
                                </IonRow>
                            </IonCol>
                            <IonCol sizeXl="6" sizeLg="6" size="12">
                                { shipmentData() }
                                <SelectShipment/>
                            </IonCol>
                        </IonRow>
                        <IonCardTitle className='icon-text'>Artículos</IonCardTitle>
                        <IonRow>
                            <IonCol size="12" className="boxListItems">
                                <IonList lines="none">
                                    <IonRow>
                                    { items.map((item) => {
                                        return (
                                            <IonCol key={ item.id } sizeXl="3" sizeLg="4" sizeSm="6" sizeXs="12">
                                                <PackingItem item={item} />
                                            </IonCol>
                                        )
                                    })}
                                    </IonRow>
                                </IonList>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardHeader>
            </IonCard>
        );
    } else {
        return (<></>);
    }
}

export default PackingOrder;
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import { qrCodeOutline } from 'ionicons/icons'
import React, { useEffect, useRef, useState } from 'react'
import { ReceptionItem } from '../interfaces';
import { useReceptionOrder } from '../hooks';
import { barcodeReducer, resetBarcode } from "../../../store/barcodeSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { showToast } from "../../../store/toastSlice";
import ApiRequest from '../../../shared/ApiRequest';

type Props = {
  item: ReceptionItem;
}
const UpdateEanButton: React.FC<Props> = ({item}: Props) => {

  const inputRef = useRef<HTMLIonInputElement>(null);
  const { orderId, beep, updateEanFromReceptionItem } = useReceptionOrder();
  const barcode = useAppSelector(barcodeReducer);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [currentBarcode, setCurrentBarcode] = useState(item.barcode);

  const onDidDismiss = () => {    
    setIsOpen(false);
    setCurrentBarcode(item.barcode);
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.setFocus();
    }
  };

  const handleUpdate = () => {    
    const data = {variantId: item.variant_id, barcode: currentBarcode, orderId};
    
    ApiRequest.post('/es/reception-order-provider/update-ean', data).then((_) => {
      setIsOpen(false);
      updateEanFromReceptionItem(item.id, currentBarcode!);
      dispatch(showToast({type: "success", message: 'Ean actualizado'}));
    })
    .catch(error => {
      if (error.status >= 400) {
        dispatch(showToast({type: "danger", message: "Error"}));
      }
    });
    
  }

  useEffect(() => {    
    
    if (isOpen && barcode.input !== '' && barcode.location.includes('reception-order-provider/')) {      
      setCurrentBarcode(barcode.input);
      dispatch(resetBarcode());      
      beep.play();
      dispatch(showToast({type: "success", message: 'Ean escaneado'}));
    }
    
  }, [setCurrentBarcode, isOpen, dispatch, barcode.input, barcode.location, beep]);

  return (
    <>
      <IonButton onClick={() => setIsOpen(true)} color="unobike-red" >
        <IonIcon icon={qrCodeOutline} ></IonIcon>
      </IonButton>
      <IonModal isOpen={isOpen} onDidPresent={focusInput} onDidDismiss={onDidDismiss} >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsOpen(false)}>Cerrar</IonButton>
            </IonButtons>
            <IonTitle>Escanea el nuevo ean</IonTitle>            
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>                  
          <IonInput
              ref={inputRef}
              readonly={true}
              value={currentBarcode}
            />
          </IonItem>

          {item.barcode !== currentBarcode && <IonButton expand="block" onClick={handleUpdate}>Actualizar</IonButton>}
          
        </IonContent>
      </IonModal>
    </>
  )
}

export default UpdateEanButton
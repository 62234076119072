import React , { useEffect, useState} from "react";
import { IonInput, IonItem, IonLabel, IonGrid, IonRow, IonCol } from "@ionic/react";
import { NativeAudio } from '@capacitor-community/native-audio'
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { barcodeReducer, resetBarcode, setResetString } from "../../store/barcodeSlice";
import { setProduct } from "../../store/measuresSlice";
import MeasureWorkstation from "./MeasureWorkstation";

const MeasureSearch: React.FC = () => {

    const [search, setSearchText] = useState('');
    const [value, setValue] = useState<string>('');
    const barcode = useAppSelector(barcodeReducer);
    const dispatch = useAppDispatch();

    NativeAudio.preload({
        assetId: "beep",
        assetPath: "beep.mp3",
        audioChannelNum: 1,
        isUrl: false
    });

    useEffect (() => {
        if (value) {
            ApiRequest.post("es/measures/product",{value: value}).then((response:any) => {
                dispatch(setProduct(response.data));
            })
            .catch(error => {
                if (error.status >= 400) {
                    dispatch(showToast({type: "danger", message: "Error: "+error.message}));
                }
            });
            setValue('');
            dispatch(setResetString(true));
        }
    },[dispatch,value])

    useEffect(() => {
        if (barcode.value !== '' && barcode.location === 'measures') {
            setValue(barcode.value);
            dispatch(resetBarcode());
        }
    }, [barcode, dispatch]);

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            NativeAudio.play({ assetId: 'beep', time: 0.0 });
            setValue(event.target.value)
            setSearchText('');
        } else {
            setSearchText(event.target.value);
        }
    }

    return (
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="10">
              <IonItem class="item-search">
                <IonLabel position="floating">EAN/SKU</IonLabel>
                <IonInput
                  inputmode="text"
                  type="text" autofocus={true} onIonChange={(e: any) => setSearchText(e.target.value)}
                  onKeyDown={handleKeyDown}
                  value={search}
                  id="search"
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="1" sizeXs="2">
                <MeasureWorkstation/>
            </IonCol>
          </IonRow>
        </IonGrid>
    );
}

export default MeasureSearch;
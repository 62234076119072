import React from 'react';
import { Redirect } from 'react-router-dom';
import {  IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonItemDivider, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import AuthenticateJWT from '../shared/Authenticate';
import ButtonUser from '../components/ButtonUser';
import PackingRead from '../components/Packing/PackingRead';
import PackingOrder from '../components/Packing/PackingOrder';
import PackingReady from '../components/Packing/PackingReady';
import { useAppSelector } from '../hooks/hook';
import { packingReducer  } from '../store/packingSlice';

const Packing: React.FC = () => {

  const packing = useAppSelector(packingReducer);

  if (!AuthenticateJWT.isAuthenticated() && !AuthenticateJWT.isEmployee()) {
    return <Redirect to="/login" />
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonTitle>Empaquetado</IonTitle>
          <ButtonUser />
        </IonToolbar>
      </IonHeader>
      <IonItem lines="none" className={ "scan "+packing.scan}></IonItem>
      <IonContent>
        <IonItemDivider sticky={true} className="shadow-box">
          <PackingRead/>
        </IonItemDivider>
        <IonGrid>
          <IonRow>
            <IonCol>
              <PackingOrder />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <PackingReady/>
    </IonPage>
  );
};

export default Packing;
import React, { ReactNode } from 'react';
import { Redirect } from 'react-router';
import AuthenticateJWT from '../shared/Authenticate';

interface AuthProps {
  children: ReactNode;
}

const Authored: React.FC<AuthProps> = ({ children }) => {
    
  if (!AuthenticateJWT.isAuthenticated() && !AuthenticateJWT.isEmployee()) {
    return <Redirect to="/login" />
  } 

  return <>{children}</>;
};

export default Authored;
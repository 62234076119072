/**
 * Función para formatear fecha y hora ISO a formato legible
 * @param dateString ISO string de fecha y hora
 * @returns String legible de fecha y hora
 */
export function formatDateTime(dateString: string): string {
    const dateObj = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('es-ES', options).format(dateObj);
}

export function isToday(dateString: string): boolean {
    const dateObj = new Date(dateString);
    const today = new Date();
    return (
        dateObj.getDate() === today.getDate() &&
        dateObj.getMonth() === today.getMonth() &&
        dateObj.getFullYear() === today.getFullYear()
    );
}


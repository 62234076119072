import { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
} from '@ionic/react';
import { Hydramember } from "../../shared/domain/Provider";
import { receivedReducer, setProviderId } from "../../store/receivedSlice";
import { useAppDispatch, useAppSelector } from '../../hooks/hook';

interface TypeaheadProps {
  items: Hydramember[];
  closeModal?: () => void;
}

function ReceivedModal(props: TypeaheadProps) {

  const [filteredItems, setFilteredItems] = useState<Hydramember[]>([...props.items]);
  const received = useAppSelector(receivedReducer);
  const dispatch = useAppDispatch();

  const searchbarInput = (ev: any) => {
    filterList(ev.target.value);
  };

  const filterList = (searchQuery: string | null | undefined) => {
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([...props.items]);
    } else {
      const normalizedQuery = searchQuery.toLowerCase();
      setFilteredItems(
        props.items.filter((item) => {
          return item.name.toLowerCase().includes(normalizedQuery);
        })
      );
    }
  };

  const close = () => {
    props.closeModal && props.closeModal();
  };

  const isChecked = (value: number) => {
    return received.providerId === value ? true : false;
  };

  const setProvider = (event: any) => {
    const providerId = event.target.value;
    dispatch(setProviderId({providerId: providerId}));
    props.closeModal && props.closeModal();
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Proveedores</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={close}>Cerrar</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar placeholder="Buscar..." onIonInput={searchbarInput}></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding-none">
        <IonList>
          {filteredItems.map((item) => (
            <IonItem key={item.id} lines='full'>
              <IonCheckbox value={item.id} checked={isChecked(item.id)} onClick={setProvider} slot='start'/>{item.name}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </>
  );
}
export default ReceivedModal;

import React , {useEffect, useState} from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { addOrder, delOrder, setOrderList, ordersReadyReducer } from "../../store/ordersReadySlice";
import { IonButton, IonCheckbox, IonItem, IonItemDivider, IonList, getPlatforms, IonText, IonGrid, IonRow, IonCol } from "@ionic/react";
import { Order} from "../../shared/domain/Order";
import Mercure from '../../shared/Mercure';
import { ordersReducer, setRefresh } from "../../store/ordersSlice";

const OrderList: React.FC = () => {

    const dispatch = useAppDispatch();
    const orders = useAppSelector(ordersReducer);
    const [list, setList] = useState<Order[]>([]);
    const ordersReady = useAppSelector(ordersReadyReducer);
    const arrayOrders = JSON.parse(ordersReady.orders);
    const isMobile = getPlatforms().includes('mobile');
    const isTable = getPlatforms().includes('tablet');

    useEffect(() => {
        if (orders.refresh) {
            ApiRequest.post<Order[]>("/es/orders/ready").then(response => {
                let newList = response.data;
                let allOrders = JSON.parse(ordersReady.orders);
                let allIds = newList.map((item) => item.id);
                let newOrders = allOrders.filter((item: any) => allIds.includes(item));
                
                dispatch(setOrderList({orders: JSON.stringify(newOrders)}));
                setList( newList );
            })
            .catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });
            dispatch(setRefresh(false));
        }
    }, [dispatch, ordersReady.orders, orders.refresh]);

    useEffect(() => {
        const eventSource = Mercure.subscribe("setOrdersReady", () => {
            dispatch(setRefresh(true));
        });

        return () => {
            eventSource.close();
        }
    }, [dispatch]);

    const checked = (e: any) => {
        if (e.detail.checked) {
            dispatch(addOrder({orderId: e.detail.value}));
            return;
        }
        dispatch(delOrder({orderId: e.detail.value}));
    }

    const buttonList = () => {
        if (isMobile && !isTable) {
            return (
                <IonButton color="unobike-red" className="button-barcode unobike-button-margin" slot="end" id="modalListItem">
                    P: {ordersReady.quantityOrders} | A: {ordersReady.quantityItems}
                </IonButton>
            );
        }
    }

    return (
        <IonList lines="none" className="ion-no-padding">
            <IonItemDivider sticky={true} className="shadow-box">
                <h4>Listado Pedidos</h4>
                { buttonList() }
            </IonItemDivider>
        { list.map((item) => {
            const isChecked = (arrayOrders.includes(item.id)) ? true : false;
            let dateOrder = new Date(item.dateCreated).toLocaleDateString();

            return (
                <IonItem className="unobike-item-border" key={ item.id }>
                    <IonCheckbox className="ion-margin-end" slot="start" value={ item.id } onIonChange={ checked } checked={ isChecked }/>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                P: { item.number } | F: { dateOrder }
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="variant-text-list">
                                <IonText>{ item.customerNote }</IonText><br/>
                                <IonText>{ item.privateNote }</IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>                    
                </IonItem>
            );
        })}
        </IonList>
    );
}

export default OrderList;
import { useOrderLoader } from "../hooks/useOrderLoader";
import OrderLoaderListByProvider from "./OrderLoaderListByProvider";
import OrderLoaderListByTerm from "./OrderLoaderListByTerm";

const OrderLoaderList: React.FC = () => {

  const { term } = useOrderLoader();  

  return (
    term === '' 
      ? <OrderLoaderListByProvider />
      : <OrderLoaderListByTerm />    
  );
}

export default OrderLoaderList;
import { useHistory, useParams } from "react-router";
import { ResponseOrderProvider } from "../interfaces";
import { useEffect, useState } from "react";
import ApiRequest from "../../../shared/ApiRequest";

export const useListAndScanScreens = () => {
  const { id } = useParams<{id: string}>();
  const [order, setOrder] = useState<ResponseOrderProvider>({} as ResponseOrderProvider);    
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  
  
  useEffect(() => {    
    const fetchOrder = async() => {
      const responseOrder = await ApiRequest.get<ResponseOrderProvider>(`/es/order_providers/${id}`)        
  
      setOrder(responseOrder.data);        
      setLoading(false);
    };

    fetchOrder();

  }, [id]);

  return { id, order, setOrder, loading, setLoading, history };
}
import { IonToolbar, IonGrid, IonRow, IonCol, IonButton, useIonAlert, useIonLoading } from "@ionic/react";
import { useReceptionOrder } from "../hooks/useReceptionOrder";
import ApiRequest from "../../../shared/ApiRequest";
import { useAppDispatch } from "../../../hooks/hook";
import { showToast } from "../../../store/toastSlice";
import { ResponseResumeOrderProvider } from "../interfaces";
import ModalLocation from "./ModalLocation";

const ButtonStockReceived: React.FC = () => {

  const { receptionItemsCount, location, orderId, setResumeOrderProvider, documentId, beep } = useReceptionOrder();
  const [presentAlert] = useIonAlert();
  const [present, dismiss] = useIonLoading();
  const dispatch = useAppDispatch();

  const handleStock = () => {

    if ( receptionItemsCount ) {
      present({
        message: 'Procesando...',
        spinner: 'circular'
      });

      ApiRequest.post<ResponseResumeOrderProvider>("/es/warehouse_barcodes/addStockByOrderProvider",{location, orderId, documentId}).then(response => {
        setResumeOrderProvider(response.data);
        dismiss();                
        dispatch(showToast({type: "success", message: 'Recepcionado'}));
        beep.play();
      })
      .catch(error => {
          dismiss();
          dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
      });
    }
  }

  return (
    <IonToolbar>
      <IonGrid>
        <IonRow class="ion-justify-content-center">          
          <IonCol size="6" size-md="4" size-lg="4">
            <IonButton
              color="unobike-red"
              expand="block"
              disabled={receptionItemsCount === 0}
              onClick={() => presentAlert({
                header: 'Alerta',
                subHeader: `¿Quieres añadir los artículos en ${location}?`,
                buttons: [
                  {
                    text: 'Cancelar',
                    role: 'cancel',
                  }, {
                    text: 'Añadir',
                    role: 'confirm',
                    handler: () => {
                      handleStock();
                    },
                  },
                ],
              })} >Añadir {receptionItemsCount} uds</IonButton>

          </IonCol>
          <ModalLocation />
        </IonRow>
      </IonGrid>
    </IonToolbar>
  );
}

export default ButtonStockReceived;
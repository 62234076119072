import { IonCol, IonButton, IonIcon, IonText } from "@ionic/react";
import React,{ useEffect, useState } from "react";
import { Hydramember, StorageStart } from "../../shared/domain/ItemStorage";
import { addOutline, removeOutline} from 'ionicons/icons';
import ApiRequest from "../../shared/ApiRequest";
import { showToast } from "../../store/toastSlice";
import { useAppDispatch } from "../../hooks/hook";

type Props = {
    data: Hydramember,
    storageStart: StorageStart|undefined,
}

const StorageButtonStock: React.FC<Props> = ({data, storageStart}) => {

    const dispatch = useAppDispatch();
    const [uds, setUds] = useState<number>(data.stock);
    const [maxium, setMaxium] = useState<number>(1);

    useEffect(() => {
        if (storageStart) {
            setUds(data.stock);
            setMaxium(storageStart.stock);
        }
    }, [data, storageStart]);

    const handleAddStock = () => {
        if (uds < maxium!) {
            let newUds = uds + 1;
            setUds(newUds);
            updateStock(newUds);
        }
    }

    const handleSubStock = () => {
        if (uds > 1) {
            let newUds = uds - 1;
            setUds(newUds);
            updateStock(newUds);
        }
    }

    const updateStock = (newUds : number) => {
        ApiRequest.patch("/es/storage_tmps/"+data.id, {stock: newUds})
        .catch(error => {
            if (error.status >= 400) {
                dispatch(showToast({type: "danger", message: "Error"}));
            }
        });
    };

    return (
        <IonCol size="12">
            <IonButton size="small" color="medium" className="ion-float-right ion-no-margin" onClick={ () => handleAddStock() }>
                <IonIcon className='icon-text' icon={addOutline}/>
            </IonButton>
            <IonButton size="small" fill="clear" color="light" className="ion-float-right ion-no-margin">
                <IonText className='icon-text'>{ uds }</IonText>
            </IonButton>
            <IonButton size="small" color="medium" className="ion-float-right ion-no-margin" onClick={ () => handleSubStock() } >
                <IonIcon className='icon-text' icon={removeOutline}/>
            </IonButton>
        </IonCol>
    )
}

export default StorageButtonStock;
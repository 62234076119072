import { IonGrid, IonRow, IonCol, IonButton, IonIcon, IonText } from "@ionic/react";
import React,{ useEffect, useState } from "react";
import { Hydramember } from "../../shared/domain/ItemReceived";
import { addOutline, removeOutline} from 'ionicons/icons';
import ApiRequest from "../../shared/ApiRequest";
import { showToast } from "../../store/toastSlice";
import { useAppDispatch } from "../../hooks/hook";

type Props = {
    data: Hydramember,
}

const ButtonsReceived: React.FC<Props> = ({data}) => {

    const dispatch = useAppDispatch();
    const [uds, setUds] = useState<number>(data.stock);

    useEffect(() => {
        setUds(data.stock);
    }, [data]);

    const handleAddStock = () => {
        let newUds = uds + 1;
        setUds(newUds);
        updateStock(newUds);
    }

    const handleSubStock = () => {
        if (uds > 1) {
            let newUds = uds - 1;
            setUds(newUds);
            updateStock(newUds);
        }
    }

    const updateStock = (newUds :number) => {
        ApiRequest.patch("/es/warehouse_barcodes/"+data.id, {stock: newUds})
        .catch(error => {
            dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
        });
    };

    return (
        <IonGrid class="buttons-received">
            <IonRow>
                <IonCol size="4">
                    <IonButton size="small" color="medium" onClick={ () => handleAddStock() }>
                        <IonIcon className='icon-text' icon={addOutline}/>
                    </IonButton>
                </IonCol>
                <IonCol size="4">
                    <IonButton size="small" fill="clear" color="light" expand="full"><IonText className='icon-text'>{ uds }</IonText></IonButton>
                </IonCol>
                <IonCol size="4">
                    <IonButton size="small" color="medium" onClick={ () => handleSubStock() } >
                        <IonIcon className='icon-text' icon={removeOutline}/>
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default ButtonsReceived;
import { IonInput, IonItem, useIonPicker } from "@ionic/react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import React , {useEffect, useState} from "react";
import { Hydramember, StorageStart } from "../../shared/domain/ItemStorage";
import { ItemStorageList } from "../../shared/domain/ItemStorageList";

type Props = {
    data: Hydramember,
    setStorageStart: (storageStart: StorageStart|undefined) => void,
    storageStart: StorageStart|undefined
}

const StorageLocationStart: React.FC <Props> = ({data, setStorageStart, storageStart}) => {

    const dispatch = useAppDispatch();
    const [present] = useIonPicker();
    const [list, setList] = useState<ItemStorageList[]>([]);

    useEffect (() => {
      ApiRequest.post("/es/storage_tmps/storages",{id: data.variant}).then(response => {
          setList( response.data );
      })
      .catch(error => {
          if (error.status >= 400) {
              dispatch(showToast({type: "danger", message: "Error"}));
          }
      });
    },[data.variant,dispatch]);

    useEffect (() => {
      if(list.length === 1 && !storageStart) {
        ApiRequest.patch("/es/storage_tmps/"+data.id,{storageStart: list[0].id}).then(response => {
          let data = response.data as Hydramember;
          setStorageStart(data.storageStart);
        })
      }
    },[list,data,storageStart,setStorageStart]);

    const openPicker = async () => {
      present({
        columns: [
          {
            name: 'Localizaciones',
            options: list.map((item) => {
              var isReturn = item.customerOrder ? ' (DEVOLUCIÓN)' : '';            
              return {
                text: item.location.code + isReturn,
                value: item,
              };
            }),
          },
        ],
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Confirmar',
            handler: (value) => {
              ApiRequest.patch("/es/storage_tmps/"+data.id,{storageStart: value.Localizaciones.value.id}).then(response => {
                let data = response.data as Hydramember;
                setStorageStart(data.storageStart);
                dispatch(showToast({type: "success", message: 'Localización Inicial Añadida'}));
              })
              .catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
              });
            },
          },
        ],
      });
    };

    if (list === undefined || list.length === 0) {
      return (  
        <IonItem lines="none">
            <IonInput readonly={true} value="ERROR" className="input-focus" color="danger"></IonInput>
        </IonItem>
      );
    }
    
    if (!storageStart) {
      return (  
        <IonItem lines="none">
            <IonInput readonly={true} value="INICIO" className="input-focus" color="danger" onClick={ openPicker }></IonInput>
        </IonItem>
      );
    } else {
      return (
        <IonItem lines="none">
            <IonInput readonly={true} value={ storageStart.location.code } className="input-focus" color="success" onClick={ openPicker }></IonInput>
        </IonItem>
      );
    }
}

export default StorageLocationStart;
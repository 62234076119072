import React from "react";
import { Hydramember } from "../../shared/domain/OrderReady";
import { IonCol, IonGrid, IonItem, IonList, IonRow } from "@ionic/react";
import OrderReadyDel from "./OrderReadyDel";

type Props = {
    item: Hydramember,
}

const OrderReadyItem: React.FC <Props> = ({item}) => {

    const orderReadyItems = item.orderReadyItems;
    let dateOrder = new Date(item.dateCreated).toLocaleDateString();
    
    return (
        <IonItem className="unobike-item-border">
            <IonGrid>
                <IonRow>
                    <IonCol size="10">
                        <p className="variant-text-list">
                            { dateOrder } - <strong>Localizacion:</strong> { item.location?.code }
                        </p>
                        <h5>{ item.order.number }</h5>
                    </IonCol>
                    <IonCol size="2" >
                        <OrderReadyDel id={ item.id }/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonList>
                        {
                            orderReadyItems.map((item) => {
                                return (
                                    <p><strong>{ item.orderItem.sku }</strong> - {item.orderItem.productName}</p>
                                )
                            })
                        }
                    </IonList>
                </IonRow>
            </IonGrid>
        </IonItem>
    );
}

export default OrderReadyItem;
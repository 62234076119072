import { IonList, IonGrid, IonRow, IonCol, IonItem, IonAvatar, IonLabel, IonItemDivider, IonBadge, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import ReceivedSearch from "./ReceivedSearch";
import { AddingButton, Documents, ModalNote, ReceivedButtonDel, ReceivedButtons, UpdateEanButton } from "./";
import { useReceptionOrder } from "../hooks/useReceptionOrder";
import { useEffect, useState } from "react";
import Mercure from '../../../shared/Mercure';

const ItemList: React.FC = () => {

  const {
    availableItems, addReceptionItem, receptionItems, availableItemsCount, receptionItemsCount, fetchAvailableAndReceptionItems
  } = useReceptionOrder();
  const [state] = useState({addReceptionItem, fetchAvailableAndReceptionItems});  

  useEffect(() => {    
    
    state.fetchAvailableAndReceptionItems();

    const eventSource = Mercure.subscribe("warehouseBarcodes", () => {      
      state.fetchAvailableAndReceptionItems();       
    });

    const eventSourceDel = Mercure.subscribe("delWarehouseBarcode", () => {
      state.fetchAvailableAndReceptionItems();
    });

    return () => {
      eventSource.close();
      eventSourceDel.close();
    }

  }, [state]);

  return (
    <>
      <IonItemDivider sticky={true} className="shadow-box">
        <ReceivedSearch />
        <ModalNote />
      </IonItemDivider>

      <IonGrid>
        <IonRow>
          <IonCol size-xl="2" size-lg="4" size="12" >
            <IonCard>
              <IonCardHeader>
                { availableItemsCount > 0
                  ? <IonCardTitle style={{color: '#fff'}}>Por recibir</IonCardTitle>
                  : <IonCardTitle style={{color: '#fff'}}>Nada que recibir</IonCardTitle>
                }                
              </IonCardHeader>

              <IonCardContent>
                <IonList>
                  { availableItems.map(item => (
                    <IonItem key={item.id}>
                      <IonLabel>{item.sku}</IonLabel>
                      <IonBadge color="primary">{item.stock}</IonBadge>
                      {
                        !item.hasVariant && <AddingButton item={item} />
                      }
                    </IonItem>
                  )) }
                </IonList>
              </IonCardContent>
            </IonCard>

            <Documents />
          </IonCol>        
          <IonCol size-xl="10" size-lg="8" size="12">
            <h2>Artículos a recepcionar {receptionItemsCount}</h2>      
            <IonList>
              <IonGrid>
                <IonRow>
                  {
                    receptionItems.map((item) => (
                      <IonCol sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="12" sizeXs="12" className="ion-no-padding" key={`r-${item.id}`}>
                        <IonItem className="unobike-item-border">
                          {item.variant_id && <IonAvatar slot="start">
                            <img alt={ item.name } src={ `https://www.unobike.com/${item.thumbnailUrl}` } />
                          </IonAvatar>}
                          <IonLabel>
                            <IonGrid>
                              <IonRow>
                                <IonCol size="10">
                                {item.sku && <h2><strong>{ item.sku }</strong></h2>}
                                <h3>{ item.name }</h3>
                                </IonCol>                          
                                <IonCol size="2" >
                                  <ReceivedButtonDel item={item} />
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol size="6">
                                  <p className="variant-text-list">{item.variant_id && item.barcode }</p>
                                </IonCol>                                
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  {item.variant_id && <UpdateEanButton item={item} />}
                                </IonCol>
                                <IonCol>
                                  <ReceivedButtons item={item}/>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                    ))
                  }
                </IonRow>
              </IonGrid>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>    
  );    
}


export default ItemList;
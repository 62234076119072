import React from 'react';
import { useHistory } from "react-router-dom";
import { IonButton, IonIcon, useIonActionSheet } from '@ionic/react';
import { personCircle, exitOutline, listOutline} from 'ionicons/icons';
import AuthenticateJWT from '../shared/Authenticate';


const ButtonUser: React.FC = () => {

    const [present] = useIonActionSheet();
    const history = useHistory();
    const user = AuthenticateJWT.getUser();
  
    return (
        <IonButton fill="clear" slot="end" onClick={() =>
            present({
                header: user!.name,
                buttons: [
                    {
                        text: 'Listado de Pedidos',
                        icon: listOutline,
                        handler: () => {
                            history.push("/orders-ready");
                        },
                    },
                    {
                        text: 'Logout',
                        icon: exitOutline,
                        handler: () => {
                            AuthenticateJWT.removeToken();
                            history.push("/login");
                        },
                        cssClass: 'logout-button'
                    },
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                    }
                ],
            })
        }>
            <IonIcon size="large" className='icon-text' icon={personCircle}/>
        </IonButton>
    );
  };
  
  export default ButtonUser;
import React , {useEffect, useState} from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { setItemGtin, setStoregareItem, setScan } from '../../store/pickingSlice';
import { barcodeReducer, resetBarcode } from "../../store/barcodeSlice";
import { IonButton, IonIcon, IonInput, getPlatforms, IonCol, IonGrid, IonRow, IonItem, IonLabel } from "@ionic/react";
import { BarcodeScanner, SupportedFormat } from "@capacitor-community/barcode-scanner";
import { NativeAudio } from "@capacitor-community/native-audio";
import { stopCircleOutline, barcodeOutline } from "ionicons/icons";

const PickingRead: React.FC = () => {

    const dispatch = useAppDispatch();
    const [value, setSearchText] = useState('');
    const [buttonStop, setButtonStop] = useState(false);
    const isMobile = getPlatforms().includes('capacitor') || getPlatforms().includes('cordova');
    const barcode = useAppSelector(barcodeReducer);
    const [code, setCode] = useState<string>('');

    NativeAudio.preload({
        assetId: "beep",
        assetPath: "beep.mp3",
        audioChannelNum: 1,
        isUrl: false
    });

    const searchText = (e: any) => {
        if (e.key === 'Enter') {
            setCode(e.target.value);
            setSearchText('');
        } else {
            setSearchText(e.target.value);
        }
    }

    const startScan = async () => {
        await BarcodeScanner.checkPermission({ force: true });
        document.querySelector('body')!.classList.add('scan');
        dispatch(setScan({scan: 'on'}));
        setButtonStop(true);
        scanning();
    };
  
    const scanning = async () => {
        const result = await BarcodeScanner.startScan({ targetedFormats: [ 
            SupportedFormat.UPC_A,
            SupportedFormat.UPC_E,
            SupportedFormat.UPC_EAN_EXTENSION,
            SupportedFormat.EAN_8, 
            SupportedFormat.EAN_13,
            SupportedFormat.CODE_39,
            SupportedFormat.CODE_93,
            SupportedFormat.CODE_128,
            SupportedFormat.CODABAR,
            SupportedFormat.ITF,
            SupportedFormat.CODABAR,
        ]});
        
        if (result.hasContent) {
            setCode(result.content as string);
            scanning();
        }
    }
  
    const stopScan = () => {
        BarcodeScanner.stopScan();
        document.querySelector('body')!.classList.remove('scan');
        dispatch(setScan({scan: 'off'}));
        setButtonStop(false);
    }
  
    const stopScanButton = () => {
        if (buttonStop) {
            return (
            <IonButton size="small" className="button-barcode" onClick={ stopScan }>
                <IonIcon size="large" className='icon-text' icon={stopCircleOutline}></IonIcon>
            </IonButton>
            );
        } else {
            return (
            <IonButton size="small" className="button-barcode" onClick={ startScan }>
                <IonIcon size="large" className='icon-text' icon={barcodeOutline}></IonIcon>
            </IonButton>
            );
        }
    }

    useEffect(() => {
        if (barcode.value !== '' && barcode.location === 'picking') {
            setCode(barcode.value);
            dispatch(resetBarcode());
        }
    }, [barcode, dispatch]);

    useEffect(() => {
        if (code !== '') {
            const expresion = /^[A-Za-z]{1,2}[0-9]{1,2}[A-Za-z]{1,2}[0-9]{1,2}$/
            if (code.match(expresion)) {
                dispatch(setStoregareItem({storage: code}));
            } else {
                dispatch(setItemGtin({gtin: code}));
            }
            NativeAudio.play({ assetId: 'beep', time: 0.0 });
            setCode('');
        }
    }, [code,dispatch]);

    if (isMobile) {
        return (
            <IonGrid className="ion-no-padding">
                <IonRow>
                    <IonCol sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="10">
                        <IonItem>
                            <IonLabel position="floating">EAN/LOC</IonLabel>
                            <IonInput
                                type="text" onKeyDown={(e: any) => searchText(e)}
                                value = { value }
                                id="search"
                            ></IonInput>
                        </IonItem>
                    </IonCol>
                    <IonCol size="1" sizeXs="2">
                        { stopScanButton() }
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    }

    return (
            <IonItem>
                <IonLabel position="floating">EAN/LOC</IonLabel>
                <IonInput
                    type="text" onKeyDown={(e: any) => searchText(e)}
                    value = { value }
                    id="search"
                ></IonInput>
            </IonItem>
    );
}

export default PickingRead;
import { IonSelect, IonSelectOption } from "@ionic/react";
import React from "react";
import { packingReducer, setAgency } from "../../store/packingSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";

const SelectShipment:React.FC = () => {

    const dispatch = useAppDispatch();
    const packing = useAppSelector(packingReducer);

    const handleAgency = (value: string) => {
        dispatch(setAgency(value));
    }

    return (
        <>
            <IonSelect value={packing.agency} cancelText="Cerrar" placeholder="Forzar agencia de envio" onIonChange={e => handleAgency(e.detail.value)} interface="action-sheet">
                <IonSelectOption value="1">MRW</IonSelectOption>
                <IonSelectOption value="3">UPS</IonSelectOption>
                <IonSelectOption value="5">SEUR</IonSelectOption>
                <IonSelectOption value="10">Envialia</IonSelectOption>
                <IonSelectOption value="11">GLS</IonSelectOption>
            </IonSelect>
        </>
    );
}

export default SelectShipment;
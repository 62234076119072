import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';

interface StorageState {
    refresh : boolean,
    scan : string,
    count : number,
}

const initialState: StorageState = {
    refresh: true,
    scan: 'off',
    count: 0,
}

export const storageSlice = createSlice({
  name: 'storageItem',
  initialState,
  reducers: {
    refresh: (state, action: PayloadAction<{refresh: boolean}>) => {
      state.refresh = action.payload.refresh
    },
    setScan: (state, action: PayloadAction<{scan: string}>) => {
      state.scan = action.payload.scan
    },
    countProduct: (state, action: PayloadAction<{count: number}>) => {
      state.count = action.payload.count
    }
  }
});

export const { refresh, setScan, countProduct} = storageSlice.actions
export const storageReducer = (state: RootState) => state.storage
export default storageSlice.reducer
import React from 'react';
import { Redirect } from 'react-router-dom';
import {  IonButtons, IonContent, IonFooter, IonHeader, IonItem, IonItemDivider, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import AuthenticateJWT from '../shared/Authenticate';
import ButtonUser from '../components/ButtonUser';
import { useAppSelector } from '../hooks/hook';
import { storageReducer  } from '../store/storageSlice';
import StorageList from '../components/Storage/StorageList';
import StorageAdd from '../components/Storage/StorageAdd';
import StorageSet from '../components/Storage/StorageSet';

const Storage: React.FC = () => {

  const storage = useAppSelector(storageReducer);

  if (!AuthenticateJWT.isAuthenticated() && !AuthenticateJWT.isEmployee()) {
    return <Redirect to="/login" />
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonTitle>Movimientos</IonTitle>
          <ButtonUser />
        </IonToolbar>
      </IonHeader>
      <IonItem lines="none" className={ "scan "+storage.scan}></IonItem>
      <IonContent className="unobike-background">
        <IonItemDivider sticky={true} className="shadow-box">
          <StorageAdd/>
        </IonItemDivider>
        <StorageList/>
      </IonContent>
      <IonFooter>
        <StorageSet/>
      </IonFooter>
    </IonPage>
  );
};

export default Storage;
import { useIonRouter, IonLoading, IonList, IonItem, IonLabel, IonText, IonButton, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { barcodeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useOrderLoader } from "../hooks";
import { OrderProviderStatus } from "../interfaces";

const OrderLoaderListByProvider: React.FC = () => {
    const { fetchOrders, formatDateTime, page, provider, orders, loadingOrders } = useOrderLoader();    
    const [state, setState] = useState({firstLoad: true, provider, page, fetchOrders});
    
    const router = useIonRouter();
  
    const goToPage = (id: number) => {
      router.push(`/reception-order-provider/${id}`);
    };
  
    // First Load
    useEffect(() => {
      state.fetchOrders(state.provider,state.page);
    }, [state]);   

    useEffect(() => {
      
      if ( provider !== state.provider ) {
        setState({...state, provider})
      }
    }, [provider, state]);

    return (
      <>
        <IonLoading
          isOpen={loadingOrders}        
          message={'Cargando...'}        
        />
      
        <IonList>
          {orders.map(({id, number, codeNumber, dateCreated, dateExpected, provider, pipelineStage, orderProviderItems}) => (
            <IonItem key={id}>
              <IonLabel>
                <h2>
                  {`Nº ${number} ${provider.name}`} <IonText color="medium"><small>{codeNumber}</small></IonText>
                </h2>
                <p>
                {
                  dateExpected && new Date(dateExpected) > new Date()
                    ? <IonText color="warning">{`Previsto: ${formatDateTime(String(dateExpected))}`}</IonText>
                    : formatDateTime(dateCreated)
                }
                </p>
              </IonLabel>
              
              <div slot="end">
                <IonButton className={pipelineStage.status === OrderProviderStatus.Parcial ? "partial-color-background" : "red-background"} size="small" onClick={() => goToPage(id)}>
                  {
                    pipelineStage.status === OrderProviderStatus.Parcial
                      ? `${orderProviderItems.filter(item => item.status === 2).length} (${orderProviderItems.length})`
                      : <IonIcon icon={barcodeOutline} color="light"></IonIcon>
                  }
                                        
                </IonButton>
              </div>
            </IonItem>
          ))}
        </IonList>
  
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            
            fetchOrders(provider, page);
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </>
    );
}

export default OrderLoaderListByProvider;
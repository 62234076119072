import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';

interface OrdersState {
    refresh: boolean,
}

const initialState: OrdersState = {
    refresh: true,
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setRefresh: (state, action: PayloadAction<boolean>) => {
      state.refresh = action.payload
    }
  }
});

export const { setRefresh } = ordersSlice.actions
export const ordersReducer = (state: RootState) => state.orders
export default ordersSlice.reducer
import { useEffect, useState } from "react";
import { useReceptionOrder } from "../hooks/useReceptionOrder";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonItem, IonList, IonText, IonToggle } from "@ionic/react";
import { cameraOutline, documentTextOutline } from "ionicons/icons";
import { environment } from "../../../environments/environment";
import { DocumentTypeBadge } from "./";

const Documents: React.FC = () => {
  
  const { documents, fetchAllDocuments, toggleScanView, documentId, setDocumentId } = useReceptionOrder();
  const [state] = useState({fetchAllDocuments});

  const openLinkInSystemBrowser = async (id: number) => {
    
    window.open(`${environment.api}/es/scandocument/download/${id}`,'_system','location=yes');    
  };

  const handleToggleClick = (id: number) => {    
    
    if ( !documentId || documentId !== id ) {
      setDocumentId(id);
      return;
    }
    
    setDocumentId(0);
  }

  useEffect(() => {

    state.fetchAllDocuments();

  }, [state]);

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle style={{color: '#fff'}}>
          <IonIcon icon={documentTextOutline}></IonIcon> Documentos
        </IonCardTitle>                
      </IonCardHeader>

      <IonCardContent>
        <IonList>          
          {
            documents.map(({id, title, type}) => (
              
              <IonItem key={id} button={true} >                
                
                <IonText onClick={() => openLinkInSystemBrowser(id)}>
                  <DocumentTypeBadge documentType={type} /> {title}
                </IonText>
                <IonToggle slot="end" checked={id===documentId} onClick={() => handleToggleClick(id)} />
              </IonItem>              
            ))
          }
        </IonList>        
      </IonCardContent>

      <IonButton expand="block" onClick={() => toggleScanView()} >
        <IonIcon icon={cameraOutline}></IonIcon>
        Escanear
      </IonButton>
    </IonCard>    
  );
}

export default Documents;
import {IonButton, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from '@ionic/react';
import './DocumentScan.css';
import {
  scanOutline,
  cloudUploadOutline,
  closeOutline,
  eyeOutline,
  addOutline
} from 'ionicons/icons';
import Scan from './Scan';
import { useDocumentScan, useReceptionOrder } from '../../hooks';
import { DocumentType, ProviderDocument, ResponseDocumentList } from '../../interfaces';
import { useState, useEffect } from 'react';
import ApiRequest from '../../../../shared/ApiRequest';
import { isToday, formatDateTime } from '../../../../shared/utils';
import { environment } from '../../../../environments/environment';
import { DocumentTypeBadge } from '../';

type Props = {
  orderId: number;
  orderNumber: number;
};

const DocumentScan: React.FC<Props> = ({orderId, orderNumber}: Props) => {
  
  const { toggleScanView } = useReceptionOrder();
  const { scans, takeScan, showLoading, handleDeleteScan, handleUpload, documentType, setDocumentType, handleAddDocument} = useDocumentScan(orderId);   
  const [documentList, setDocumentList] = useState<ProviderDocument[]>([]);    

  const fetchDocumentList = async() => {
    const responseDocuments = await ApiRequest.get<ResponseDocumentList>(`/es/scandocument/list`)
    setDocumentList(responseDocuments.data.data);
  };

  useEffect(() => {

    fetchDocumentList();
    
  }, [])
  
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Scan Document {orderNumber}</IonTitle>          
          
          <IonButton fill="clear" slot="end" onClick={() => toggleScanView()}>
            <IonIcon size="large" className='icon-text' icon={closeOutline}/>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

      {
        scans.length > 0 && <IonItem>
          <IonSelect placeholder="Tipo de documento" onIonChange={(e) => setDocumentType(e.detail.value as DocumentType)} value={documentType}>          
            <IonSelectOption value={DocumentType.DeliveryNote}>Albarán</IonSelectOption>
            <IonSelectOption value={DocumentType.Invoice}>Factura</IonSelectOption>
            <IonSelectOption value={DocumentType.Other}>Otros</IonSelectOption>
          </IonSelect>
        </IonItem>
      }

      

        <IonList>          
          {scans.map((scan, index) => (
            <IonItem key={index}>              
              <Scan index={index} handleDeleteScan={handleDeleteScan} webviewPath={scan.webviewPath!} />
            </IonItem>
          ))}

          {scans.length === 0 && <IonItem key={0}>
            <IonLabel>
              <h2>Agregar nuevos scans o elige un documento ...</h2>                
            </IonLabel>
          </IonItem>}

          {scans.length === 0 && documentList.map(({id,title,code,date,type}) => (
            <IonItem key={id}>
              <IonLabel>
                <h2>{title} <IonText color="medium"><small>{code}</small></IonText></h2>
                <p>
                  {isToday(date) ? <strong>{formatDateTime(date)}</strong> : formatDateTime(date)} <DocumentTypeBadge documentType={ type } />
                </p>
                
              </IonLabel>
              
              <div slot="end">
                <IonButton className={"red-background"} size="small" onClick={() => window.open(`${environment.api}/es/scandocument/download/${id}`,'_system','location=yes')}>
                  <IonIcon icon={eyeOutline} color="light"></IonIcon>                                        
                </IonButton>
                <IonButton className={"red-background"} size="small" onClick={() => handleAddDocument(id, orderId)}>
                  <IonIcon icon={addOutline} color="light"></IonIcon>                                        
                </IonButton>
              </div>
            </IonItem>
          ))}
        </IonList>        

        <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton onClick={() => takeScan()}>
            <IonIcon icon={scanOutline}></IonIcon>
          </IonFabButton>
        </IonFab>

        {
          scans.length > 0
            ? (
              <IonFab slot="fixed" vertical="bottom" horizontal="end">
                <IonFabButton color="danger" onClick={() => handleUpload()}>
                  <IonIcon icon={cloudUploadOutline}></IonIcon>
                  {scans.length > 1 && <div className="subscript-dot">{scans.length}</div>}                  
                </IonFabButton>            
              </IonFab>
            )
            : null
        }

        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}            
          message={'Subiendo ...'}
          duration={3000}
        />
      </IonContent>
    </>
  );
};

export default DocumentScan;

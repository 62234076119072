import { Hydramember } from "../shared/domain/OrderStorageReady";

export function usortRackPosition (hydramemberA: Hydramember , hydramemberB: Hydramember): number
{
    if (hydramemberA.storage && hydramemberB.storage) {
        if(hydramemberA.storage.location.rack === hydramemberB.storage.location.rack) {
            if(hydramemberA.storage.location.position === hydramemberB.storage.location.position) {
                return 0; 
            }
        
            return hydramemberA.storage.location.position < hydramemberB.storage.location.position ? -1 : 1;
        }

        return hydramemberA.storage.location.rack < hydramemberB.storage.location.rack ? -1 : 1;
    } else {
        if (!hydramemberA.storage && !hydramemberB.storage) {
            return 0;
        }

        return !hydramemberB.storage ? -1 : 1;
    }
}
import { NativeAudio } from "@capacitor-community/native-audio";

export class BeepService {  

  constructor(
    private readonly nativeAudio = NativeAudio
  ) {
    this.nativeAudio.preload({
      assetId: "beep",
      assetPath: "beep.mp3",
      audioChannelNum: 1,
      isUrl: false
    });
  }

  play() {    
    this.nativeAudio.play({ assetId: 'beep', time: 0.0 });
  }
}
import React , {useEffect, useState} from "react";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { packingReducer, setItemGtin, refresh } from "../../store/packingSlice";
import { OrderReadyItem } from "../../shared/domain/OrderReady";
import ApiRequest from "../../shared/ApiRequest";
import { stopCircleOutline,checkmarkCircleOutline } from "ionicons/icons";

type Props = {
    item: OrderReadyItem,
}

const PackingItem: React.FC <Props> = ({item}) => {

    const dispatch = useAppDispatch();
    const packing = useAppSelector(packingReducer);
    const [borderCard, setBorderCard] = useState('');
    const [numberPick, setNumberPick] = useState<number>(0);
    const [status, setStatus] = useState<number>(item.status);

    useEffect(() => {
        if (status === 3) {
            setBorderCard('unobike-border-success');
        } else {
            setBorderCard('');
        }
    }, [status]);

    useEffect(() => {
        if (item.orderItem.variant) {
            let gtin = String(item.orderItem.variant.gtin)
            let sku = String(item.orderItem.variant.sku.toLowerCase())

            if ((gtin && gtin === packing.itemGtin) || (sku && sku === packing.itemGtin.toLowerCase())) {
                let newPick = numberPick + 1;
                if (item.orderItem.uds === newPick) {
                    setNumberPick(newPick);
                    ApiRequest.patch("/es/order_item_readies/"+item.id,{status: 3})
                    .then((response) => {
                        setStatus(3);
                        setNumberPick(0);
                    })
                    .catch(error => {
                        dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
                    });
                } else if (status === 2) {
                    setNumberPick(newPick);
                    setBorderCard('unobike-border-warning');
                }
                dispatch(setItemGtin({gtin: ''}));
                dispatch(refresh({refresh: true}));
            }
        }
    }, [packing.itemGtin, dispatch, numberPick, item, status]);

    const checkButton = () => {
        if (
            !item.orderItem.variant
            || item.orderItem.product.category === '/es/categories/232'
        ) {
            if (status === 2) {
                return (
                    <IonButton size="small" fill="clear" color="danger" className="ion-no-padding ion-no-margin" onClick={ statusItem }>
                        <IonIcon size="small" color="danger" icon={stopCircleOutline}></IonIcon>
                    </IonButton>
                );
            } else {
                return (
                    <IonButton size="small" fill="clear" color="success" className="ion-no-padding ion-no-margin" onClick={ statusItem }>
                        <IonIcon size="small" color="success" icon={checkmarkCircleOutline}></IonIcon>
                    </IonButton>
                );
            }
        } else {
            return (<></>);
        }
    }

    const statusItem = () => {
        let value = status === 2 ? 3 : 2;
        ApiRequest.patch("/es/order_item_readies/"+item.id,{status: value})
        .then((response) => {
            if (value === 2) {
                setNumberPick(0);
            }
            setStatus(value);
            dispatch(refresh({refresh: true}));
        })
        .catch(error => {
            dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
        });
    }

    let skuText = item.orderItem.sku;
    if (item.orderItem.variant) {
        skuText = item.orderItem.variant.sku;
    }

    const finish = () => {
        if (item.orderItem.variant) {
            return (
                item.orderItem.variant.description && <span><strong>Acabado:</strong> {item.orderItem.variant.description}</span>
            );
        }
    }
    
    return (
        <IonCard key={ item.id } className={ borderCard }>
            <IonCardHeader>
                <IonCardTitle className='icon-text'>{skuText} { checkButton() }</IonCardTitle>
                <IonCardSubtitle>{item.orderItem.productName}</IonCardSubtitle>
                {finish()}
            </IonCardHeader>
            <IonCardContent>
                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol>
                            <IonCardTitle className='icon-text'>Uds. {item.orderItem.uds}</IonCardTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCardSubtitle>{ item.orderItem.productNote }</IonCardSubtitle>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    );
}

export default PackingItem;
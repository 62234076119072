import React, { useEffect, useState } from 'react';
import ApiRequest from "../../shared/ApiRequest";
import { OrderReady, Hydramember } from '../../shared/domain/OrderReady';
import { showToast } from "../../store/toastSlice";
import AuthenticateJWT from '../../shared/Authenticate';
import { useAppDispatch } from '../../hooks/hook';
import { IonCol, IonGrid, IonList, IonRow } from '@ionic/react';
import OrderReadyItem from './OrderReadyItem';
import Mercure from '../../shared/Mercure';

const OrdersReadyList: React.FC = () => {

    const userId = AuthenticateJWT.getUser()?.sub;
    const dispatch = useAppDispatch();
    const [list, setList] = useState<Hydramember[]>([]);
    const [update, setUpdate] = useState<boolean>(true);

    useEffect(() => {
        if (update) {
            ApiRequest.get<OrderReady>("/es/order_readies?user="+userId).then(response => {
                setList( response.data["hydra:member"] );
            })
            .catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });
            setUpdate(false);
        }
    }, [dispatch, userId, update]);

    useEffect(() => {
        const eventSource = Mercure.subscribe("orderReadies", () => {
            setUpdate(true);
        });

        return () => {
            eventSource.close();
        }
    }, []);

    useEffect(() => {
        const eventSourceDel = Mercure.subscribe("delOrderReady", () => {
            setUpdate(true);
        });

        return () => {
            eventSourceDel.close();
        }
    }, []);

    return (
        <IonList lines="full">
            <IonGrid>
                <IonRow>
                    {list.map((item) => {
                        return (
                            <IonCol key={ item.id } sizeXl="3" sizeLg="4" sizeSm="6" sizeXs="12">
                                <OrderReadyItem item={ item }/>
                            </IonCol>
                        );
                    })}
                </IonRow>
            </IonGrid>
        </IonList>
    );
};

export default OrdersReadyList;
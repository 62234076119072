import React, { useEffect, useState } from "react";
import { IonAvatar, IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow } from "@ionic/react";
import { useAppSelector } from "../../hooks/hook";
import { measuresReducer } from "../../store/measuresSlice";
import MeasureDel from "./MeasureDel";
import MeasureType from "./MeasureType";

const MeasureProduct: React.FC = () => {

    const measures = useAppSelector(measuresReducer);
    const [border, setBorder] = useState('');

    useEffect(() => {
        if (measures.variant?.product.packing) {
            setBorder('unobike-item-warning')
        } else {
            setBorder('unobike-item-border')
        }
    },[setBorder, measures.variant?.product.packing]);

    const oldMeasures = () => {
        if (measures.variant?.product.packing) {
            return (
                <span className="variant-text-list">X: {measures.variant?.product.packing.width} cm. | Y: {measures.variant?.product.packing.height} cm. | Z: {measures.variant?.product.packing.length} cm.</span>
            );
        }
    }

    if (!measures.variant) {
        return (<></>);
    }

    return (
        <IonList lines="full" className="label-list">
            <IonGrid>
                <IonRow>
                    <IonCol sizeXl="3" sizeLg="4" sizeSm="6" sizeXs="12" className="ion-no-padding">
                        <IonItem className={ border }>
                            <IonAvatar slot="start">
                                <img alt={ measures.variant.product.name } src={ "https://www.unobike.com/"+measures.variant.product.thumbnailUrl } />
                            </IonAvatar>
                            <IonLabel>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="10">
                                            <h3>{ measures.variant.product.name }</h3>
                                            <p className="variant-text-list">{ measures.variant.sku } { measures.variant.description }</p>
                                        </IonCol>
                                        <IonCol size="2">
                                            <MeasureDel/>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="10">
                                            <p>
                                                X: {measures.x} cm. | Y: {measures.y} cm. | Z: {measures.z} cm.<br/>
                                                {oldMeasures()}
                                            </p>
                                        </IonCol>
                                        <IonCol size="2">
                                            <MeasureType/>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonList>
    );
}

export default MeasureProduct;
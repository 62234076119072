import { createContext } from "react";
import { OrderLoaderState, OrderProvider, Provider } from "../interfaces";
import { BeepService } from "../../../services/BeepService";

export type OrderLoaderContextProps = {
    orderLoaderState: OrderLoaderState,
    addOrders: (orders: OrderProvider[]) => void,    
    addProviders: (providers: Provider[]) => void,
    fetchProviders: () => void,
    fetchOrders: (provider: number, page: number) => void,
    setProvider: (provider: number) => void,
    setTerm: (term: string) => void,
    fetchOrdersBySearch: (term: string) => void,
    formatDateTime: (dateString: string) => string,
    beep: BeepService
}

export const OrderLoaderContext = createContext<OrderLoaderContextProps>({} as OrderLoaderContextProps);
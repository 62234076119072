import { IonButton, IonCol, IonGrid, IonRow, IonToolbar, useIonLoading } from "@ionic/react";
import React  from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { measuresReducer, setProduct } from "../../store/measuresSlice";

const PackingReady: React.FC = () => {

    const measures = useAppSelector(measuresReducer);
    const dispatch = useAppDispatch();
    const [present, dismiss] = useIonLoading();

    const handleReady = () => {
        present({
            message: 'Procesando...',
            spinner: 'circular'
        });
        
        ApiRequest.post("/es/measures/set",{
            x: measures.x,
            y: measures.y,
            z: measures.z,
            type: measures.type,
            productId: measures.variant?.product.id,
            workstation: measures.workstation
        }).then(response => {
            dismiss();
            dispatch(setProduct(undefined));
            dispatch(showToast({type: "success", message: response.data}));
        })
        .catch(error => {
            dismiss();
            dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
        });
    }

    if (measures.x > 0 && measures.y > 0 && measures.z > 0 && measures.variant) {
        return (
            <IonToolbar>
                <IonGrid className="ion-no-padding">
                    <IonRow class="ion-justify-content-center">
                        <IonCol sizeXl="4" sizeLg="6" sizeSm="6" sizeXs="12">
                            <IonButton color="success" expand="block" onClick={handleReady}>
                                Listo
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        )
    } else {
        return null;
    }
}

export default PackingReady;
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {  IonButtons, IonContent, IonFooter, IonHeader, IonItemDivider, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import AuthenticateJWT from '../shared/Authenticate';
import ButtonUser from '../components/ButtonUser';
import { useAppDispatch, useAppSelector } from '../hooks/hook';
import { measuresReducer, setMeasures, reset, setType } from '../store/measuresSlice';
import Mercure from '../shared/Mercure';
import { MeasureItem } from '../shared/domain/Measures';
import MeasureSearch from '../components/Measures/MeasureSearch';
import MeasureProduct from '../components/Measures/MeasureProduct';
import MeasureSet from '../components/Measures/MeasureSet';

const Measures: React.FC = () => {

  const dispatch = useAppDispatch();
  const measures = useAppSelector(measuresReducer);

  useEffect(() => {
    dispatch(reset());

    const eventSource = Mercure.subscribe("measures"+measures.workstation, (measures: MeasureItem) => {
      dispatch(setMeasures(measures));
    });

    if (!measures.variant) {
      eventSource.close();
    }

    dispatch(setType(measures.variant?.product.packing?.type ?? 1));

    return () => {
        eventSource.close();
        dispatch(reset());
    }
  }, [dispatch, measures.variant, measures.workstation]);

  if (!AuthenticateJWT.isAuthenticated() && !AuthenticateJWT.isEmployee()) {
    return <Redirect to="/login" />
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonTitle>Medidas</IonTitle>
          <ButtonUser />
        </IonToolbar>
      </IonHeader>
      <IonContent className="unobike-background">
        <IonItemDivider sticky={true} className="shadow-box">
          <MeasureSearch/>
        </IonItemDivider>
        <MeasureProduct/>
      </IonContent>
      <IonFooter>
        <MeasureSet/>
      </IonFooter>
    </IonPage>
  );
};

export default Measures;
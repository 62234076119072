import React , {useEffect, useState} from "react";
import ApiRequest from "../../shared/ApiRequest";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showToast } from "../../store/toastSlice";
import { pickingReducer, setRefresh } from '../../store/pickingSlice';
import { IonCardSubtitle, IonCol, IonGrid, IonList, IonRow } from "@ionic/react";
import AuthenticateJWT from '../../shared/Authenticate';
import { OrderStorageReady,Hydramember } from "../../shared/domain/OrderStorageReady";
import PickingItem from "./PickingItem";
import { usortRackPosition } from "../../Application/usortRackPosition";
import Mercure from '../../shared/Mercure';

const PickingList: React.FC = () => {

    const dispatch = useAppDispatch();
    const [list, setList] = useState<Hydramember[]>([]);
    const userId = AuthenticateJWT.getUser()?.sub;
    const picking = useAppSelector(pickingReducer);

    useEffect(() => {
        if (picking.refresh) {
            ApiRequest.get<OrderStorageReady>("/es/order_storage_readies?orderItemReady.orderReady.status=1&orderItemReady.orderReady.user="+userId).then(response => {
                response.data['hydra:member'].sort((a, b) => usortRackPosition(a, b));
                setList( response.data['hydra:member'] );
            })
            .catch(error => {
                dispatch(showToast({type: "danger", message: 'Error: '+error.message}));
            });
            dispatch(setRefresh(false));
        }
    }, [dispatch, picking.refresh, userId]);

    useEffect(() => {
        const eventSource = Mercure.subscribe("orderReadies", () => {
            dispatch(setRefresh(true));
        });

        return () => {
            eventSource.close();
        }
    }, [dispatch]);

    useEffect(() => {
        const eventSourceDel = Mercure.subscribe("delOrderReady", () => {
            dispatch(setRefresh(true));
        });

        return () => {
            eventSourceDel.close();
        }
    }, [dispatch]);

    return (
        <IonList className="ion-no-padding">
            <IonGrid className="ion-no-padding">
                <IonRow className="ion-no-padding">
                    <IonCardSubtitle>Localizacion: {picking.storageItem}</IonCardSubtitle>
                </IonRow>
                <IonRow className="ion-no-padding">
                { list.map((item) => {
                    return (
                        <IonCol key={ item.id } sizeXl="3" sizeLg="4" sizeSm="6" sizeXs="12">
                            <PickingItem item={item} />
                        </IonCol>
                    )
                })}
                </IonRow>
            </IonGrid>
        </IonList>
    );
}

export default PickingList;
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './Store';

interface Picking {
    orders : string,
    itemGtin : string,
    storageItem : string,
    scan : string,
    refresh : boolean,
}

const initialState: Picking = {
    orders: '[]',
    itemGtin: '',
    storageItem: '',
    scan: 'scan off',
    refresh: true,
}

export const pickingSlice = createSlice({
    name: 'picking',
    initialState,
    reducers: {
        setItemGtin: (state, action: PayloadAction<{gtin: string}>) => {
            state.itemGtin = action.payload.gtin;
        },
        setStoregareItem: (state, action: PayloadAction<{storage: string}>) => {
            state.storageItem = action.payload.storage;
        },
        setScan: (state, action: PayloadAction<{scan: string}>) => {
            state.scan = action.payload.scan
        },
        setRefresh: (state, action: PayloadAction<boolean>) => {
            state.refresh = action.payload
        },
    }
});

export const {  setItemGtin,
                setStoregareItem,
                setScan,
                setRefresh
             } = pickingSlice.actions
export const pickingReducer = (state: RootState) => state.picking
export default pickingSlice.reducer